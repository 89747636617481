import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { gsap } from 'gsap';
import '../../i18n/config';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { Client } from "../../utilities/prismicHelpers";
import '../../components/style/responsive/style.scss';
import { Button } from "../blocks/button";
import "../../components/style/B2B.scss";
import "../../components/style/CaseModules.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import useForm1 from '../../components/form/useForm1';
import FormInput from "../../components/form/form-input.component";
import validate from '../../components/form/validateInfo';
import FormSuccess from "../form/FormSuccess";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function parseCodes(textWithCodes) {
    const splitText = textWithCodes.split('[br]').map((text, index) => (
        <React.Fragment key={index}>
            {text}
            {index !== textWithCodes.length - 1 && <br />} {/* Add <br /> except for the last element */}
        </React.Fragment>
    ));

    return (
        <React.Fragment>
            {splitText}
        </React.Fragment>
    );
}

function buildHtml(entity, customClasses) {
    let customClassesStr = '';

    if (!Array.isArray(entity) || entity.length <= 0) {
        return null;
    }

    if (Array.isArray(customClasses) && customClasses.length > 0) {
        customClassesStr = customClasses.join(' ');
    }

    return entity.map((element, index) => {
        let text = parseCodes(element.text);

        if (typeof element.type === 'string') {
            switch (element.type) {
                case 'heading1':
                    return <h1 className={customClassesStr} key={index}>{text}</h1>;

                case 'heading2':
                    return <h2 className={customClassesStr} key={index}>{text}</h2>;

                case 'heading5':
                    return <h5 className={customClassesStr} key={index}>{text}</h5>;

                case 'heading6':
                    return <h6 className={customClassesStr} key={index}>{text}</h6>;

                // By default, treat as paragraph
                default:
                    return <p className={customClassesStr} key={index}>{text}</p>;
            }
        }
        return null;
    });
}

function embedVideo(source) {
    if (typeof source != 'object')
        return false;

    if (typeof source.provider_name == 'string') {
        if (source.provider_name == 'YouTube') {
            const split = source.embed_url.split('?v=');
            if (split.length == 2) {
                return (
                    <iframe frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoplay allowfullscreen title="PIA – We Do Automotive" style={{ width: '100%', height: '960px' }} src={`https://youtube.com/embed/` + split[1]}>
                        Iframe disabled
                    </iframe>
                );
            }
        }
    }
}

function B2BBasicModule(props) {
    const content = props.content;
    const width = window.innerWidth;
    
    const extraCssClass = content.primary.extra_css_class ? content.primary.extra_css_class : '';
    const basicModuleBackgroundColor = content.primary.basic_module_background_color ? content.primary.basic_module_background_color : '';
    let basicModuleBackgroundUrl = content && content.primary && content.primary.basic_module_background && typeof content.primary.basic_module_background.url ? content.primary.basic_module_background.url : '';
    if(width < 992) {
        basicModuleBackgroundUrl = content && content.primary && content.primary.basic_module_background && typeof content.primary.basic_module_background.mobile == 'object' ? content.primary.basic_module_background.mobile.url : basicModuleBackgroundUrl;
    }

    document.addEventListener('scroll', function() {
        
        let scrollTop = window.scrollY;
        let parallaxElements = document.querySelectorAll('.bg-left-module');
        console.log('test2', parallaxElements);
        parallaxElements.forEach(function(element) {
            let speed = 0.005
            let yOffset = -(scrollTop * speed);
            element.style.transform = 'translate3d(0, ' + yOffset + 'px, 0) !important';
            console.log(element, yOffset);
        });
    });
    

    console.log('b2b', content);
    console.log('dims', width);

    return (
        <div style={{ backgroundColor: '#fff' }}>
            <div className={`b2b-container b2b-basic-module ${extraCssClass}`} style={{ backgroundColor: `${basicModuleBackgroundColor}`, backgroundImage: `url(${basicModuleBackgroundUrl})` }}>
                {
                    content.primary.has_overlay1 ?
                        (
                            <div className={`b2b-overlay`}></div>
                        ) : ('')
                }
                <div className={`b2b-${content.primary.text_position}`}>
                    {buildHtml(content.primary.basic_module_title)}
                    {buildHtml(content.primary.basic_module_description, [])}
                </div>
            </div>
        </div>
    )
}

function ModuleHero(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const content = props.content

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section>
            <div className="topVideoContainer d-block moduleHero parallax-container">
                {
                    typeof content == 'object' ?
                        (
                            <div className="parallax-content" style={{ width: '100%', height: '960px', transform: `translate(0%, ${scrollPosition * 0.5}px)`,
                            backgroundSize:'cover',
                            backgroundImage:
                                 'url(' +
                                 (typeof content.hero_image.url != 'undefined' ?
                                     content.hero_image.url:
                                     '') +
                                 ')',
                            
                            }}>
                                {
                                    content.has_overlay ?
                                        (
                                            <div className={`b2b-overlay`}></div>
                                        ) : ('')
                                }
                                {/* {
                                    embedVideo(content.hero_video)
                                } */}

                                <div className="b2b-hero">
                                    {
                                        buildHtml(content.hero_title, ['b2b-title', 'text-white'])
                                    }
                                    {
                                        buildHtml(content.hero_description, ['text-white'])
                                    }
                                    {
                                        typeof content.call_to_action.url != 'undefined' ?
                                            (
                                                <a href={content.call_to_action.url} className="btn--inverted--solid btn--regular btn-h-40">
                                                    {
                                                        content.call_to_action_text[0].text != '' ?
                                                            (
                                                                content.call_to_action_text[0].text
                                                            ) : ('View')
                                                    }
                                                </a>
                                            ) : ('')
                                    }
                                </div>
                            </div>
                        ) : ('')
                }
            </div>
        </section>
    );
}

function CarouselContainer(props) {
    const content = props.content;

    console.log('carousel', content);

    return (
        <div className={`b2b-container carousel`}>
            <div class="swiper-controls">
                <div>
                    {
                        buildHtml(content.primary.title, ['f-36'])
                    }
                </div>
                < div className = {
                    `swiper-button-prev-${props.name} swiper-button-prev`
                } > </div>
                < div className = {
                        `swiper-button-next-${props.name} swiper-button-next`
                        }></div>
            </div>
            <Swiper
                loop={true}
                freeMode={true}
                grabCursor={true}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 32
                    },
                    768: {
                        spaceBetween: 32,
                        slidesPerView: 3,
                    },
                }}
                observer={true}
                draggable={true}
                navigation={{
                    prevEl: `.swiper-button-prev-${props.name}`,
                    nextEl: `.swiper-button-next-${props.name}`,
                }}

                onSlideChange={() => { }}
                speed={1000}
                autoplay={false}
                freeModeMomentum={false}
                observeParents={true}
                observeSlideChildren={true}
                onObserverUpdate={() => { }}
                //centeredSlides={true}
                spaceBetween={42}
               slidesPerColumn = {
                   1
               }
               slidesPerView = {
                   3
               }
            >
                {
                    content.items.map((item, index) => {
                        return (
                            <SwiperSlide  key={`presentation-slider-${props.name}-${index}`}>
                                <img style={{ maxWidth: '370px' }}class="b2b-slider-image" src={item.item_image.url} />
                                <span class="break-line t-16 carousel-tagline">{item.item_tag}</span>
                                <h5 class="t-24">{item.item_title}</h5>
                                {
                                    buildHtml(item.item_description, ['t-16'])
                                }
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}

function ModuleType12(props) {
       const width = window.innerWidth;
    const data = props.content;
 const breakpoint = 768;
 const mobileBreakpoint = 576;

    console.log('mod12data', data);

    let imageUrl = typeof data.primary.image_link.url != 'undefined' ? data.primary.image_link.url : null;
    let dims;
    if (typeof data.primary.image_link.url != 'undefined') {
       
        dims = {
            width: 'auto',
            height: 'auto',
        }
    } else {
        dims = {
            width: 0, //data.primary.image_link.dimensions.width,
            height: 0 //data.primary.image_link.dimensions.height
        }
    }

      if (width <= mobileBreakpoint) {
          if (typeof data.primary.image_link.mobile.url != 'undefined') {
              imageUrl = data.primary.image_link.mobile.url;
          }
      } else if (width <= breakpoint) {
          if (typeof data.primary.image_link.url != 'undefined') {
              imageUrl = data.primary.image_link.url;
          }
      }


    console.log('module type 12', data)
    if (data.primary.title1 == 'Projects') {
        return ('')
    }

    let classStr1 = '';
    let classStr2 = '';
    if (data.primary.is_text_left) {
        classStr1 = 'col-12 col-xl-3 offset-xl-1';
        classStr2 = 'col-12 col-xl-7';
    } else {
        classStr1 = 'col-12 col-xl-3 offset-xl-1';
        classStr2 = 'col-12 col-xl-7';
    }

    return (
        <div class="module-12-container">
            <div className={`module-12 b2b-module-12`}>
                <div className={`container-fluid container`}>
                    <div className={`row`}>
                        {
                            data.primary.is_text_left == true ?
                                (
                                    <div className={`${classStr1} order-1`}>
                                        <h5>{data.primary.title1}</h5>
                                        {
                                            data.primary.description1 != null ?
                                                data.primary.description1.length > 0 ?
                                                    data.primary.description1.map((text, index) => {
                                                        return (
                                                            <p className="poppins-regular" key={index}>{text.text}</p>
                                                        )
                                                    }) : ('')
                                                : ('')
                                        }
                                    </div>
                                ) : (
                                    <div className={`${classStr1} order-2`}>
                                        <h5>{parseCodes(data.primary.title1)}</h5>
                                        <p className="poppins-regular">
                                            {
                                                Array.isArray(data.primary.description1) && data.primary.description1.length > 0 ?
                                                    data.primary.description1[0].text : ''
                                            }
                                        </p>
                                    </div>
                                )
                        }

                        <div className={`${classStr2} order-2`}>
                            {
                                imageUrl != null ?
                                    (
                                        <img src={imageUrl} />
                                    ) : ('')
                            }

                            {
                                data.primary.video_id != null || data.primary.video_embed_link ?
                                    (
                                        data.primary.video_source == 'Youtube' ?
                                            (
                                                <iframe width="100%" height="415" src={`https://www.youtube.com/embed/` + data.primary.video_id} title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen></iframe>
                                            ) : data.primary.video_source == 'Vimeo' ?
                                                (
                                                    <iframe width="100%" height="415" src={`https://player.vimeo.com/video/` + data.primary.video_id} title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen></iframe>
                                                ) : (
                                                    <video width="100%" height="315" controls>
                                                        <source src={data.primary.video_embed_link} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )
                                    ) : ('')
                            }
                        </div>
                    </div>

                    <div className="row percentages mt-5 mt-mobile-5">
                        <div className="col-12 col-lg-9 offset-lg-2 col-xxl-6 offset-xxl-5">
                            <div className="row">
                                {data.items.map((item, index) => {
                                    return (
                                        <div key={index} className="col-12 col-lg-4 flex-mobile">
                                            <h5 className="percent-label white-32 mb-3">{item.number_value}</h5>
                                            <p className="" style={{ lineHeight: '24px' }}>{item.number_description}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SliderContainer(props) {
    const content = props.content;

    console.log('slider', content);

    return (
        <div className={`b2b-slider-container`}>
            <Swiper
                style={{ height: '100%' }}
                loop={false}
                freeMode={true}
                grabCursor={true}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                    },
                    768: {
                        spaceBetween: 32,
                        slidesPerView: 3,
                    },
                }}
                observer={true}
                draggable={true}
                navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                }}

                onSlideChange={() => { }}
                speed={2000}
                autoplay={false}
                freeModeMomentum={true}
                observeParents={true}
                observeSlideChildren={true}
                onObserverUpdate={() => { }}
                //centeredSlides={true}
                spaceBetween={32}
                slidesPerView={1}
            >
                {
                    content.items.map((item, index) => {
                        return (
                            <SwiperSlide style={{ height: '100%' }} key={`b2b-bg-slider-${index}`}>
                                <div style={{ position: 'relative', paddingTop: '128px', height: '100%' }}>
                                    <div style={{ transform: 'translateZ(0)', transition: 'transform 0.4s ease-out', position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundImage: `url(${item.slider_image.url})`, backgroundColor: `#1a1a1a`, width: '100%', height: `960px` }}>
                                        <div class="b2b-slider-content">
                                            {
                                                buildHtml(item.title, ['t-64', 'text-white'])
                                            }
                                            {
                                                buildHtml(item.description, ['t-28', 'text-white', 'm-b-60'])
                                            }
                                            {
                                                typeof item.call_to_action_link.url != 'undefined' ?
                                                    (
                                                        <a href={item.call_to_action_link.url} className="btn--inverted--solid btn--regular btn-h-40">
                                                            {
                                                                item.call_to_action_text1 != '' ?
                                                                    (
                                                                        item.call_to_action_text1
                                                                    ) : ('View')
                                                            }
                                                        </a>
                                                    ) : ('')
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }

                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </Swiper>
        </div>
    )
}

function B2bFaq(props) {
    const content = props.content;
    const [activeIndex, setActiveIndex] = React.useState(0);
    let handleClick2 = (id) => {
        if (id === activeIndex) {
            setActiveIndex(99);
        } else {
            setActiveIndex(id);
        }
    }
    console.log('faq', content);

    return (
        <div style={{ backgroundColor: '#fff' }}>
            <div class="container b2b-faq">
                <div class="row">
                    <div class="faq-title">
                        {
                            buildHtml(content.primary.title, ['t-36', 'l-h-48'])
                        }
                    </div>
                    <div class="faq-content">
                        <div className="acc">
                            <dl className="acc__list">
                                {content.items.map((list, index) => {
                                    const showDescription = index === activeIndex ? "show-description" : "";
                                    const fontWeightBold = index === activeIndex ? "font-weight-bold expandedArea" : "";
                                    const ariaExpanded = index === activeIndex ? "true" : "false";

                                    return (
                                        <div className="acc__question" key={`accordion-${index}`}>
                                            <dt id={list.item_id_anchor}>
                                                <div className="row">
                                                    <div className="col-12 col-md-12">
                                                        <button
                                                            aria-expanded={ariaExpanded}
                                                            aria-controls={`acc${index + 1}_desc`}
                                                            data-qa="acc__question-button"
                                                            className={`acc__question-button  ${fontWeightBold}`}
                                                            onClick={(_) => handleClick2(index)}
                                                        // onClick={() => this.handleClick({index})}

                                                        >
                                                            {
                                                                list.question
                                                            }
                                                        </button>
                                                    </div>
                                                </div>

                                            </dt>
                                            <dd>
                                                <div className={`acc__desc  ${showDescription}`} id={`acc${index + 1}_desc`}
                                                    data-qa="acc__desc">
                                                    <div className="row">
                                                        <div className="col-12 col-md-10">
                                                            <div>
                                                                {
                                                                    buildHtml(list.answer)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </dd>
                                        </div>
                                    )
                                })}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContactSection(props) {
    const content = props.content;
    const {
        t
    } = useTranslation();

    const { handleChange, handleSubmit, values, errors } = useForm1(
        submitForm,
        validate
    );
        const [isSubmitted, setIsSubmitted] = useState(false);

    const [valuess, setValuess] = useState('');

      function submitForm() {
        console.log(errors)
          setIsSubmitted(true);
      }

      async function onChange(token) {
        console.log("Recaptcha token:", token);
        console.log("Recaptcha token:", 'changed');
    }



    function handleSuccess() {

    }

    function handleFailure() {

    }

    return (
        <div class="contact-section-container">
            <GoogleReCaptchaProvider reCaptchaKey="6Lf3qY8pAAAAAH36x6tkJQaV2vX6IHrFWmKGnkMJ">
            {/* <div className={`b2b-container offer-container`}>
                <div class="offer">
                    <h5 class="t-64 p-b-40">{content.offer_title}</h5>
                    {
                        buildHtml(content.offer_description, ['t-36'])
                    }
                    {
                        <div class="offer-bg" style={{ backgroundImage: `url(${content.offer_background && content.offer_background.url ? content.offer_background.url : ''})` }}>

                        </div>
                    }
                    <img class="offer-bg-mobile" src={content.offer_background && content.offer_background.url ? content.offer_background.url : ''} />
                </div>
            </div> */}

            <div className={`b2b-container b2b-contact`}>
                <div class="row">
                    <div class="col-12 col-md-2 t-36">
                        {
                            content.contact_title
                        }
                    </div>
                    <div class="col-12 col-md-8 right-col">
                         {!isSubmitted ? (
                        <form id="formtab1" className="FROMB2B " onSubmit={handleSubmit} method="POST" noValidate>

                            <FormInput
                                style={{ marginTop: '0px' }}
                                name='name'
                                type='text'
                                onChange={handleChange}
                                value = {
                                    values.name
                                }
                                label="First name"
                                required
                            />
                            <FormInput
                                name='forename'
                                type='text'
                                onChange={handleChange}
                                value = {
                                    values.forename
                                }
                                label="Last name"
                                error={errors.forename}
                                required
                            />

                            <FormInput
                                name='email'
                                type='email'
                                onChange={handleChange}
                                value = {
                                    values.email
                                }
                                label="Email"
                                error={errors.email}
                                required
                            />
                            <FormInput
                                name='phone'
                                type='tel'
                                onChange={handleChange}
                                value = {
                                    values.phone
                                }
                                label="Phone"
                                error={errors.phone}

                            />

                            <div className="mt-5">
                                <GoogleReCaptcha onVerify={onChange} />
                            </div>

                            <div className="privacyContainer mt-5" htmlFor="privacy">
                                <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                                    value="1" />
                                <span class="contact-terms">
                                    {
                                        buildHtml(content.contact_terms)
                                    }
                                </span>
                            </div>
                            <div className="my-4">
                            <Button  buttonStyle='btn--inverted--solid' buttonSize='btn--regular' type={'submit'}>{t('formSendEmailButton')}</Button>
                            </div>

                        </form>):(
                    < FormSuccess showBtn={false} />
                        )}
                    </div>
                </div>
            </div>

            <div class="b2b-contact-person">
                {
                    <div class="first-section" style={{ backgroundImage: `url(${content.contact_second_image && content.contact_second_image.url ? content.contact_second_image.url : ''})` }}>
                        <div class="first-section-content">
                            <div class="align-content">
                                <h5 class="p-b-40 t-36 text-white">
                                    {
                                        content.contact_headline
                                    }
                                </h5>
                                {
                                    buildHtml(content.contact_description, ['text-white'])
                                }
                            </div>
                        </div>
                    </div>
                }
                <div class="second-section" style={{ backgroundImage: `url(${content.contact_image && content.contact_image.url ? content.contact_image.url : ''})` }}>
                    <div style={{ position: 'relative', height: '100%' }}>
                        <div style={{ position: 'absolute', left: '0px', bottom: '0px', width: '100%', height: '50%', background: `linear-gradient(180deg, rgba(255, 255, 255, 7.2e-05) 0%, rgba(128, 128, 128, 0.259146) 35.44%, rgba(0, 0, 0, 0.5184) 100%)` }}>
                            <div style={{ position: 'absolute', left: '40px', bottom: '40px' }}>
                                <h3 class="contact-name" style={{ color: '#ffffff' }}>
                                    {
                                        content.contact_name
                                    }
                                </h3>
                                <span class="contact-tagline">
                                    {
                                        content.contact_tagline
                                    }
                                </span>
                                <p class="contact-phone" style={{ color: '#ffffff' }}>
                                    {
                                        content.contact_phone
                                    }
                                </p>
                                {
                                    typeof content.contact_cta_text != 'undefined' ?
                                        (
                                            <a href={`tel:${content.contact_phone}`} className="btn--inverted--solid btn--regular btn-h-40">
                                                {
                                                    content.contact_cta_text != '' ?
                                                        (
                                                            content.contact_cta_text
                                                        ) : ('View')
                                                }
                                            </a>
                                        ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </GoogleReCaptchaProvider>
        </div>
    )
}

function ModuleLoader(props) {
    let width = props.width;
    const breakpoint = 768;
    const mobileBreakpoint = 576;
    gsap.registerPlugin(ScrollTrigger);

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);

    const { t, i18n } = useTranslation();
    let langStr;

    if (i18n.language == 'en') {
        langStr = 'en-Us';
    } else {
        langStr = 'de-De';
    }

    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);
    const location = useLocation();
    const [uid, setUid] = React.useState(location);

    React.useEffect(() => {
        const fetchData = async () => {
            let uidStr = uid.pathname.split('/');
            uidStr = uidStr[3];

            const response = await Client.getByUID('b2b_landing_page', 'b2b',
                { lang: langStr },
            );
            if (response) {
                setDocData(response);
            }
        }
        fetchData()
    }, [])

    console.log('body', doc);

    let [projectDetailsVisible, setProjectDetailsVisiblity] = React.useState(null);

    function toggleProjectDetails() {
        setProjectDetailsVisiblity(!projectDetailsVisible);
    }

    return doc ? (
        <div className={``}>
            <div className={`pageHeader`}>

                <ModuleHero content={doc.data} />
            </div>

            <div className={`col-12`}>

                {
                    doc.data.body.map((module, index) => {
                        if (module.slice_type == 'module_type_12') {
                            return <ModuleType12 content={module} />
                        }

                        if (module.slice_type == 'carousel') {
                            return <CarouselContainer name = {
                                `first-${index}`
                            }
                            content = {
                                module
                            }
                            />
                        }

                        if (module.slice_type == 'slider') {
                            return <SliderContainer content={module} />
                        }

                        if (module.slice_type == 'b2b_faq') {
                            return <B2bFaq content={module} />
                        }
                         //if(index!==9){
                        return <B2BBasicModule content={module} />;
                    })
                }

                <ContactSection content={doc.data} />

                {/* <div className="caseStudyPageContainer d-block mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <a className="btn--inverted--solid btn--regular btn-h-40">Weitere Cases entdecken</a>
                            </div>
                        </div>
                    </div>
                </div> */}


            </div>
        </div>
    ) : (<div className={`page-loading`}>Loading</div>);
}

export default ModuleLoader;
