import React, {useState, useEffect, Fragment} from "react";
import '../../components/style/Arbeiten.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BrowserView, MobileView, isDesktop, isMobileSafari, isTablet, isMobile, isMobileOnly,  isIOS, CustomView  } from 'react-device-detect';
import Slide1Image from "../images/arbeiten/1-min.jpg";
import shapeBg from "../images/arbeiten/2-min.jpg";
import bgQuote from "../images/arbeiten/heike_d.png";
import youtubeBg from "../images/arbeiten/6-min.jpg";
import benefitsBg from "../images/arbeiten/shape01_d-min.jpg";
import userTestimonialImage from "../images/arbeiten/5-min.jpg"
import userTestimonialImage2 from "../images/arbeiten/4-min.jpg"
import SlideAtm1Image from "../images/arbeiten/9-min.jpg";
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import SlideAtm2Image from "../images/arbeiten/8-min.jpg";
import AccordionImage from "../images/arbeiten/3-min.jpg";
import imgPersonKontakt from "../images/arbeiten/11-min.jpg";
import shapeBg2 from "../images/arbeiten/img_02_3-min.png"
import {RichText} from "prismic-reactjs";
import {NavHashLink} from "react-router-hash-link";
import Accordion from "../blocks/Accordion"
import {v4 as uuidv4} from "uuid";
import TeamSliderModule from "../blocks/TeamSliderModule";
import AccordionModule from "../blocks/AccordionModule";
import {useTranslation} from "react-i18next";
import videoTopVerti from "../videos/loop-vertical.mp4";
import videoTopHoriz from "../videos/loop-horizontal.mp4";


const ArbeitenPage = ( data ) => {

    // const [showMore, setShowMore] = useState(false);

    useEffect(()=> {
        window.emos3.send({
            content: `Arbeiten Page`
        });

    }, []);
    const { i18n, t } = useTranslation();
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(99);
    const handleClick = (event, param) => {
        let Item = document.getElementById(param).getElementsByClassName("acc__question-button")[0];
        Item.click();
    };
    function jobsClick(name) {
        window.emos3.send(
            {Target:  [`Arbeiten Jobs Button Click ${name}`, 'Arbeiten Jobs Button click', 1, 's'], type: 'event'}
        )
    }
    function bottomJobsClick() {
        window.emos3.send(
            {Target:  [`Arbeiten Bottom Page Jobs Button Click`, 'Arbeiten Jobs Button click', 1, 's'], type: 'event'}
        )
    }



    let handleClick2 = (id) =>
    {
        if (id === activeIndex) {
            setActiveIndex(99);
        } else {
            setActiveIndex(id);
        }

        // var activeIndex = id;
        //
        // var activeSlide = document.getElementsByClassName('acc__imageHolder')[id];
        // console.log(activeSlide);

        // var activeQa = document.getElementsByClassName('acc__imageHolder')[id];
        // var activeQaVideo = activeQa.getElementsByTagName('video')[0];
        // console.log(activeQaVideo);
        // console.log('out');
        // if (activeQaVideo) {
        //     console.log('in');
        //     activeQaVideo.play();
        // }

    }

    return (
        <div className="ArbeitenPage">

            {data.data.body.map( item =>
                <div key={uuidv4()} className={item.uid}>

                    {item.slice_type=="top_slider_with_anchors" ?
                        <Swiper
                            loop={true}
                            observer={true}
                            observeParents={true}
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                swiper.params.navigation.nextEl = navigationNextRef.current;
                            }}
                            onTransitionStart={() => {
                                // var videos = document.querySelectorAll('video');
                                //
                                // Array.prototype.forEach.call(videos, function(video){
                                //     video.pause();
                                // });
                            }}
                            onTransitionEnd={(swiper) => {
                                var activeIndex = swiper.activeIndex;

                                var activeSlide = document.getElementsByClassName('arbeitenSliderTopSlide')[activeIndex];
                                //console.log(activeSlide);
                                var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                                if (activeSlideVideo) {
                                    activeSlideVideo.play();
                                }

                                // var activeIndex = swiper.activeIndex;
                                // var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                                // var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                                // activeSlideVideo.play();

                            }}
                           // onSlideChange={() => console.log('slide change')}
                            //onSwiper={(swiper) => console.log(swiper)}
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                },

                            }}
                        >
                            <div className="container arrows">
                                <div className="swiper-button-prev" ref={navigationPrevRef} />
                                <div className="swiper-button-next" ref={navigationNextRef} />
                            </div>
                            {item.items.map(list =>
                                <SwiperSlide className="arbeitenSliderTopSlide">
                                    <div key={uuidv4()} className={"swiperImageContainer " + (list.video.url ? 'noImage' : 'hasImage')} style={{ backgroundImage: `url(${list.image.url})`}}>



                                        <CustomView condition={isMobile === true || isTablet === true}>
                                            {list.mobile_video.url ? <video src={list.mobile_video.url} autoPlay={true} muted={true} playsInline={true} loop={false}></video> : ''}
                                        </CustomView>
                                        <CustomView condition={isMobile === false && isTablet === false}>
                                            {list.video.url ? <video src={list.video.url} autoPlay={true} muted={true} playsInline={true} loop={false}></video> : ''}
                                        </CustomView>




                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 col-md-10 offset-md-1">
                                                    <h2 className="mb-3">{list.title}</h2>
                                                    <h4 className="mb-3">{list.description}</h4>
                                                    <NavHashLink to={`#${list.anchor_id}`} onClick={event => handleClick(event, list.anchor_id)} className="btn--regular btn--primary--solid d-inline-block">{t('learn-more')}</NavHashLink>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}

                        </Swiper>
                        :null}

                    {item.slice_type=="hidden_text_module" ?

                        <section className="section2Arbeiten pb-0 padding-topFull-bottomHalf" style={{ backgroundImage: `url(${shapeBg})`}}>
                            <div className="container">
                                <div className="row margin-bottomDefault">
                                    <div className="col-12 col-lg-10 offset-lg-1">
                                        <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                        {item.primary.title ? <h2 className="margin-bottomDefault">{item.primary.title}</h2> : ''}
                                        {item.primary.subtitle ? <h3 className="margin-bottomDefault">{item.primary.subtitle}</h3> : ''}
                                    </div>
                                    <div className="col-12 col-lg-5 offset-lg-1">
                                        <div className="opacity64 richText">
                                            {RichText.render(item.primary.text_column_1) ? RichText.render(item.primary.text_column_1) : ''}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <div className="opacity64 richText">
                                            {RichText.render(item.primary.text_column_2) ? RichText.render(item.primary.text_column_2) : ''}
                                        </div>
                                    </div>


                                </div>



                            </div>

                        </section>


                        :null}

                    {item.slice_type=="custom_text_block" ?

                        <section className="sectionCustomText pt-0 padding-topFull-bottomHalf">
                            <div className="container">
                                <div className="row margin-bottomDefault">
                                    <div className="col-12 col-lg-10 offset-lg-1">
                                        {item.primary.title ? <h3 className="margin-bottomDefault">{item.primary.title}</h3> : ''}
                                    </div>
                                    <div className="col-12 col-lg-5 offset-lg-1">
                                        <div className="opacity64 richText">
                                            {RichText.render(item.primary.description) ? RichText.render(item.primary.description) : ''}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center">
                                        <div className="richText text-center my-5">
                                            <img className="w-100" src={item.primary.image.url} alt=""/>
                                        </div>
                                    </div>


                                </div>



                            </div>

                        </section>


                        :null}

                    {item.slice_type=="parallax_module" ?

                        <Fragment>
                            <CustomView condition={isDesktop === true}>
                                <section className="section3Arbeiten isLight d-flex justify-content-center align-items-end align-items-lg-center" style={{ backgroundImage: `url(${item.primary.image.url})`}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-5 offset-lg-1 d-flex flex-column">
                                                {item.primary.quote ? <h4 className="quote">{item.primary.quote}</h4> : ''}
                                                <div>
                                                    {item.primary.user_name ? <h4>{item.primary.user_name}</h4> : ''}
                                                    {item.primary.user_position ? <p>{item.primary.user_position}</p> : ''}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </CustomView>
                            <CustomView condition={isMobileOnly === true}>
                                <section className="section3Arbeiten isLight d-flex justify-content-center align-items-end align-items-lg-center" style={{ backgroundImage: `url(${item.primary.image_mobile.url})`}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-5 offset-lg-1 d-flex flex-column">
                                                {item.primary.quote ? <h4 className="quote">{item.primary.quote}</h4> : ''}
                                                <div>
                                                    {item.primary.user_name ? <h4>{item.primary.user_name}</h4> : ''}
                                                    {item.primary.user_position ? <p>{item.primary.user_position}</p> : ''}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </CustomView>
                            <CustomView condition={isTablet === true}>
                                <section className="section3Arbeiten isLight d-flex justify-content-center align-items-end align-items-lg-center" style={{ backgroundImage: `url(${item.primary.image_tablet.url})`}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-5 offset-lg-1 d-flex flex-column">
                                                {item.primary.quote ? <h4 className="quote">{item.primary.quote}</h4> : ''}
                                                <div>
                                                    {item.primary.user_name ? <h4>{item.primary.user_name}</h4> : ''}
                                                    {item.primary.user_position ? <p>{item.primary.user_position}</p> : ''}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </CustomView>
                        </Fragment>

                        :null}

                    {item.slice_type=="karriere_accordion_module" ?
                        <section className="section4Arbeiten padding-topFull-bottomHalf">
                            <div className="container">
                                <div className="row margin-bottomDefault">
                                    <div className="col-12 col-lg-10 offset-lg-1">
                                        <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                        <h2 className="margin-bottomDefault">{item.primary.title ? item.primary.title : ''}</h2>
                                        <h3 className="margin-bottomDefault">{item.primary.subtitle ? item.primary.subtitle : ''}</h3>
                                    </div>
                                    <div className="col-12 col-lg-5 offset-lg-1">
                                        <div className="opacity64 richText">
                                            {RichText.render(item.primary.description_row_left) ? RichText.render(item.primary.description_row_left) : ''}
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <div className="opacity64 richText">
                                            {RichText.render(item.primary.description_row_right) ? RichText.render(item.primary.description_row_right) : ''}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="container-fluid p-0">
                                <div className="row g-0">
                                    {/*<AccordionModule onClick={handleClick}/>*/}

                                    <div className="acc">
                                        <dl className="acc__list">
                                            {item.items.map((list, index) => {
                                                const showDescription = index === activeIndex ? "show-description" : "";
                                                const fontWeightBold = index === activeIndex ? "font-weight-bold expandedArea" : "";
                                                const ariaExpanded = index === activeIndex ? "true" : "false";

                                                return (
                                                    <div className="acc__question" key={uuidv4()}>
                                                        <dt id={list.item_id_anchor}>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-10 offset-md-1">
                                                                        <button
                                                                            aria-expanded={ariaExpanded}
                                                                            aria-controls={`acc${index + 1}_desc`}
                                                                            data-qa="acc__question-button"
                                                                            className={`acc__question-button  ${fontWeightBold}`}
                                                                            onClick={(_) => handleClick2(index)}
                                                                            // onClick={() => this.handleClick({index})}

                                                                        >
                                                                            {list.title}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </dt>
                                                        <dd>
                                                            <div className={`acc__desc  ${showDescription}`} id={`acc${index + 1}_desc`}
                                                                 data-qa="acc__desc">
                                                                <div className="acc__imageHolder">
                                                                    {list.video.url ? <video src={list.video.url} autoPlay={true} muted={true} playsInline={true} loop={false}></video> : <img className="w-100" src={list.image.url} alt=""/>}

                                                                    </div>
                                                                <div className="container getItCentered">
                                                                    <div className="row">
                                                                        <div className="col-12 col-md-6"></div>
                                                                        <div className="col-12 col-md-6">
                                                                            <div>
                                                                                {RichText.render(list.content) ? RichText.render(list.content) : ''}
                                                                                <a href={"/de/jobs"} className="btn--regular btn--primary--solid d-inline-block" onClick={() => jobsClick(list.item_id_anchor)} >{t('slideJobsButton')}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </dd>
                                                    </div>
                                                )
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :null}

                            {item.slice_type=="team_arbeiten_module" ?
                                <section className="section5Arbeiten padding-topFull-bottomHalf pb-0">
                                    <div className="container">
                                        <div className="row margin-bottomBigger">
                                            <div className="col-12 col-lg-10 offset-lg-1">
                                                <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                                <h2 className="margin-bottomDefault">{item.primary.title ? item.primary.title : ''}</h2>
                                                <h3 className="margin-bottomDefault">{item.primary.subtitle ? item.primary.subtitle : ''}</h3>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1">
                                                <div className="opacity64 m-0">
                                                    {RichText.render(item.primary.column_text_left) ? RichText.render(item.primary.column_text_left) : ''}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5">
                                                <div className="opacity64 m-0">
                                                    {RichText.render(item.primary.column_text_right) ? RichText.render(item.primary.column_text_right) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            :null}
                            {item.slice_type=="team_slider_module_1" ?
                                <TeamSliderModule/>
                            :null}

                            {item.slice_type=="youtube_section_module" ?
                                <section className="section6Arbeiten padding-topFull-bottomHalf">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-5 offset-lg-1">
                                                <img className="w-100" src={item.primary.image.url} alt=""/>
                                            </div>
                                            <div className="col-12 col-lg-5 d-flex flex-column justify-content-center">
                                                <div>
                                                    <h3>{item.primary.title ? item.primary.title : ''}</h3>
                                                    <div className="margin-bottomDefault opacity64">
                                                        {RichText.render(item.primary.description) ? RichText.render(item.primary.description) : ''}
                                                    </div>
                                                    {item.primary.button_link.url ? <a href={item.primary.button_link.url} target="_blank" className="btn--regular btn--primary--solid d-inline-block">{t('jetzt-ansehen')}</a> : ''}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            :null}
                        {item.slice_type=="campus_module" ?
                            <section className="section7Arbeiten padding-topFull-bottomHalf">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-5 offset-lg-1">
                                            <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                            <h2 className="margin-bottomDefault">{item.primary.title ? item.primary.title : ''}</h2>
                                            <h3 className="margin-bottomDefault">{item.primary.subtitle ? item.primary.subtitle : ''}</h3>
                                            <div className="margin-bottomDefault opacity64">
                                                {RichText.render(item.primary.description) ? RichText.render(item.primary.description) : ''}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="campusBg"></div>
                            </section>
                        :null}
                        {item.slice_type=="benefits_module" ?
                            <section className="section8Arbeiten padding-topFull-bottomHalf isDark" style={{ backgroundImage: `url(${benefitsBg})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1">
                                            <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                            <h2 className="margin-bottomDefault">{item.primary.title ? item.primary.title : ''}</h2>
                                            <div>{RichText.render(item.primary.description) ? RichText.render(item.primary.description) : ''}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1">
                                            <div className="row">

                                                {item.items.map(list =>
                                                    <div key={uuidv4()} className="col-12 col-md-6 col-lg-6">

                                                        <div className="margin-bottomDefault">
                                                            {RichText.render(list.list_item_title) ? RichText.render(list.list_item_title) : ''}
                                                        </div>
                                                        <p className="opacity64 sectionBottomPadding">
                                                            {RichText.render(list.list_item_description) ? RichText.render(list.list_item_description) : ''}
                                                        </p>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </section>
                        :null}

                        {item.slice_type=="atmosphare_module" ?
                            <section className="section9Arbeiten padding-topFull-bottomHalf">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1">
                                            <strong className="pretitle">{item.primary.pretitle ? item.primary.pretitle : ''}</strong>
                                            <h2 className="margin-bottomDefault">{item.primary.title ? item.primary.title : ''}</h2>
                                            <h3 className="margin-bottomDefault">{item.primary.subtitle ? item.primary.subtitle : ''}</h3>
                                        </div>
                                        <div className="col-12 col-lg-5 offset-lg-1">
                                            <div className="opacity64">
                                                {RichText.render(item.primary.description_block_left) ? RichText.render(item.primary.description_block_left) : ''}
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-5">
                                            <div className="opacity64">
                                                {RichText.render(item.primary.description_block_right) ? RichText.render(item.primary.description_block_right) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-10 offset-lg-1">
                                            <Swiper
                                                className="bottomSwiper"
                                                autoplay={{
                                                    "delay": 5000,
                                                    "disableOnInteraction": true
                                                }}
                                                observer={true}
                                                loop={true}
                                                observeParents={true}
                                                spaceBetween={15}
                                                slidesPerView={"auto"}
                                                navigation
                                                //onSlideChange={() => console.log('slide change')}
                                                //onSwiper={(swiper) => console.log(swiper)}

                                            >

                                                {item.items.map(list =>

                                                    <SwiperSlide key={uuidv4()}>
                                                        <div>
                                                            <div className="swiperBottomImageContainer" style={{ backgroundImage: `url(${list.list_item_image.url ? list.list_item_image.url : ''})`}}>

                                                            </div>
                                                            <div className="opacity64">
                                                                {RichText.render(list.list_item_image_caption) ? RichText.render(list.list_item_image_caption) : ''}
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>

                                                )}

                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        :null}


                        {item.slice_type=="kontakt_module" ?
                            <div className="sectionKontakt" style={{ backgroundImage: `url(${shapeBg2})`}}>

                                <div className="container" >
                                    <div className="row">
                                        <div className="col-12 col-lg-6 offset-lg-1">
                                            <div className="KontaktTexts">
                                                <div className="title">{item.primary.title ? item.primary.title : ''}</div>
                                                <div className="description">
                                                    {RichText.render(item.primary.description) ? RichText.render(item.primary.description) : ''}<br/>

                                                </div>
                                                <a href={item.primary.button_link.url ? item.primary.button_link.url : ''}  onClick={() => bottomJobsClick()} className="btn--regular btn--primary--solid d-inline-block">{item.primary.button_text ? item.primary.button_text : ''}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoPersonHolder">
                                    <img
                                        src={item.primary.image.url ? item.primary.image.url : ''}
                                        alt=""/>
                                    <div className="userData">
                                        <div className="teamsName">{item.primary.name ? item.primary.name : ''}</div>
                                        <div className="teamsPosition">{item.primary.position ? item.primary.position : ''}</div>
                                        <div className="d-none d-md-block"><a href={`tel:${item.primary.phone ? item.primary.phone : ''}`} className="teamsPhone">{item.primary.phone ? item.primary.phone : ''}</a>
                                            <div className="d-flex deskSocialCont">
                                                {/* <a href={`mailto:${item.primary.email ? item.primary.email : ''}`} className="btn--inverted--solid btn--regular">{item.primary.email ? item.primary.email : ''}</a> */}
                                            </div>
                                        </div>
                                        <div className="d-block d-md-none">
                                            <div className="d-flex"><a href={`tel:${item.primary.phone ? item.primary.phone : ''}`} className="iconMobileUser"><img
                                                src={iconPhone} alt="email"/></a><a
                                                href={`mailto:${item.primary.email ? item.primary.email : ''}`} className="iconMobileUser"><img
                                                src={iconMail} alt="email"/></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :null}

                </div>
            )}






        </div>
    )
}

export default ArbeitenPage;