import React from 'react';
import '../../App.scss';
import '../../Fonts.scss';
import quote_black from '../../components/images/quote_black.svg';
import quote_white from '../../components/images/quote_white.svg';
import '../../components/style/CaseModules.scss';
import '../../components/style/responsive/style.scss';
import '../../i18n/config';
import { RichText } from 'prismic-reactjs';
import '../style/textOnly.scss';

const viewportContext = React.createContext({});

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};
//Case services module
function SimpleText(props) {
  const { width } = useViewport();
  const breakpoint = 768;
  const mobileBreakpoint = 576;
  const data = props.data;
  let quote = quote_black;


  if (data.primary.iswhite) {
    quote = quote_white;
  }

 console.log(data.primary.text)

  return (
      <div
        className="container-fluid container pt-5"
        style={{
          position: 'relative',
          top: '20%',
        }}
      >
        <div className="row">
          <div className='col-lg-12'>
          < div className = "textOnly-container textOnly-module text-only-module" >
            {data.primary.text != null ? (
              <p
                style={{ color: data.primary.iswhite ? '#fff' : '#000' }}
                className = "opacity64 m-0"
              >
                  {
                    data.primary.text[0].text
                  }
              </p>
            ) : (
              ''
            )}
</div>
          </div>
        </div>
      </div>
    
  );
}

export default SimpleText;
