import React, {useState, useEffect} from "react";
import { RichText, LinkResolver } from "prismic-reactjs";
import '../../components/style/landingPage.scss'
import 'swiper/swiper.scss';
import 'sal.js/dist/sal.css';
import 'swiper/swiper-bundle.css';
import "swiper/components/pagination/pagination.min.css"
import { BrowserView, MobileView, isDesktop, isMobileSafari, isTablet, isMobile,  isIOS, CustomView  } from 'react-device-detect';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Parallax } from 'react-parallax';
import 'react-tabs/style/react-tabs.css';
import { Swiper, SwiperSlide } from 'swiper/react';


import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import videoServices from "../videos/loop-6.mp4";
import videoServicesMobile from "../videos/loop-6-mobile.mp4";
import videoJobs from "../videos/green-on-black.mp4";
import videoTopHoriz from "../videos/loop-horizontal.mp4";
import videoTopVerti from "../videos/loop-vertical.mp4";
import shapePurple from "../images/shapes/new/shape01-new.jpg"
import shapeGreen from "../images/shapes/new/shape02new-desktop.png"
import sal from "sal.js";
import Case2Image from "../images/cases/img_case_02.jpg";

import ParticleBox from "../../Particles";
import {useInView} from "react-intersection-observer";
import loadable from "@loadable/component";
import SectionCasesAnchor from "../SectionCasesAnchor";
import {v4 as uuidv4} from "uuid";
const SectionCasesModule = loadable(() => import ('../SectionCasesModule'));
const SectionTeamSliderNew = loadable(() => import ('../SectionTeamSliderNew'));
const SectionNewsSliderNew = loadable(() => import ('../SectionNewsSliderNew'));


const LandingPageContainer = ( data ) => {
    const url = window.location.pathname.split('/').pop();
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };




    useEffect(() => {
        console.log(`You changed the page to: ${url}`)
    }, [url]);


    useEffect(() => {

        sal({
            threshold: 0.8,
            once: true
        });
    }, []);
    console.log(data);
    let color;
    let words;


    useEffect(()=> {
        window.emos3.send({
            content: 'homepage'
        });
    }, []);

    function contactClick () {
        window.emos3.send(
            {Target:  ['Contact Homepage Email Button Click', 'Button click', 1, 's'], type: 'event'}
        )
    }

    color = data.data.islight ? 'isLight' : 'isDark';

    function removeClass(e) {
        let element;
        element = document.getElementById("controller-id");

        element.classList.remove("hidden-all");

        let tabs;
        tabs = document.getElementsByClassName('react-tabs__tab');
        for (var i = 0, len = tabs.length; i < len; i++) {
            if (tabs[i].classList.contains('react-tabs__tab')) {
                tabs[i].classList.add('react-tabs__tab--selected');
            }
        }


    }

    useEffect(() => {

        function playPauseVideo() {
            let videos = document.querySelectorAll(".videospecial");
            videos.forEach((video) => {
                // We can only control playback without insteraction if video is mute
                if (video.classList.contains('completed')) {

                } else {
                    video.muted = true;
                    video.playsInline = true;
                    video.loop = false;
                    // Play is a promise so we need to check we have it
                    let playPromise = video.play();
                    if (playPromise !== undefined) {
                        playPromise.then((_) => {
                            let observer = new IntersectionObserver(
                                (entries) => {
                                    entries.forEach((entry) => {
                                        if (
                                            entry.intersectionRatio !== 1 &&
                                            !video.paused
                                        ) {
                                            video.pause();
                                        } else if (video.paused) {
                                            video.play();
                                        }
                                    });
                                },
                                { threshold: 0.2 }
                            );
                            observer.observe(video);
                        });
                    }
                }

            });
        }

// And you would kick this off where appropriate with:
        playPauseVideo();

        function addClassEndedVideo() {
            let videos = document.querySelectorAll(".videospecial");
            videos.forEach((video) => {
                video.onended = function (e) {
                    //video.classList.add('completed');
                };
            });
        }
        addClassEndedVideo();



    }, []);



    function myFunction() {
        const div =  document.querySelector('.react-tabs__tab-list .react-tabs__tab');
        div.classList.add('selected');
    }




    useEffect(() => {
        let tabs;
        tabs = document.getElementsByClassName('react-tabs__tab');
        for (var i = 0, len = tabs.length; i < len; i++) {
            if (tabs[i].classList.contains('react-tabs__tab--selected')) {
                tabs[i].classList.remove('react-tabs__tab--selected');
            }
        }

        color = data.data.islight;

        if (!color) {
            document.querySelector("header").classList.add("inverted");
            document.querySelector("footer").classList.add("darkVariant");
        }

    }, []);

    // useEffect(() => {
    //
    //     var text = [ "Wir kreieren Digital Experiences – end-to-end", "Wir designen Digital Experiences – end-to-end","Wir bauen Digital Experiences – end-to-end"];
    //     var counter = 0;
    //     var elem = document.querySelector("#changer");
    //     setInterval(change, 3000);
    //     function change() {
    //         elem.innerHTML = text[counter];
    //         counter++;
    //         if (counter >= text.length) {
    //             counter = 0;
    //             // clearInterval(inst); // uncomment this if you want to stop refreshing after one cycle
    //         }
    //     }
    //
    // }, []);

    return (
        <div className="landingPage">
            <div className={`${color} pageHeader`}>

                <CustomView condition={isMobile === true || isTablet === true}>
                    <div className="topVideoContainer d-block">
                        <video src={videoTopVerti} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                    </div>
                </CustomView>
                <CustomView condition={isMobile === false && isTablet === false}>
                    <div className="topVideoContainer d-block">
                        <video src={videoTopHoriz} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                    </div>
                </CustomView>


                <div className="container">
                    <div className="row text-center">

                        {data.data.pretext ? <p><b>{data.data.pretext}</b></p> : ''}
                        {RichText.asText(data.data.title) ? <h1>{RichText.asText(data.data.title)}</h1> : ''}

                    </div>
                </div>

            </div>


            {data.data.body1.map( item =>
                <section key={uuidv4()} className={item.uid}>

                        {item.slice_type=="heading_with_two_columns"?
                                <div className="slideContentHolder">
                                    <h2>{RichText.asText(item.primary.title)}</h2>
                                </div>
                         :null}

                    {item.slice_type=="services_module"?
                        <div id="sectionMachen" className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} services_module text-start`}>
                            <div className="container preinfinity2">
                                <div className="row">
                                    <div className="col-12 col-lg-10 offset-lg-1">
                                        {/*{item.primary.title1 ? <h2>{item.primary.title1}</h2> : ''}*/}
                                        <div className="h2">{item.primary.title1 ? <span>{item.primary.title1}</span> : ''}
                                        <div className="dropping-texts">
                                            {item.primary.animation_word_1 ? <div>{item.primary.animation_word_1}</div> : ''}
                                            {item.primary.animation_word_2 ? <div>{item.primary.animation_word_2}</div> : ''}
                                            {item.primary.animation_word_3 ? <div>{item.primary.animation_word_3}</div> : ''}
                                            {item.primary.animation_word_4 ? <div>{item.primary.animation_word_4}</div> : ''}
                                            {item.primary.animation_word_5 ? <div>{item.primary.animation_word_5}</div> : ''}
                                            {item.primary.animation_word_6 ? <div>{item.primary.animation_word_6}</div> : ''}
                                            {item.primary.animation_word_7 ? <div>{item.primary.animation_word_7}</div> : ''}


                                        </div><br/>
                                            {item.primary.text_under_animation ? <h1 className="h2">{item.primary.text_under_animation}</h1> : ''}</div>
                                        {item.primary.description1 ? <h3>{item.primary.description1}</h3> : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid infinity2">
                                <CustomView condition={isMobile === true || isTablet === true}>
                                    <div className="row position-relative">
                                        <video src={videoServicesMobile} loop={true} autoPlay={true} muted={true} playsInline={true} ></video>

                                        {/*<div className="position-absolute container infinity-words">*/}
                                        {/*    <div className="row">*/}
                                        {/*        {item.primary.infinity_word_1 ? <div className="col-6">{item.primary.infinity_word_1}</div> : ''}*/}
                                        {/*        {item.primary.infinity_word_2 ? <div className="col-6">{item.primary.infinity_word_2}</div> : ''}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </CustomView>
                                <CustomView condition={isMobile === false && isTablet === false}>
                                    <div className="row position-relative">
                                        <video src={videoServices} loop={true} autoPlay={true} muted={true} playsInline={true} ></video>

                                        {/*<div className="position-absolute container infinity-words">*/}
                                        {/*    <div className="row">*/}
                                        {/*        {item.primary.infinity_word_1 ? <div className="col-6">{item.primary.infinity_word_1}</div> : ''}*/}
                                        {/*        {item.primary.infinity_word_2 ? <div className="col-6">{item.primary.infinity_word_2}</div> : ''}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </CustomView>

                            </div>
                            <div className="container postinfinity2">
                                <div className="row services-texts">
                                    <div className="col-12 col-lg-5 offset-lg-1">
                                        {item.primary.service_1 ? <h3 className="service-title">{item.primary.service_1}</h3> : ''}
                                        {item.primary.service_1_description ?  <div className="service-description">{item.primary.service_1_description}</div> : ''}
                                    </div>
                                    <div className="col-12 col-lg-5 offset-lg-1">
                                        {item.primary.service_2 ? <h3 className="service-title">{item.primary.service_2}</h3> : ''}
                                        {item.primary.service_2_description ?  <div className="service-description">{item.primary.service_2_description}</div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        :null}


                        {item.slice_type=="tab_module"?
                                <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} tab_module`}>
                                    {/*<div className="videoPurpleContainer">*/}
                                    {/*    <img src={shapePurple} alt=""/>*/}
                                    {/*    /!*<video className="videospecial" src={videoServices} autoPlay={true} muted={true} playsInline={true} loop={false}></video>*!/*/}
                                    {/*</div>*/}

                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-10 offset-lg-1">


                                                <div id="animationcontainer">
                                                    {/*<p><b>{item.primary.pretitle}</b></p>*/}
                                                    Wir
                                                    <div className="dropping-texts">
                                                        <div>bauen</div>
                                                        <div>kreieren</div>
                                                        <div>designen</div>
                                                    </div><br/>
                                                    Digital Experiences


                                                </div>
                                                {/*<h2 id="changer">{RichText.asText(item.primary.title1)}</h2>*/}
                                                <h3>{RichText.asText(item.primary.subtitle)}</h3>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-12 col-lg-5 offset-lg-1">
                                                    {item.primary.text_box_left ? <p><b>{item.primary.text_box_left}</b></p> : ''}
                                                </div>
                                                <div className="col-12 col-lg-5">
                                                    {item.primary.text_box_right ? <p><b>{item.primary.text_box_right}</b></p> : ''}
                                                </div>
                                            </div>

                                                <Tabs>
                                                    <div className="col-12 col-lg-11 offset-lg-1" data-sal="slide-left" data-sal-duration="800"
                                                         data-sal-delay="300">
                                                    <TabList>
                                                        {item.items.map((list, index) =>
                                                            <Tab onClick={(e) => {removeClass(item); handleToggle()}} >
                                                                <a key={list.uid} data-service={`serviceNrShow${index}`}>{list.name}</a>
                                                            </Tab>
                                                        )}
                                                    </TabList>
                                                    </div>
                                                    <div className="col-12 col-lg-9 offset-lg-1">
                                                    <div id="controller-id" className="hidden-all">
                                                        {item.items.map((list, index) =>
                                                            <TabPanel className={`serviceNrShow${index}`}>

                                                                <div className="row opacity-48">
                                                                    <div className="col-12">
                                                                        {/*{list.name ? <h2>{list.name}</h2> : ''}*/}
                                                                    </div>
                                                                    <div className="col-12 col-lg-8">
                                                                        {list.description1 ? <h3>{list.description1}</h3> : ''}
                                                                    </div>
                                                                    <div className="col-12 offset-lg-1 col-lg-3">
                                                                        <h4>{list.services_title}</h4>
                                                                        <ul>
                                                                            {list.service1 ? <li>{list.service1}</li> : ''}
                                                                            {list.service2 ? <li>{list.service2}</li> : ''}
                                                                            {list.service3 ? <li>{list.service3}</li> : ''}
                                                                            {list.service4 ? <li>{list.service4}</li> : ''}
                                                                            {list.service5 ? <li>{list.service5}</li> : ''}
                                                                            {list.service6 ? <li>{list.service6}</li> : ''}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </TabPanel>
                                                        )}
                                                    </div>

                                                     </div>
                                                </Tabs>



                                        </div>
                                    </div>

                                </div>
                        :null}




                        {item.slice_type=="parallax_module"?
                            <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} parallax_module text-start`}>
                                <Parallax bgImage={item.primary.image1.url} bgImageAlt="" strength={200}>
                                    <div className="container" style={{ height: '100vh' }}>
                                        <div className="row d-flex align-items-start flex-column justify-content-end h-100">
                                            <div className="col-12 col-lg-7 offset-lg-1 quote-container">
                                                    {item.primary.quote ? <h3>{item.primary.quote}</h3> : ''}
                                                    {item.primary.name ? <h4>{item.primary.name}</h4> : ''}
                                                    {item.primary.position ? <span>{item.primary.position}</span> : ''}
                                            </div>


                                        </div>
                                    </div>
                                </Parallax>
                            </div>

                        :null}


                    {item.slice_type=="parallax_module"?
                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} parallax_module parallax_module_fixed text-start`}>
                            <div className="parallax-one" style={{ backgroundImage: `url(${item.primary.image1.url})` }}>
                                <div className="container" style={{ height: '100vh' }}>
                                    <div className="row d-flex align-items-start flex-column justify-content-end h-100">
                                        <div className="col-12 col-lg-7 offset-lg-1 quote-container">
                                            {item.primary.quote ? <h3>{item.primary.quote}</h3> : ''}
                                            {item.primary.name ? <h4>{item.primary.name}</h4> : ''}
                                            {item.primary.position ? <span>{item.primary.position}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        :null}
                    

                    {item.slice_type=="text_image_module"?
                        <div id="sectionMission" className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} text_image_module`}>
                            <div className="imagePurpleContainer">
                                <img src={shapePurple} alt=""/>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-10 col-sm-12 col-md-12 col-xl-11 offset-xl-1">
                                        {/*{item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}*/}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                    </div>
                                    <div className="col-12 col-xl-10 offset-xl-1">
                                        {RichText.asText(item.primary.subtitle) ? <h3>{RichText.asText(item.primary.subtitle)}</h3> : ''}
                                    </div>
                                    <div className="col-12 d-block d-xl-none">

                                    </div>

                                </div>
                            </div>

                            {item.primary.image1 ? <img className="w-100" src={item.primary.image1.url} alt=""/> : 'nu are image'}

                        </div>
                        :null}


                    {item.slice_type=="case_module"?

                        <div>
                            <SectionCasesAnchor/>
                            {item.primary.casemodule ? <SectionCasesModule/> : 'deactive'}
                        </div>

                        :null}

                    {item.slice_type=="mini_carousel_module"?

                        <div className={`${item.primary.alignment ? 'rightside' : 'leftside'} ${item.primary.islight1 ? 'isLight' : 'isDark'} mini_swiper_module`}>
                            <Swiper
                                loop={true}
                                freeMode={true}
                                grabCursor={true}
                                breakpoints={{
                                    // when window width is >= 320px
                                    320: {
                                        slidesPerView: 3,
                                    },
                                    768: {
                                        spaceBetween: 50,
                                        slidesPerView: 4,
                                    },
                                }}
                                observer={true}
                                draggable={false}
                                autoplay={{
                                        delay: 1,
                                        disableOnInteraction: false,
                                        //waitForTransition: false,
                                        reverseDirection: item.primary.alignment ? true : false,
                                }}
                                onSlideChange={() => {}}
                                speed={5000}
                                freeModeMomentum={false}
                                observeParents={true}
                                observeSlideChildren={true}
                                onObserverUpdate={() =>{}}
                                //centeredSlides={true}
                                spaceBetween={50}
                                slidesPerView={4}
                            >
                                {item.items.map(list =>
                                    <SwiperSlide key={uuidv4()}>
                                        <img  className={list.uid} src={list.image1.url} alt=""/>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>

                        :null}


                    {item.slice_type=="carousel_module_team"?

                        <div id="sectionTeam" className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} team_module`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {/*{item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}*/}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}
                                    </div>
                                </div>
                                <SectionTeamSliderNew/>
                                {/*<div className="row">*/}
                                {/*    <div className="col-12 col-lg-5 offset-lg-1">*/}
                                {/*        {item.primary.text_column_one ? <p><b>{item.primary.text_column_one}</b></p> : ''}*/}

                                {/*    </div>*/}
                                {/*    <div className="col-12 col-lg-5">*/}
                                {/*        {item.primary.text_column_two ? <p><b>{item.primary.text_column_two}</b></p> : ''}*/}

                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                        </div>

                        :null}

                    {item.slice_type=="full_width_image_module"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} full_width_image_module`}>
                            <div className="videoGreenContainer">
                                <img src={shapeGreen} alt=""/>
                                {/*<video className="videospecial" src={videoJobs} autoPlay={true} muted={true} playsInline={true}*/}
                                {/*       loop={false}></video>*/}
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {/*{item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}*/}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}

                                        {item.primary.button_text ? <a href={item.primary.button_link.url} className="btn--regular btn--primary--solid btn--inverted--solid">{item.primary.button_text}</a> : ''}
                                    </div>
                                </div>
                            </div>

                        </div>

                        :null}


                    {item.slice_type=="carousel_module_news"?
                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} news_module`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}
                                    </div>
                                </div>
                                <SectionNewsSliderNew/>
                                <div className="row">
                                    <div className="col-12 col-xl-5 offset-xl-1">
                                        {item.primary.button_text ? <a href={item.primary.button_link.url} className="btn--regular btn--primary--solid btn--inverted--solid">{item.primary.button_text}</a> : ''}

                                    </div>

                                </div>
                            </div>

                        </div>

                        :null}


                    {item.slice_type=="contact_module"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} contact_module`}>
                            <div className="container position-absolute">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                                        <div className="KontaktTitle">
                                            {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-6 col-lg-5">
                                        <div className="mb-4">
                                            {item.primary.company ? <p className="company_contact">{item.primary.company}</p> : ''}
                                        </div>
                                        <div className="mb-4">
                                            <a href={"tel:"+ item.primary.phone} className="phone">{item.primary.phone}</a>
                                        </div>

                                        <a href={"mailto:"+ item.primary.email} onClick={contactClick} className="btn--inverted--solid btn--regular">{item.primary.email}</a>

                                    </div>

                                </div>
                            </div>


                        </div>

                        :null}

                </section>
            )}



        </div>
    );
}
export default LandingPageContainer;
