import logo from './logoLight.svg';
import './style/Footer.scss';
import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, animateScroll as scroll } from "react-scroll";
import linkedin from './images/ic_social_linkedin_white.svg';
import xing from './images/ic_social_xing_white.svg';
import youtube from './images/ic_social_youtube_white.svg';
import {scroller} from "react-scroll";
import { NavHashLink } from 'react-router-hash-link';
import {debounce} from "../utilities/helpers";


function FooterDark() {
    const { t, i18n } = useTranslation();

    const footerRef = useRef(null);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 20) || currentScrollPos < 79);

        setPrevScrollPos(currentScrollPos);
    }, 100);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, visible, handleScroll]);

    let languageKey;
    let contactLink;
    let newsLink;
    let jobsLink;
    let imprintLink;

    if (i18n.language=='en') {
        languageKey = '/en/home';

        contactLink = "/en/contact";
        newsLink = "/en/news";
        jobsLink = "/en/jobs";
        imprintLink = "/en/imprint";
    } else {

        languageKey = '/de/home'
        contactLink = "/de/contact";
        newsLink = "/de/news";
        jobsLink = "/de/jobs";
        imprintLink = "/de/imprint";
    }
    const today = new Date();
    return (
        <footer ref={footerRef} className="darkVariant">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-2">
                        <img className="logo" src={logo} alt=""/>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Menu</div>
                        <ul className="footerSectionList">
                            <li><NavHashLink to={languageKey+"#sectionMission"}>{t('header-mission')}</NavHashLink></li>
                            <li><NavHashLink  to={languageKey+"#sectionMachen"} >{t('header-machen')}</NavHashLink></li>
                            <li><NavHashLink to={languageKey+"#sectionTeam"} >{t('header-sind')}</NavHashLink></li>
                            <li><a href={"/" + i18n.language + "/page/arbeiten"} className="kareers-link-menu">{t('header-arbeiten')}</a></li>
                            <li><a href={"/" + i18n.language + "/jobs"}>Jobs</a></li>
                            <li><a href={"/" + i18n.language + "/news"}>News</a></li>
                            <li><a href={"/" + i18n.language + "/contact"}>{t('header-kontakt')}</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">{t('footer-companies')}</div>
                        <ul className="footerSectionList">
                            <li><a href="https://www.dymatrix.de/" target="_blank">PIA DYMATRIX</a></li>
                            <li><a href="https://www.econda.de/" target="_blank">PIA econda</a></li>
                            <li><a href="https://feed-dynamix.de/" target="_blank">PIA FeedDynamix</a></li>
                            <li><a href="https://piamedia.com/" target="_blank">PIA Media</a></li>
                            <li><a href="http://www.nordpol.com/" target="_blank">PIA NORDPOL+</a></li>
                            <li><a href="https://www.udg.de/en" target="_blank">PIA UDG</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">{t('footer-general-information')}</div>
                        <ul className="footerSectionList">
                            <li><a href={"/" + i18n.language + "/page/imprint"}>{t('footer-imprint')}</a></li>
                            <li><a href={"/" + i18n.language + "/page/privacy"}>{t('footer-privacy')}</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Social Media</div>
                        <div className="footerSectionSocialList d-flex">
                            <a href="https://www.linkedin.com/company/performance-interactive-alliance/" target="_blank" rel="noreferrer"><img src={linkedin} alt=""/></a>
                            <a href="https://www.xing.com/companies/piaperformanceinteractivealliance?sc_o=da980_e" target="_blank" rel="noreferrer"><img src={xing} alt=""/></a>
                            <a href="https://www.youtube.com/channel/UC5KJjgkVJ4-ps6ldvuhVopg" target="_blank" rel="noreferrer"><img src={youtube} alt=""/></a>
                        </div>


                    </div>
                    <div className="col-12 col-lg-2">
                        <div className="copy">&copy;{today.getFullYear()} PIA</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterDark;