import React, {useEffect, useState} from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import Footer from "./components/Footer";
import './components/style/responsive/style.scss';
import ScrollToTop from "./components/ScrollToTop";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useTranslation} from "react-i18next";
import ArbeitenPage from "./components/pages/Arbeiten";
import {Client} from "./utilities/prismicHelpers";
import NotFound from "./NotFound";
import {RichText} from "prismic-reactjs";
import LandingPageContainer from "./components/pages/landingPage";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};


const Arbeiten = ({  }) => {

    const { i18n, t } = useTranslation();
    const uid = 'carrer';
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    let isGerman;
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en-us';
        isGerman = false;
    }
    else {
        languageKey = 'de-de';
        isGerman = true;
    }

    const href = window.location.href;

    useEffect(() => {
        console.log(`Page href is: ${href}`)
    }, [href]);

    // Get the blog post document from Prismic
    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await Client.getByUID('employer_branding_page', uid ,{lang:languageKey});

                if (doc) {
                    setPrismicDoc(doc);
                    console.log(doc);


                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);

    if (prismicDoc) {
        const title =
            prismicDoc.data.meta_title.length !== 0 ?
                prismicDoc.data.meta_title :
                'Untitled';
        const metatags =
            prismicDoc.data.meta_tgs.length !== 0 ?
                prismicDoc.data.meta_tgs :
                'Untitled';
        const metadescription =
            prismicDoc.data.meta_description.length !== 0 ?
                prismicDoc.data.meta_description :
                'Untitled';

        return (
            <HelmetProvider>
                <div className="App">
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={metadescription} />
                        <meta name="keywords" content={metatags} />
                        <link rel="canonical" href={href} />

                    </Helmet>
                    <ViewportProvider>
                        <HeaderComponent />
                        <div id="postHeader"></div>
                        <ArbeitenPage data={prismicDoc.data}/>
                    </ViewportProvider>

                    <Footer/>


                    <ScrollToTop/>
                </div>
            </HelmetProvider>
        )
    } else if (notFound) {
        return <NotFound />;
    }
    return null;

}

export default Arbeiten;
