import React, { useEffect,useState } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import './components/style/responsive/style.scss';
import SectionsDesktopHolder from "./components/SectionsDesktopHolder";
import SectionsMobileHolder from "./components/SectionsMobileHolder";
import ScrollToTop from "./components/ScrollToTop";
import ScrollAnimation from "./components/ScrollAnimation";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import JobsPositionPageContainer from "./components/pages/jobsPosition";
import FooterDark from "./components/FooterDark";
import { RichText } from 'prismic-reactjs';
import { Client } from "./utilities/prismicHelpers";
import NotFound from "./NotFound";
import axios from 'axios';


const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

const JobsPosition = ({ match }) => {
    const [Jobdata, setJobData] = useState({jobPosts: null,jobPostsCopy: null });
  const [notFound, toggleNotFound] = useState(false);
   console.log(match.params.jobDbId);
  const uid = match.params.jobDbId;
    const { t , i18n } = useTranslation();
    let languageKey;
    
    if (i18n.language=='en') {
        languageKey = 'en-us';
    } 
    else {
        languageKey = 'de-de';
    }
    const href = window.location.href;

    useEffect(() => {
        console.log(`Page href is: ${href}`)
    }, [href]);


  useEffect(() => {
   const fetchPrismicData = async () => {
       axios.request({
        url: `https://api.softgarden.io/api/rest/v3/frontend/jobslist/76012_extern/job/${match.params.jobDbId}`,
        method: "get",
        auth: {
            username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
            password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
        }
        }).then(function(results) {
        console.log(results);  
        setJobData({ jobPosts: results.data,jobPostsCopy: results.data});
        });
    }
    fetchPrismicData();
  }, [uid]);

    if(Jobdata.jobPosts){
    return (

        <div className="App JobPosPage inverted">
            <Helmet>
                <title>{t('homepage-metaTitle')}</title>
                <meta name="description" content="{t('homepage-metaDescription')}" />
                <link rel="canonical" href={href} />
                {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
                {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
                {/*<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet"/>*/}
            </Helmet>
            <ViewportProvider>
                <HeaderComponent />
                <div id="postHeader"></div>
                <JobsPositionPageContainer  data={Jobdata.jobPosts} title={match.params.name}/>
            </ViewportProvider>
            <div className="blackVariant">
                <FooterDark/>
            </div>

            <ScrollToTop/>
        </div>
    )
} else if (notFound) {
    return <NotFound />;
  }
  return null;
}

export default JobsPosition;

