import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleGallery(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;

    console.log('module gallery', data);

    return (
        <div>mod gallery</div>
    );
}

export default ModuleGallery;