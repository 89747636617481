import React, {useState, useEffect, useRef, PureComponent} from "react";
import '../../components/style/jobsContainer.scss'
import arrowImage from "../../components/images/ic_arrow_down_white_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import shape3img from "../../components/images/shapes/new/bg_shape_03.png";
import shape11img from "../../components/images/shapes/new/bg_shape_12.png";
import iconLinkedIn from "../../components/images/ic_social_linkedin_white.svg";
import iconXing from "../../components/images/ic_social_xing_white.svg";
import iconTwitter from "../../components/images/ic_social_twitter_white.svg";
import axios from 'axios';
import ReactToPrint, { useReactToPrint } from "react-to-print";

import blueSummit from "../../components/images/logos/agencies/ic_logo_agency_bluesummit.svg"
import { RichText } from "prismic-reactjs";
import { useTranslation } from "react-i18next";
import {Anchor} from "../blocks/anchor";


const JobsPositionPageContainer1 = React.forwardRef(({props,data,title}, ref) => {
    
    gsap.registerPlugin(ScrollTrigger);
    const [jobCatData, setJobCat] = useState({jobCat: null});
    const [jobWorkExpData, setWorkExp] = useState({jobExp: null});
    const [jobEmpType, setJobEmpType] = useState({jobType: null});
    const [jobWorkTime, setJobWorkTime] = useState({jobTime: null});

    var data1=data;    
    let myStr = data1.company_name;
    let firstWord = myStr.split(" ")[0];
    let imgName = myStr.replace(/ /g,"_");
    const {t, i18n} = useTranslation();
    useEffect( () => {
        if (window.location.href.indexOf('jobs') > 0) {
            document.querySelector("header").classList.add("inverted")
        }
        
       const fetchCategories= async () => {
       axios.request({
        url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/JOB_CATEGORY?locale=${languageKey}`,
        method: "get",
        auth: {
            username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
            password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
        }
        }).then(function(results) {
        setJobCat({ jobCat: results.data});
        });
        }
           const fetchWorkExp= async () => {
       axios.request({
        url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/WORK_EXPERIENCE?locale=${languageKey}`,
        method: "get",
        auth: {
            username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
            password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
        }
        }).then(function(results) {
        setWorkExp({ jobExp: results.data});
        });
        }
        const fetchEmpType= async () => {
        axios.request({
        url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/EMPLOYMENT_TYPE?locale=${languageKey}`,
        method: "get",
        auth: {
            username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
            password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
        }
        }).then(function(results) {
        setJobEmpType({ jobType: results.data});
        });
        }
        const fetchWorkTime= async () => {
        axios.request({
        url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/WORKING_HOURS?locale=${languageKey}`,
        method: "get",
        auth: {
            username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
            password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
        }
        }).then(function(results) {
        setJobWorkTime({ jobTime: results.data});
        });
        }
        fetchWorkTime();
        fetchEmpType();
        fetchWorkExp();
        fetchCategories();
     }, []);
    let languageKey;
  
    if (i18n.language=='en') {
        languageKey = 'en';
    } 
    else {
        languageKey = 'de';
    }



    const url = window.location.pathname.split('/').pop();
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('jobs') > 0) {
            document.querySelector(".jobs-link-menu").classList.add("active");
        }



    }, [url]);

    useEffect(()=> {
        window.emos3.send({
            content: `Job Position : ${url}`
        });
    }, []);
  var jobCat='';
    var jobExp='';
var jobEmp='';
var jobTime='';
   if(jobCatData.jobCat){
    jobCat=jobCatData.jobCat
    console.log(jobCat);
   }
   if(jobWorkExpData.jobExp){
    jobExp=jobWorkExpData.jobExp;   
   }
    if(jobEmpType.jobType){
    jobEmp=jobEmpType.jobType;   
   }
    if(jobWorkTime.jobTime){
    jobTime=jobWorkTime.jobTime;   
    }



    return (
        
        <div className="jobsPageContainer jobsPosition" ref={ref}>
            <div className="sectionTopJobs">
                <div className="animationJobsPositionRight">
                    <img src={shape3img} alt="" style={{width: '100%', height: '100%'}}/>
                </div>
                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row mb-0">
                        <div className="col-12 offset-md-0 col-md-8 offset-lg-1 col-lg-7">
                            <div className="companyLogo"><img src={'/images/companies/' + imgName + '.png'} alt={imgName}/></div>

                            <h1 className="PageTitle" >{data1.externalPostingName}</h1>
                            <div className="jobLocation">{data1.company_name}: {data1.geo_city}</div>
                        </div>
                        <div className="col-12 offset-md-1 col-md-3 offset-lg-1 col-lg-2  text-end">
                            <div className="jobLocation text-right">{t('LetsGo')}</div>
                            <Anchor children="Apply" anchorStyle="btn--inverted--solid" anchorSize="btn--big" href={data1.applyOnlineLink}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 offset-lg-1 col-lg-4">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="sidebarItem">
                                        <h4>Stellenkategorie</h4>
                                        <p>{jobCat[data1.jobCategories[0]]}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="sidebarItem">
                                        <h4>Arbeitserfahrung</h4>
                                        <p>{jobExp[data1.workExperiences[0]]}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="sidebarItem">
                                        <h4>Art der Anstellung</h4>
                                        <p>{jobEmp[data1.employmentTypes[0]]}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="sidebarItem">
                                        <h4>Arbeitszeit</h4>
                                         <p>{jobTime[data1.workTimes[0]]}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-6 jobPositionSoftgardenContent" dangerouslySetInnerHTML={{__html: data1.jobAdText}}>
                        </div>
                    </div>
                    <div className="row mt-0">
                        <div className="col-12 offset-lg-1 col-lg-4">
                            <div className="subTitleJob">{data.your_profile_title}</div>
                        </div>
                        <div className="col-12 offset-lg-1 col-lg-6">
                            {/* <ul className="jobInfo">
                                {data.your_profile_list.map(list =>
                                <li>{list.text}</li>
                                        )}
                              
                            </ul> */}
                        </div>
                    </div>
                </div>

                <div className="container" style={{zIndex: '4', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-lg-4 offset-lg-1">
                                    <div className="titleJobsBottom">{t('AboutGroup')}</div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12 col-lg-4 offset-lg-1">
                                    <div className="descriptionJobsBottom1">
                                        <p>{t('AboutDescription1')}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="descriptionJobsBottom2">
                                        <p>{t('AboutDescription2')}</p>
                                        <p>{t('AboutDescription3')}</p>
                                        <p>{t('AboutDescription4')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="titleJobsBottom text-center">{t('LetsGo')}</div>
                                    <div className="bottom-btn">
                                        <Anchor children="Apply" anchorStyle="btn--inverted--solid" anchorSize="btn--big" href={data1.applyOnlineLink}/>
                                    </div>


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                   <div className="socialBottom d-flex justify-content-between">
                                       <div className="socialText">{t('ShareJob')}</div>
                                       <ul>
                                           <li><a href=""><img src={iconLinkedIn} alt="linkedin"/></a></li>
                                           <li><a href=""><img src={iconXing} alt="xing"/></a></li>
                                           <li><a href=""><img src={iconTwitter} alt="twitter"/></a></li>
                                       </ul>
                                   </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="shapesJobsPageKontakttrigger"></div>
            </div>


            <div className="sectionKontakt">
                <div className="animationJobsPositionBottom">
                    <img src={shape11img} alt="" style={{width: '1280px', height: '1280px'}}/>
                </div>

                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 offset-md-1 col-md-9 col-lg-6 offset-lg-1">
                            <div className="KontaktTexts">
                                <div className="title">{t('InternalHRTitle')}</div>
                                <div className="description">{t('InternalHRDescription')}</div><br/><br/>
                                <Anchor children={t('SoftgardenTextCV')} anchorStyle="btn--inverted--solid" anchorSize="btn--regular" href={'https://short.sg/a/14147169'} target='_blank'/>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="infoPersonHolder">
                    <img src={"/team/img_team1.avif"} alt=""/>
                    <div className="userData">
                        <div className="teamsName">Christine Löw</div>
                        <div className="teamsPosition">Group Lead People & Culture</div>
                        <div className="d-none d-md-block">
                            <a href={"tel:+49 151 11 421529"} className="teamsPhone">+49 151 54679127</a>
                            <div className="d-flex deskSocialCont">
                                {/* <Anchor children={'jobs@pia.me'} anchorStyle="btn--inverted--solid" anchorSize="btn--regular" href={'mailto:jobs@pia.me'}/> */}

                            </div>
                        </div>
                        <div className="d-block d-md-none">
                            <div className="d-flex">
                                <a href={"tel:+49 151 11 421529"} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                <a href={"mailto:annika-sophie.butt@pia.me"} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>



            <div className="shapesJobsPositionPagetrigger"></div>


        </div>


    );
    });

class JobsPositionPageContainer extends React.PureComponent {
    
render(){
    const pageStyle = `
 
  @media print {
      .jobsPageContainer .jobPositionSoftgardenContent li{
          color:black !important;
      }
      .jobsPageContainer .jobPositionSoftgardenContent{
               color:black !important;
      }
       .jobsPageContainer .jobPositionSoftgardenContent p{
          color:black !important;
      }
      div{
                    color:black !important;
      }
      p,ul{
                    color:black !important;
      }
      .PageTitle{
          color:black !important;
      }
    .page {
      background-color:white;
      color:black;
    }
    .animationJobsPositionRight,.animationJobsPositionBottom{
        display:none;
    }
    
  }
  @media all {
  .page-break {
    display: none;
  }
}
`;
    return (
       <div>
        <JobsPositionPageContainer1 data={this.props.data} title={this.props.title} ref={el => (this.componentRef = el)} />

        <ReactToPrint   pageStyle={()=> pageStyle} 
          trigger={() => <a className="getPDFButton btn--big btn--inverted--solid" style={{color: "white",textAlign:'center'}} href="#">Get PDF</a>}
          content={() =>this.componentRef}
        />
      </div>
    );

  }
}

export default  JobsPositionPageContainer;