

export default function validateInfo(values) {


    let errors = {}
    if (!values.name.trim()) {
        errors.name = "Name required"
    }
    if (!values.forename.trim()) {
        errors.forename = "Forename required"
    }
    if (!values.email.trim()) {
        errors.email = "Email required"
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    // if (!values.phone.trim()) {
    //     errors.phone = "Phone required"
    // } else if (!values.phone.match(/^[0-9]+$/)) {
    //     errors.phone = 'Only digits are allowed';
    // }
    if (values.phone.trim()) {
        if (!values.phone.match(/^[0-9]+$/)) {
            errors.phone = 'Only digits are allowed';
        }
    }

    if (!values.company.trim()) {
        errors.company = "Company required"
    }

    if (!values.position.trim()) {
        errors.position = "Position required"
    }

    return errors;
}

