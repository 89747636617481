import React from 'react';
import '../../components/style/SectionForm.scss';
import validate from './validateInfo';
import useFormCareers from './useFormCareers';
import FormInput from "./form-input.component";
import arrowImage from "../images/ic_arrow_down_white_small.svg";
import {useTranslation} from "react-i18next";
import {Button} from "../blocks/button";
import {Anchor} from "../blocks/anchor";
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";

const FormTabCareers = ({ submitForm }) => {
    const { handleChange, handleSubmit, values, errors } = useFormCareers(
        submitForm,
        validate
    );
    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);

    const { t, i18n } = useTranslation();
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en';
    } else {
        languageKey = 'de';
    }

    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'en-us' }
            )
            if (response) {
                setDocData(response.results[0]);
            }

        }
        fetchData()
    }, [])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'de-de' }
            )
            if (response) {
                setDocDataDe(response.results[0]);
            }

        }
        fetchData()
    }, [])

    return (
        <div className="formContainer mb-5 d-block">


            <form id="formtab3" onSubmit={handleSubmit} method="POST" noValidate>


                <Button type={'submit'} buttonStyle='btn--solid' buttonSize='btn--regular'>Autofill mit Linkedin</Button>

                <FormInput
                    name='name'
                    type='text'
                    onChange={handleChange}
                    value={values.name}
                    label={t('formName') + "*"}
                    error={errors.name}
                    required
                />
                <FormInput
                    name='forename'
                    type='text'
                    onChange={handleChange}
                    value={values.forename}
                    label={t('formForename') + "*"}
                    error={errors.forename}
                    required
                />

                <FormInput
                    name='email'
                    type='email'
                    onChange={handleChange}
                    value={values.email}
                    label={t('formEmail') + "*"}
                    error={errors.email}
                    required
                />
                <FormInput
                    name='phone'
                    type='tel'
                    onChange={handleChange}
                    value={values.phone}
                    label={t('formTelefon')}
                    error={errors.phone}

                />


                <FormInput
                    name='title'
                    type='text'
                    onChange={handleChange}
                    value={values.title}
                    label='Title'
                    error={errors.title}

                />
                <FormInput
                    name='message'
                    type='textarea'
                    onChange={handleChange}
                    value={values.message}
                    label={t('formMessage')}
                    error={errors.message}
                    required
                />

                <div className="privacyContainer mt-5" htmlFor="privacy">
                    <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                           value="1"/>
                    <span>{t('formCheckboxText')}</span>
                </div>
                <div className="my-4 d-flex">
                    <Button type={'submit'} buttonStyle='btn--solid' buttonSize='btn--regular'>Anfrage senden</Button>
                    <div className="mt-4 ms-5">
                        <Anchor children={t('slideJobsButton')} anchorStyle="btn--dark--solid" anchorSize="btn--regular" href={`/${languageKey}/jobs`}/>
                    </div>

                </div>




            </form>


            <div className="infoPersonHolder" style={{position: 'relative'}}>
                {
                    isGerman ? (

                        <React.Fragment>
                            {
                                docDe ? (
                                    <img src={ docDe.data.photo.url} alt=""/>

                                ) : <div></div>
                            }
                        </React.Fragment>
                    ) : <React.Fragment>
                        {
                            doc ? (
                                <img src={ doc.data.photo.url} alt=""/>
                            ) : <div></div>
                        }
                    </React.Fragment>
                }

                <div className="userData">

                    {
                        isGerman ? (

                            <React.Fragment>
                                {
                                    docDe ? (
                                        <div className="teamsName">{docDe.data.user_name}</div>

                                    ) : <div></div>
                                }
                            </React.Fragment>
                        ) : <React.Fragment>
                            {
                                doc ? (
                                    <div className="teamsName">{doc.data.user_name}</div>
                                ) : <div></div>
                            }
                        </React.Fragment>
                    }
                    {
                        isGerman ? (
                            <React.Fragment>
                                {
                                    docDe ? (
                                        <div className="teamsPosition">{docDe.data.user_position}</div>

                                    ) : <div></div>
                                }
                            </React.Fragment>
                        ) : <React.Fragment>
                            {
                                doc ? (
                                    <div className="teamsPosition">{doc.data.user_position}</div>
                                ) : <div></div>
                            }
                        </React.Fragment>
                    }
                    <div className="d-none d-md-block">

                        {
                            isGerman ? (

                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <a href={"tel:"+ docDe.data.phone} className="teamsPhone">{docDe.data.phone}</a>

                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <a href={"tel:"+ doc.data.phone} className="teamsPhone">{doc.data.phone}</a>
                                    ) : <div></div>
                                }
                            </React.Fragment>
                        }

                        {
                            isGerman ? (

                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <div className="d-flex deskSocialCont">
                                                <a href={"mailto:"+ docDe.data.email} className="btn--inverted--solid btn--regular">{docDe.data.email}</a>
                                            </div>

                                        ) : <div className="d-flex deskSocialCont"></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <div className="d-flex deskSocialCont">
                                            <a href={"mailto:"+ doc.data.email} className="btn--inverted--solid btn--regular">{doc.data.email}</a>
                                        </div>
                                    ) : <div className="d-flex deskSocialCont"></div>
                                }
                            </React.Fragment>
                        }


                    </div>
                    <div className="d-block d-md-none">
                        <div className="d-flex">
                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <a href={"tel:"+ docDe.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>

                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <a href={"tel:"+ doc.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            }
                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <a href={"mailto:"+ docDe.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>

                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <a href={"mailto:"+ doc.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
};

export default FormTabCareers;