import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";


const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleHero(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;

    let imgUrl = typeof data.case_image_desktop.url != 'undefined' ? data.case_image_desktop.url : null;
    let dims = {
        width: data.case_image_desktop.dimensions.width,
        height: data.case_image_desktop.dimensions.height
    }
    console.log('module hero', data);

    let overlay = false;
    if(typeof data.has_overlay != 'undefined') {
        if(data.has_overlay) {
            overlay = true;
        }
    }

    let [projectDetailsVisible, setProjectDetailsVisiblity] = React.useState(null);

    function toggleProjectDetails() {
        setProjectDetailsVisiblity(!projectDetailsVisible);
        console.log('vis', projectDetailsVisible);
    }

    return (
        <section>

            <CustomView condition={isMobile === true || isTablet === true}>
                <div className="topVideoContainer d-block moduleHero">
                    <div style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url('+imgUrl+')', height: `${dims.height}px`, width: '100%' }}>
                        {
                            overlay ?
                                (
                                    <div className="overlay"></div>
                                ) : ('')
                        }
                        <div className="customTop" style={{ position: 'relative', top: '341px' }}>
                            <div className="container-fluid container">
                                <div className="row">
                                    <div className="col-11 col-lg-10 offset-lg-1 ">
                                        {
                                            typeof data.title[0].text != 'undefined' && data.title[0].text != null ?
                                                (
                                                    <h2 className={`f-64`}>{data.title[0].text}</h2>
                                                ) : ('')
                                        }

                                        {
                                            data.subtitle != null ?
                                                (
                                                    <p className={``}>{data.subtitle}</p>
                                                ) : ('')
                                        }

                                        {
                                            data.description != null ?
                                                (
                                                    <div className="col-12 col-lg-5">
                                                        <p className={``}>{data.description}</p>
                                                    </div>
                                                ) : ('')
                                        }

                                        {
                                            typeof data.body[0] != 'undefined'  ?
                                                (
                                                    typeof data.body[0].primary != 'undefined' ?
                                                        (
                                                            typeof data.body[0].primary.title_module_8 != 'undefined' ?
                                                                (
                                                                    data.body[0].primary.title_module_8.length > 0 ?
                                                                        (
                                                                            typeof data.body[0].primary.title_module_8 == 'string' ?
                                                                                (
                                                                                    <h5 className={`list-heading f-24`}>{data.body[0].primary.title_module_8}</h5>
                                                                                ) : (
                                                                                    data.body[0].primary.title_module_8.map((text,index) => {
                                                                                        return (
                                                                                            <h6 className={`list-heading f-24`} key={index}>{text.text}</h6>
                                                                                        )
                                                                                    })
                                                                                )
                                                                        ) : ('')
                                                                ) : ('')
                                                        ) : ('')
                                                ) : ('')
                                        }

                                        {

                                            typeof data.body[0] != 'undefined'  ?
                                                (
                                                    typeof data.body[0].items != 'undefined' ?
                                                        (
                                                            data.body[0].items.length > 0 ?
                                                                (
                                                                    <ul className={`heading-items hero-list`} style={{  }}>
                                                                        {data.body[0].items.map((item,index) => {
                                                                            return (
                                                                                <li key={`heading-items-` + index} className={`f-18`} key={index}>{item.list_item}</li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                ) : ('')
                                                        ) : ('')
                                                ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            data.body.map((item, index) => {
                                if(item.primary.title1 == 'Projects') {
                                    return (
                                        <div key={`slider-menu-` + index} className="slider-menu">
                                            <div className={`project-details` + (projectDetailsVisible ? ' d-inline' : ' d-none')}>
                                                <ul>
                                                    {item.items.map((it, index2) => {
                                                        return (
                                                            <li key={`project-details-` + index + `-` + index2}><a href={it.number_value}>{it.number_description}</a></li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                            <div className="projects-btn-container">
                                                <a onClick={ () => { toggleProjectDetails() }} className="btn--inverted--solid btn--regular btn-rotated">Projects</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </CustomView>

            <CustomView condition={isMobile === false && isTablet === false}>
                <div className="topVideoContainer d-block moduleHero">
                    <div style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url('+imgUrl+')', height: dims.height + 'px', width: '100%' }}>
                        <div className="customTop" style={{ position: 'relative', top: '341px' }}>
                            <div className="container-fluid container">
                                <div className="row">
                                    <div className="col-11 col-lg-11 offset-lg-1 ">
                                        {
                                            typeof data.title[0].text != 'undefined' && data.title[0].text != null ?
                                                (
                                                    <h2 className={`f-64`}>{data.title[0].text}</h2>
                                                ) : ('')
                                        }

                                        {
                                            data.subtitle != null ?
                                                (
                                                    <p className={``}>{data.subtitle}</p>
                                                ) : ('')
                                        }

                                        {
                                            data.description != null ?
                                                (
                                                    <div className="col-12 col-lg-5">
                                                        <p className={``}>{data.description}</p>
                                                    </div>
                                                ) : ('')
                                        }

                                        {
                                            typeof data.body[0] != 'undefined'  ?
                                                (
                                                    typeof data.body[0].primary != 'undefined' ?
                                                        (
                                                            typeof data.body[0].primary.title_module_8 != 'undefined' ?
                                                                typeof data.body[0].primary.title_module_8 == 'string' ?
                                                                    (
                                                                        <h4 className={`f-24`} style={{  }}>{data.body[0].primary.title_module_8}</h4>
                                                                    ) : (
                                                                        data.body[0].primary.title_module_8.length > 0 ?
                                                                            data.body[0].primary.title_module_8.map((text, index) => {
                                                                                return (
                                                                                    <h4 className={`f-24`} key={index} style={{  }}>{text.text}</h4>
                                                                                )
                                                                            }) : ('')
                                                                    ) : ('')
                                                        ) : ('')
                                                ) : ('')
                                        }

                                        {

                                            typeof data.body[0] != 'undefined'  ?
                                                (
                                                    typeof data.body[0].items != 'undefined' ?
                                                        (
                                                            data.body[0].items.length > 0 ?
                                                                (
                                                                    <ul className={`heading-items hero-list`} style={{  }}>
                                                                        {data.body[0].items.map((item,index) => {
                                                                                return (
                                                                                    <li key={`heading-items-` + index} className={`f-18`} key={index}>{item.list_item}</li>
                                                                                )
                                                                        })}
                                                                    </ul>
                                                                ) : ('')
                                                        ) : ('')
                                                ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            data.body.map((item, index) => {
                                if(item.primary.title1 == 'Projects') {
                                    return (
                                        <div key={`slider-menu-` + index} className="slider-menu">
                                            <div className={`project-details slide-in-blurred-right` + (projectDetailsVisible ? ' d-inline' : ' d-none')}>
                                                <div className="vertical-center-container">
                                                    <ul>
                                                        {item.items.map((it, index2) => {
                                                            return (
                                                                <li key={`project-details-` + index + `-` + index2}><a href={it.number_value}>{it.number_description}</a></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="projects-btn-container">
                                                <a onClick={ () => { toggleProjectDetails() }} className={`btn--inverted--solid btn--regular btn-rotated ${ projectDetailsVisible ? 'btn--inverted--solid--active' : ''}`}>Projects</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </CustomView>
        </section>
    );
}

export default ModuleHero;