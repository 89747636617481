import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType10(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;

    let imgUrl = typeof data.primary.full_image.url != 'undefined' ? data.primary.full_image.url : null;
    let dims = {
        width: data.primary.full_image.dimensions.width,
        height: data.primary.full_image.dimensions.height
    }

    console.log('module type 10', data);
    let overlay = false;
    if(typeof data.primary.has_overlay != 'undefined') {
        if(data.primary.has_overlay) {
            overlay = true;
        }
    }
    return (
        <div className="caseStudyPageContainer module-10 pt-0">
            <div className="topVideoContainer d-block" id={`section-${key}`}>
                {
                    overlay ?
                        (
                            <div className="overlay"></div>
                        ) : ('')
                }

                <div style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url('+imgUrl+')', height: `${dims.height}px`, width: '100%' }}>
                    <div className="container-fluid container left-0" style={{ position: 'relative', top: '65%' }}>
                        <div className="row">
                            <div className="col-12 col-lg-5 offset-lg-1">
                                {
                                    data.primary.title1 != null ?
                                        (
                                            <h4 className={`f-28 left-custom`} >{data.primary.title1}</h4>
                                        ) : ('')
                                }

                                {
                                    data.items.length > 0 ?
                                        (
                                            <ul className={`heading-items left-custom`} >
                                                {data.items.map((item, index) => <li className={`f-18`} key={index}>{item.list_item}</li>)}
                                            </ul>
                                        ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType10;