import React, { useEffect,useState } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import './components/style/responsive/style.scss';
import ScrollToTop from "./components/ScrollToTop";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import Footer from "./components/Footer";
import { RichText } from 'prismic-reactjs';
import { Client } from "./utilities/prismicHelpers";
import NotFound from "./NotFound";
import LandingPageContainerVideos from "./components/pages/landingPageVideos";


const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

const PageVideos = ({ match }) => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const uid = match.params.uid;
    const { t , i18n } = useTranslation();
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await Client.getByUID('landing_page', uid ,{lang:languageKey});

                if (doc) {
                    setPrismicDoc(doc);
                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);

    if (prismicDoc) {
        const title =
            prismicDoc.data.meta_title.length !== 0 ?
                RichText.asText(prismicDoc.data.meta_title) :
                'Untitled';
        const metatags =
            prismicDoc.data.meta_tags.length !== 0 ?
                RichText.asText(prismicDoc.data.meta_tags) :
                'Untitled';
        const metadescription =
            prismicDoc.data.meta_description.length !== 0 ?
                RichText.asText(prismicDoc.data.meta_description) :
                'Untitled';

        return (

            <div className="App">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={metadescription} />
                    <meta name="keywords" content={metatags} />

                </Helmet>
                <ViewportProvider>
                    <HeaderComponent />
                    <div id="postHeader"></div>
                    <LandingPageContainerVideos data={prismicDoc.data}/>
                </ViewportProvider>

                <Footer/>


                <ScrollToTop/>
            </div>
        )
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}


export default PageVideos;

