import logo from './logo.svg';
import logoWhite from './logoLight.svg';
import './style/Header.scss';
import React, { useState, useEffect, useRef } from 'react';
import { debounce } from '../utilities/helpers';
import { useTranslation } from 'react-i18next';
import { Link, animateScroll as scroll } from 'react-scroll';
import BurgerMenu from './navBurger';
import { Link as Link2, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import { scroller } from 'react-scroll';
import { NavHashLink } from 'react-router-hash-link';

function HeaderDesktopLight() {
  const history = useHistory();

  const headerRef = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }
  function refreshPage(e) {
    window.location.reload(e);
  }
  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 20) ||
        currentScrollPos < 79
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const headerStyles = {
    position: 'fixed',
    height: '55px',
    width: '100%',
    textAlign: 'center',
    transition: 'top 0.2s',
  };

  let languageButton;
  let languageKey;
  let contactLink;
  let newsLink;
  let jobsLink;

 const handleClick = (event, lng) => {
   event.preventDefault();
const currentPath = window.location.pathname;

let newPath = currentPath;

// Toggle '/en/' with '/de/' and vice versa
if (currentPath.includes('/en/')) {
  newPath = currentPath.replace('/en/', '/de/');
} else if (currentPath.includes('/de/')) {
  newPath = currentPath.replace('/de/', '/en/');
}

 window.location.href = newPath; // Redirect to the new URL
};

 
  if (i18n.language == 'en') {
   
    languageKey = '/en/homepage';
    languageButton = (
      <ul className="nav-lang">
        <li>
          < a component = {
            Link2
          }

        href='/'

        onClick = {
          (e) => handleClick(e,'de')
        }
           >
            De
          </a>
        </li>
        <li>
          < a component = {
            Link2
          }
          href = '/'
          onClick = {
            (e) => handleClick(e,'en')
          }
          className = "active" >
            En
          </a>
        </li>
      </ul>
    );
    contactLink = '/en/contact';
    newsLink = '/en/news';
    jobsLink = '/en/jobs';
  } else {
    languageButton = (
      <ul className="nav-lang">
        <li>
          < a component = {
            Link2
          }
           href = '/'
           onClick = {
             (e) => handleClick(e,'de')
           }
          
          className = "active" >
            De
          </a>
        </li>
        <li>
          < a component = {
            Link2
          }
            href = '/'
           onClick = {
             (e) => handleClick(e,'en')
           }
           >
            En
          </a>
        </li>
      </ul>
    );
    languageKey = '/de/homepage';
    contactLink = '/de/contact';
    newsLink = '/de/news';
    jobsLink = '/de/jobs';
  }

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (history.location.pathname !== '/') {
      await history.push(`homepage/#${target}`);
    }
    console.log(target);
    setTimeout(() => {
      //scrollTarget(target);
    }, 500);
  };

  // const location = useLocation();
  // // 👇️ with React router
  // console.log('hash', location.hash);
  // console.log('pathname', location.pathname);
  // console.log('search', location.search);
  //
  // useEffect(() => {
  //     const timer = setTimeout(() => scrollTarget('#jump_to_this_location'), 3000);
  //     return () => clearTimeout(timer);
  // }, []);
  //
  // useEffect(() => {
  //
  //     if (location.hash == '#jump_to_this_location') {
  //         alert('test');
  //         scrollTarget('#jump_to_this_location');
  //         window.location.hash = "jump_to_this_location";
  //     }
  // }, []);

  return (
    <header
      id="headerSection"
      className="App-header d-flex align-items-center inverted"
      style={{ ...headerStyles, top: visible ? '26px' : '-80px' }}
      ref={headerRef}
    >
      <div className="container d-none d-lg-block">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-1">
            <Link2 to={languageKey}>
              <img src={logo} className="App-logo" alt="logo" />
              {/*<img src={logoWhite} className="App-logo-white" alt="logo" />*/}
            </Link2>
          </div>
          <div className="col-12 col-md-9 offset-md-1">
            <div className="d-flex justify-content-between">
              <ul className="nav-top menu-left">
                <li>
                  <NavHashLink to={languageKey + '#sectionMachen'}>
                    {t('header-machen')}
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to={languageKey + '#sectionMission'}>
                    {t('header-mission')}
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink to={languageKey + '#sectionTeam'}>
                    {t('header-sind')}
                  </NavHashLink>
                </li>
                {/* <li>
                  <a
                    href={'/' + i18n.language + '/projects'}
                    className="jobs-link-menu"
                  >
                    {t('header-projects')}
                  </a>
                </li> */}
              </ul>
              <ul className="nav-top menu-right">
                {/*<li><a href={"/" + i18n.language + "/jobs"} className="jobs-link-menu">Jobs</a></li>*/}
                <li className="hasSubmenu">
                  <a
                      href = {
                        '/' + i18n.language + '/responsibility/sustainability'
                      }                  
                   className="kareers-link-menu"
                  >
                    {t('header-responsibility')}
                  </a>
                  <ul className="submenu">
                    <li>
                     <a
                          href = {
                            '/' + i18n.language + '/responsibility/sustainability'
                          }
                        className="kareers-link-menu"
                      >
                        {
                          t('header-responsibility_su')
                        }
                      </a>
                    </li>
                    <li>
                      <a
                        href = {
                          '/' + i18n.language + '/responsibility/compliance'
                        }
                        className="jobs-link-menu"
                      >
                          {
                            t('header-responsibility_com')
                          }
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="hasSubmenu">
                  <a
                    href={'/' + i18n.language + '/page/arbeiten'}
                    className="jobs-link-menu"
                  >
                    {t('header-careers')}
                  </a>
                  <ul className="submenu">
                    <li>
                      <a
                        href={'/' + i18n.language + '/page/arbeiten'}
                        className="kareers-link-menu"
                      >
                        {t('header-arbeiten')}
                      </a>
                    </li>
                    <li>
                      <a
                        href={'/' + i18n.language + '/jobs'}
                        className="jobs-link-menu"
                      >
                        Jobs
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href={'/' + i18n.language + '/news'}
                    className="news-link-menu"
                  >
                    News
                  </a>
                </li>
                <li>
                  <a
                    href={'/' + i18n.language + '/contact'}
                    className="contact-link-menu"
                  >
                    {t('header-kontakt')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-1">{languageButton}</div>
        </div>
      </div>
      <div className="container-fluid d-block d-lg-none">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Link2 to={languageKey}>
              <img src={logo} className="App-logo" alt="logo" />
            </Link2>
            {/*<Link2 to={languageKey}><img src={logoWhite} className="App-logo-white" alt="logo" /></Link2>*/}
          </div>

          <BurgerMenu />
        </div>
      </div>
    </header>
  );
}

export default HeaderDesktopLight;
