import React from "react";
import {Client} from "../../../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";

function SubTitleDe() {
    const [doc, setDocData] = React.useState(null)
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'homepage_section_1'),{ lang : 'de-DE' }
            )
            if (response) {
                setDocData(response.results[0])
            }
        }
        fetchData()
    }, [])
    return (
        <React.Fragment>
            {
                doc ? (
                    <div>
                        {doc.data.subtitle}
                    </div>
                ) : <div></div>
            }
        </React.Fragment>
    );
}

export default SubTitleDe;
