import React from 'react';
import '../../components/style/SectionForm.scss';
import {useTranslation} from "react-i18next";



const FormSuccess = ({showBtn}) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <div className='container thankYouContainer'>
            <div className="row">
                <div className="col12">
                    <h2>{t('formThankYou')}</h2>
                    {
                    showBtn===true || showBtn===''?
                    <a href={"/" + i18n.language + "/jobs"} className="btn--regular btn--inverted--solid">See Jobs Page</a>
                    :''
                    }
                    </div>
            </div>
        </div>
    );
};

export default FormSuccess;