import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';
import {gsap} from "gsap";
import {useTranslation} from "react-i18next";


/**
 * Post list item component
 */
const NewsItems = ({ news },lng) => {
    const { i18n } = useTranslation();
    const url = window.location.pathname.split('/').pop();
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);
    let lang = i18n.language;
  const title =
     RichText.asText(news.data.title) ?
    RichText.asText(news.data.title) :
    'Untitled';

    const loc=news.data.location;
    const company = news.data.company;

useEffect(() => {


    }, []);

  return (
     <div className="col-12 col-md-6 col-lg-4">
        <div className="newsItemContainer">
            <div className="newsImageContainer">
            { news.data.isexternal ? <a target="_blank" href={news.data.externallink.url}><img src={news.data.meta_open_graph_image.url} alt={news.data.meta_open_graph_image.alt}/></a> :
                    <Link to={linkResolver(news,lang)}><img src={news.data.meta_open_graph_image.url} alt={news.data.meta_open_graph_image.alt}/></Link>}
            </div>
            <div className="newsCategory">{news.data.article_type.slug}</div>
            <div className="newsTitle">
                { news.data.isexternal ? <a target="_blank" href={news.data.externallink.url}><p className="" >{title}</p></a> :
                    <Link to={linkResolver(news,lang)}><p className="" >{title}</p></Link>}
            </div>
        </div>
    </div>
   
  );
};

export default NewsItems;
