import React, { useEffect,useState } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import './components/style/responsive/style.scss';
import ScrollToTop from "./components/ScrollToTop";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import Footer from "./components/Footer";
import imageUrl3 from "./components/images/shapes/new/shape-13.jpg";
import { RichText } from 'prismic-reactjs';
import { Client } from "./utilities/prismicHelpers";
import NotFound from "./NotFound";
import NewsItemPageContainer from "./components/pages/newsArticle";
import ImpressumDe from "./components/pages/de/impressum";
import ImpressumEn from "./components/pages/en/impressum";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

const Impressum = ({ match }) => {

    const [notFound, toggleNotFound] = useState(false);


    const { i18n, t } = useTranslation();
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }
    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }

    const href = window.location.href;

    useEffect(() => {
        console.log(`Page href is: ${href}`)
    }, [href]);




        return (

            <div className="App">
                <Helmet>
                    <title>{t('homepage-metaTitle')}</title>
                    <meta name="description" content="{t('homepage-metaDescription')}" />
                    <link rel="canonical" href={href} />
                    {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
                    {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
                    {/*<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet"/>*/}
                </Helmet>
                <ViewportProvider>
                    <HeaderComponent />
                    <div id="postHeader"></div>
                    <div className="staticPageContainer SimpleContent" style={{ backgroundImage: `url(${imageUrl3})`}}>
                        <div>
                            <div className="container" style={{zIndex: '3', position: 'relative'}}>

                                {
                                    isGerman ? (

                                        <React.Fragment>
                                           <ImpressumDe/>
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        <ImpressumEn/>
                                    </React.Fragment>
                                }

                            </div>
                        </div>

                    </div>



                </ViewportProvider>

                <Footer/>


                <ScrollToTop/>
            </div>
        );
}


export default Impressum;

