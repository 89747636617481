import './style/SectionFinalHeadquarter.scss';
import { useInView } from "react-intersection-observer";
import React, {useEffect, useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import videoUrlDesktop from "./videos/contactpage_desktop_101022.mp4";
import videoUrlMobile from "./videos/contactpage_mobile_101022.mp4";
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import {Client} from "../utilities/prismicHelpers";
import {Button} from "./blocks/button";
import circle1 from "./images/mobile/shapes/img_circle_cold.jpg";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormTab1 from "./form/FormTab1";
import FormSuccess from "./form/FormSuccess";
import FormTabCareers from "./form/FormTabCareers";
import iconPhone from "./images/ic_phone_white.svg";
import iconMail from "./images/ic_mail_white.svg";


function SectionFinalHeadquarter() {

    const { t } = useTranslation();
    const {ref, inView} = useInView({
        threshold: 0.45

    });

    const [isSubmitted, setIsSubmitted] = useState(false);



    function submitForm() {
        setIsSubmitted(true);
    }

    useEffect(()=> {
        window.emos3.send({
            content: 'contact page'
        });
    }, []);


    const [doc, setDocData] = React.useState(null)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'company_info')
            )
            if (response) {
                setDocData(response.results[0])
            }

        }
        fetchData()
    }, [])


    return (
        <section className="sectionFinalHeadquarter" ref={ref} style={{height: 'auto'}}>


            <CustomView condition={isMobile === true || isTablet === true}>
                <div className="container item-shapeVideo">
                    <video src={videoUrlMobile} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                </div>
            </CustomView>
            <CustomView condition={isMobile === false && isTablet === false}>
                <div className="container item-shapeVideo">
                    <video src={videoUrlDesktop} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                </div>
            </CustomView>




            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 offset-lg-1">
                        <div className="titleHeadquarter">
                            <React.Fragment>
                                Get in touch!
                            </React.Fragment>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1">
                        <h4>{t('slideHeadquerter-description')}</h4>
                        <div className="addressHeadquarter opacity64"><React.Fragment>
                            Gorch-Fock-Wall 1a<br/>

                            20354 Hamburg
                        </React.Fragment></div>
                        <div className="phoneHeadquarter opacity64 d-none d-md-block"><a href="tel:+494052470590">+49 40 524 705 90</a><br/>
                            <a href={'mailto:contact@pia.me'}>contact@pia.me</a>
                        </div>

                        <div className="d-block d-md-none">
                            <div className="d-flex invert"><a href="tel:+494052470590" className="iconMobileUser"><img src={iconPhone} alt="phone"/></a><a
                                href="mailto:contact@pia.me" className="iconMobileUser"><img src={iconMail} alt="email"/></a></div>
                        </div>



                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-11 offset-lg-1">
                        <Tabs>
                            <TabList>
                                {/* <Tab>New business</Tab> */}
                                {/* <Tab>Press contact</Tab> */}
                                {/* <Tab>Careers</Tab>
                                <Tab>General inquires </Tab> */}
                            </TabList>

                            <TabPanel>
                                <div className="col-12 col-lg-5 mb-5">

                                    {!isSubmitted ? (
                                        <FormTab1 submitForm={submitForm} />
                                    ) : (
                                        <FormSuccess />
                                    )}


                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="col-12 col-lg-5 mb-5">

                                    {!isSubmitted ? (
                                        <FormTab1 submitForm={submitForm} />
                                    ) : (
                                        <FormSuccess />
                                    )}


                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="col-12 col-lg-5 mb-5">

                                    {!isSubmitted ? (
                                        <FormTabCareers submitForm={submitForm} />
                                    ) : (
                                        <FormSuccess />
                                    )}


                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="col-12 col-lg-5 mb-5">

                                    {!isSubmitted ? (
                                        <FormTab1 submitForm={submitForm} />
                                    ) : (
                                        <FormSuccess />
                                    )}


                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default SectionFinalHeadquarter;
