import React from 'react';
import '../style/TextTwoCol.scss';
import { RichText } from 'prismic-reactjs';

/**
 * @typedef {Object} TextTwoColProps
 * @property {Object} data - An object containing the data to be displayed.
 * @property {string} [data.pretitle] - optional
 * @property {string} [data.title] - optional
 * @property {string} [data.subtitle] - optional
 * @property {string} [data.text_left] - optional
 * @property {string} [data.text_right] - optional
 */

/**
 * A component that displays text in a two-column layout.
 * @param {TextTwoColProps} props - The props of the component.
 */
const TextTwoCol = ({ data }) => {
  return (
    <section className="two-text-col  padding-topFull-bottomHalf pb-8">
      <div className="container">
        <div className="row margin-bottomBigger p-120">
          <div className="col-12 col-lg-10 offset-lg-1">
            {/* <strong className="pretitle">{data.primary.pretitle}</strong> */}
            <h1 className="margin-bottomDefault first-head">{data.primary.pretitle}</h1>
            <h3 className="margin-bottomDefault">{data.primary.subtitle}</h3>
          </div>
          <div className="col-12 col-lg-5 offset-lg-1">
            <div className="opacity64 m-0">
              {RichText.render(data.primary.text_column_1)
                ? RichText.render(data.primary.text_column_1)
                : ''}
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="opacity64 m-0">
              {RichText.render(data.primary.text_column_2)
                ? RichText.render(data.primary.text_column_2)
                : ''}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextTwoCol;
