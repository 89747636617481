import React, { useEffect,useState } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktopLight from "./components/HeaderDesktopLight";
import './i18n/config';
import './components/style/responsive/style.scss';
import ScrollToTop from "./components/ScrollToTop";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import FooterDark from "./components/FooterDark";
import HeaderDesktop from "./components/HeaderDesktop";
import Test from "./components/pages/Test";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

function Page(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const caseStudy = props.caseStudy;

    return <Test width={width} case={caseStudy} />;
}

const CaseStudyLandingPage = ({  }) => {

    const { t } = useTranslation();

    const href = window.location.href;
    let uri = new URL(href).pathname.split('/');
    const [,,,caseStudy] = uri;

    let color = 'inverted';
    if(caseStudy == 'hyperice' || caseStudy == 'hyperice-en') {
        color = '';
    }

    if(caseStudy == 'bosch' || caseStudy == 'bosch-en') {
        color = '';
    }

    useEffect(() => {
        console.log(`Page href is: ${href}`)
    }, [href]);

    return (
        <div className={`App Jobspg ${color}`} style={{backgroundColor: "#1a1a1a"}}>
            <Helmet>
                <title>{t('homepage-metaTitle')}</title>
                <meta name="description" content="{t('homepage-metaDescription')}" />
                <link rel="canonical" href={href} />
                {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
                {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
                {/*<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet"/>*/}
            </Helmet>
            <ViewportProvider>
                <HeaderComponent />
                <div id="postHeader"></div>
                <Page caseStudy={caseStudy} />
            </ViewportProvider>
            <FooterDark/>
            <div className="inverted">
                <ScrollToTop/>
            </div>

        </div>
    )
    return null;
}


export default CaseStudyLandingPage;

