import React, { useEffect } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import Footer from "./components/Footer";
import './components/style/responsive/style.scss';
import SectionsDesktopHolder from "./components/SectionsDesktopHolder";
import SectionsMobileHolder from "./components/SectionsMobileHolder";
import ScrollToTop from "./components/ScrollToTop";
import ScrollAnimation from "./components/ScrollAnimation";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import SectionFinalHeadquarter from "./components/SectionFinalHeadquarter";


const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};


function KontaktHeadquarter() {

    const { t } = useTranslation();
    const url = window.location.pathname.split('/').pop()
    const href = window.location.href;
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('contact') > 0) {
            document.querySelector(".contact-link-menu").classList.add("active");
        }

    }, [url]);

    useEffect(() => {

        console.log(`Page href is: ${href}`)


    }, [href]);

    return (

        <div className="App">
            <Helmet>
                <title>{t('homepage-metaTitle')}</title>
                <meta name="description" content="{t('homepage-metaDescription')}" />
                <link rel="canonical" href={href} />
                {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
                {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>*/}
                {/*<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet"/>*/}
            </Helmet>
            <ViewportProvider>
                <HeaderComponent />
                <div id="postHeader"></div>
                <SectionFinalHeadquarter/>
            </ViewportProvider>

            <Footer/>
            <ScrollToTop/>
        </div>

    );
}

export default KontaktHeadquarter;
