import React, { useRef, useState, useEffect, useCallback } from "react";
import './style/SectionCasesNew.scss'
// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.min.css"
import { Client } from "../utilities/prismicHelpers";
import Prismic from '@prismicio/client';
import { Link, RichText, Date } from 'prismic-reactjs';
import { Swiper, SwiperSlide  } from 'swiper/react';
import { AnimatePresence, motion } from "framer-motion";
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import { useInView, InView } from 'react-intersection-observer';
import { linkResolver } from '../prismic-configuration';
import Case1Image from "../components/images/cases/img_case_01.jpg";
import ArrowLeft from "../components/images/arrow_left.png";
import ArrowRight from "../components/images/arrow_right.png";

// import Swiper core and required modules
import SwiperCore, {
     Navigation, Autoplay, Controller,
} from 'swiper/core';
import {useTranslation} from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import {toArray} from "gsap/gsap-core";
import {array} from "prop-types";

import checkMark from "../components/images/check-sign.png"
import shape3img from "./images/shapes/new/shape09-min.jpeg";
import shape5img from "./images/shapes/new/shape07-min.jpeg";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay, Controller]);


function SectionCasesModule() {

    const [ref, inView] = useInView({
        threshold: 0.3
    });


    const [active, setActive] = useState(null);
    const [swiperRef, setSwiperRef] = useState(null);



    const [swiperNav, setSwiperNav] = useState(null);

    const { t, i18n  } = useTranslation();

    let itemPosition;
    const [prismicData, setPrismicData] = useState({casesPosts: null,casesPostsCopy:null});
    const [notFound, toggleNotFound] = useState(false);
    let languageKey;
    let langSegment;

    if (i18n.language=='en') {
        languageKey = 'en-us';
        langSegment = 'en';
    }
    else {
        languageKey = 'de-de';
        langSegment = 'de';
    }
    const fetchPrismicData = async (languageKey) => {
        try {
            const casesPosts = await Client.query(
                Prismic.Predicates.at('document.type', 'case_article_post'),
                { lang : languageKey , orderings : '[document.first_publication_date]' },
            );
            if (casesPosts) {
                // var temp =newsPosts.results.filter(data=>data.lang ===languageKey)
                setPrismicData({ casesPosts:  casesPosts.results,casesPostsCopy:casesPosts.results});
                //check if page finished
            } else {
                console.warn('No Data');
                toggleNotFound(true);
            }
        } catch (error) {
            console.error(error);
            toggleNotFound(true);
        }
    }
    useEffect( () => {

        if (i18n.language=='en') {
            if(prismicData.casesPostsCopy){
                fetchPrismicData('en-us');
            }
        }
        else{
            if(prismicData.casesPostsCopy){
                fetchPrismicData('de-de');
            }
        }

    } , [i18n.language]);
    useEffect(() => {
        fetchPrismicData(languageKey);
    }, [inView]);


    const [focusedProject, setFocusedProject] = useState(undefined);


    useEffect(() => {
        if (inView) {
            document.body.querySelector('.swiper-slide').classList.add('mounted');

        }
        if (!inView) {
            document.body.querySelector('.swiper-slide').classList.remove('mounted');
        }
    }, [inView]);

    function bulletsMove () {
        let element;
        let dataId;
        element = swiperRef.slides[swiperRef.activeIndex];
        dataId = element.getAttribute('data-id');
        if (element.classList.contains(dataId)) {
            //alert(dataId);
            var elList = document.querySelector('.caseNavigation .swiper-wrapper').children;
            for(var i=0; i < elList.length; i++){
                if (elList[i].classList.contains('active')) {
                    elList[i].classList.remove('active');
                }
                // console.log(elList[i]);
                if (elList[i].firstChild.classList.contains(dataId)) {
                    elList[i].classList.add("active");

                }
            }
        }
    }
    function unhideCaseSlide (e, param) {
        e.preventDefault(e);
        setFocusedProject(param);
        var parent = e.target.parentNode.className;

        var cases = Array.from(document.querySelectorAll('.caseSwiper .swiper-slide.main-slide')).filter(s =>
            window.getComputedStyle(s).getPropertyValue('display') != 'none'
        );

        var found = cases.findIndex(function (element) {
            return element.classList.contains(parent);
        });

        var x = document.getElementsByClassName("hidden");
        var i;
        for (i = 0; i < x.length; i++) {
            if (x[i].classList.contains(parent)) {
                x[i].style.display = 'block';
            }

        }
        bulletsMove ();
        swiperRef.update();
        // swiperRef.updateSize();
        // swiperRef.updateSlides();
        // swiperRef.updateProgress();
        // swiperRef.updateSlidesClasses();
        // swiperRef.slideTo(slideNr);
         //swiperRef.slideReset();
         //swiperRef.slideToClosest();
        setTimeout(function () {
            swiperRef.disable();

            swiperRef.enable();
            var parent = e.target.parentElement.className;
            swiperRef.slideTo(getSlideIndexByClass(parent)+1);
            function getSlideIndexByClass(className) {
                var index = 0;
                var cases = Array.from(document.querySelectorAll('.caseSwiper .swiper-slide.main-slide')).filter(s =>
                    window.getComputedStyle(s).getPropertyValue('class') != 'hidden'
                );

                var index = cases.findIndex(function (element) {
                    return element.classList.contains(parent);
                });
                return index;

            }
            // swiperRef.swipeTo( swiperRef.activeIndex - 1, 0, false )

        }, 300);
    }

    // function setFocusedProject (e, param) {
    //     e.preventDefault(e);
    //     setFocusedProject(param);
    //     swiperRef.updateSize();
    //     swiperRef.updateSlides();
    //     swiperRef.updateProgress();
    //     swiperRef.updateSlidesClasses();
    //     swiperRef.slideNext();
    // };

    function gotoNextSlide(e) {
        e.preventDefault(e);
        swiperRef.slideNext();
    }


    // function testF() {
    //
    //     var frames = document.getElementsByTagName("iframe").length;
    //     for (var i = 0; i < frames; i++) {
    //         var iframe = document.getElementsByTagName("iframe")[i].contentWindow;
    //         iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}','*');
    //         alert(frames);
    //     }
    //
    //
    // }





    useEffect(() => {

        const circles = document.querySelectorAll('.swiper-button-next');
        for (const trigger of circles) {
            trigger.addEventListener('click', (e) => {

            });
        }
    }, []);
    useEffect(() => {
        const circles2 = document.querySelectorAll('.swiper-button-prev');
        for (const trigger2 of circles2) {
            trigger2.addEventListener('click', (e) => {

            });
        }
    }, []);

    useEffect(() => {

        if (inView) {
            document.body.querySelector('.App-header').classList.add('hide');
            document.body.querySelector('.scrollToTop').classList.add('d-none');


        }
        if (!inView) {
            document.body.querySelector('.App-header').classList.remove('hide');
            document.body.querySelector('.scrollToTop').classList.remove('d-none');
        }
    }, [inView]);

    var casesPostsAll;
    var color;

    if(prismicData.casesPosts){
        casesPostsAll=prismicData.casesPosts;
        console.log(casesPostsAll);
    }


    function gotoMainSlide(e) {
        e.preventDefault(e);
        var parent = e.target.className;
        swiperRef.slideTo(getSlideIndexByClass(parent));
        function getSlideIndexByClass(className) {
            var index = 0;
            var cases = Array.from(document.querySelectorAll('.caseSwiper .swiper-slide.main-slide')).filter(s =>
                window.getComputedStyle(s).getPropertyValue('display') != 'none'
            );

            var index = cases.findIndex(function (element) {
                return element.classList.contains(parent);
            });
            return index;
            console.log(' clicked on index' + index + Array)
        }
        //alert(parent);
        //swiperRef.slideTo(1);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
console.log('allcases', casesPostsAll);
    return (

        <div className="SectionCasesNew">

                {casesPostsAll && (
                    <React.Fragment>
                        <div class="container">
                            <div className="row">
                                <div className="col-12 col-lg-6 col-xl-5">
                                    <div className="caseNavigation" style={{ width: '100%' }}>
                                        <Swiper
                                            loop={false}
                                            onSwiper={(setSwiperNav) => {
                                                let firstSlideNav;
                                                firstSlideNav = document.querySelector('.caseNavigation .swiper-wrapper .swiper-slide.swiper-slide-active');
                                                firstSlideNav.classList.add('active');

                                            }}

                                            onAfterInit={(setSwiperNav) => {
                                                let firstSlideNav;
                                                firstSlideNav = document.querySelector('.caseNavigation .swiper-wrapper .swiper-slide.swiper-slide-active');
                                                firstSlideNav.classList.add('active');

                                            }}


                                            breakpoints={{
                                                // when window width is >= 320px
                                                320: {
                                                    slidesPerView: 2,
                                                },
                                                768: {
                                                    spaceBetween: 50,
                                                    slidesPerView: "auto"
                                                },
                                            }}
                                            observer={true}
                                            draggable={false}

                                            onSlideChange={() => {

                                            }}
                                            observeParents={true}
                                            observeSlideChildren={true}
                                            onObserverUpdate={() =>{}}
                                            //centeredSlides={true}
                                            spaceBetween={50}
                                            slidesPerView={"auto"}
                                        >
                                            {casesPostsAll.map( (item, index) => {

                                                return index < 5 ?
                                                    (
                                                        <SwiperSlide key={uuidv4()} className={index <= 0 ? 'active' : ''} ><a onClick={gotoMainSlide}  className={`${item.uid}`}>
                                                            {RichText.asText(item.data.title)}
                                                        </a>
                                                        </SwiperSlide>
                                                    ) : (
                                                        index == 5 ?
                                                            (
                                                                <SwiperSlide key={uuidv4()} className={index <= 0 ? 'active' : ''} ><a onClick={gotoMainSlide}  className={`${item.uid}`}>
                                                                    {RichText.asText(item.data.title)}
                                                                </a>
                                                                </SwiperSlide>
                                                            ) : ('')
                                                    )

                                            })}
                                        </Swiper>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <Swiper className="caseSwiper"
                                onSwiper={setSwiperRef}
                                onAfterInit={(setSwiperRef) => {
                                    // let rightBtn;
                                    // rightBtn = document.querySelector('.caseSwiper .swiper-button-next');

                                }}
                                onSlidesLengthChange={(swiper) => {
                                    // let element;
                                    // let dataId;
                                    // element = swiper.slides[swiper.activeIndex];
                                    // dataId = element.getAttribute('data-id');
                                    // console.log(element);
                                    // if (element.classList.contains(dataId)) {
                                    //     //alert(dataId);
                                    //     var elList = document.querySelector('.caseNavigation .swiper-wrapper').children;
                                    //     for(var i=0; i < elList.length; i++){
                                    //         if (elList[i].classList.contains('active')) {
                                    //             elList[i].classList.remove('active');
                                    //         }
                                    //         console.log(elList[i]);
                                    //         if (elList[i].firstChild.classList.contains(dataId)) {
                                    //             elList[i].classList.add("active");
                                    //             console.log('adding active class to id - class' + i + " " + dataId);
                                    //         }
                                    //     }
                                    // }
                                }}
                                onTransitionEnd={(swiper) => {
                                    bulletsMove ();
                                }}
                                loop={false}
                                breakpoints={{
                                    // when window width is >= 320px
                                    320: {
                                        slidesPerView: 1,
                                    },
                                }}
                                observer={true}
                                navigation
                                onSlideChange={(swiper) => {
                                    //testF();
                                    console.log("Slide index changed to: ", swiper.activeIndex);
                                    var playlists = document.querySelectorAll('.swiper-slide');
                                    // for (const playlist of playlists) {
                                    //     var youtubePlayer = playlist.querySelector('iframe');
                                    //     if (youtubePlayer) {
                                    //         youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                    //     }
                                    // }
                                    var mainSlideIndex = swiper.activeIndex;
                                    console.log(mainSlideIndex);
                                    if (swiper.activeIndex >= 0) {
                                        // let rightBtn;
                                        // rightBtn = document.querySelector('.caseSwiper .swiper-button-next');
                                        // rightBtn.classList.remove('d-none');
                                    } else {
                                        // let rightBtn;
                                        // rightBtn = document.querySelector('.caseSwiper .swiper-button-next');
                                        // rightBtn.classList.add('d-none');
                                    };
                                    var elList = document.querySelector('.caseNavigation .swiper-wrapper').children;

                                    swiperRef.update();
                                    // swiperRef.updateSize();
                                    // swiperRef.updateSlides();
                                    // swiperRef.updateProgress();

                                    // for(var i=0; i < elList.length; i++){
                                    //     if (i != mainSlideIndex) {
                                    //         if (elList[i].classList.contains('active')) {
                                    //             elList[i].classList.remove('active');
                                    //         }
                                    //     }
                                    //     elList[mainSlideIndex].classList.add("active");
                                    // }


                                    bulletsMove ();

                                    // let element;
                                    // let dataId;
                                    // element = swiper.slides[swiper.activeIndex];
                                    // dataId = element.getAttribute('data-id');
                                    // console.log(element);
                                    // if (element.classList.contains(dataId)) {
                                    //     //alert(dataId);
                                    //     var elList = document.querySelector('.caseNavigation .swiper-wrapper').children;
                                    //     for(var i=0; i < elList.length; i++){
                                    //         if (elList[i].classList.contains('active')) {
                                    //             elList[i].classList.remove('active');
                                    //         }
                                    //         console.log(elList[i]);
                                    //         if (elList[i].firstChild.classList.contains(dataId)) {
                                    //             elList[i].classList.add("active");
                                    //             console.log('adding active class to id - class' + i + " " + dataId);
                                    //         }
                                    //     }
                                    // }



                                    // for(var i=0; i < elList.length; i++){
                                    //     if (i != mainSlideIndex) {
                                    //         if (elList[i].classList.contains('active')) {
                                    //             elList[i].classList.remove('active');
                                    //         }
                                    //     }
                                    //     elList[mainSlideIndex].classList.add("active");
                                    // }







                                }}
                                observeParents={true}
                                observeSlideChildren={true}
                                onObserverUpdate={() =>{}}
                                spaceBetween={0}
                                slidesPerView={1}
                        >
                            {
                                casesPostsAll.map( (item,index) => {
                                    return index < 5 ?
                                        (
                                            <React.Fragment key={uuidv4()}>
                                                {color = item.data.iswhite ? 'isLight' : 'isDark'}
                                                {
                                                    console.log(item.data.body)

                                                }
                                                {/* //   {item[0].data.body.sliceTy} */}
                                                <SwiperSlide key={uuidv4()} className={`${item.uid} ${color} ${item.data.extra_class} main-slide `} data-id={item.uid} style={{ backgroundImage: `url(${item.data.case_image_desktop.url})` }} >
                                                    <div className="slideContentHolder">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-12 col-lg-6 col-xl-5">
                                                                    <div className="caseMainSlideElementsContainer">
                                                                        {/*{ item.data.hashtags ? <div className="caseHashtags">{item.data.hashtags} </div> : <div></div>}*/}
                                                                        {/*<div className="casePreTitle">{item.data.subtitle}</div>*/}

                                                                        <div className="caseTitle">{RichText.asText(item.data.title)}</div>
                                                                        <div className="caseDescription">{RichText.asText(item.data.home_slider_description)} </div>

                                                                        <div className={`${item.uid}`}>
                                                                            <a href={`/${langSegment}/page/${item.uid}`} className={`btn--regular ${item.data.isbackgroundwhite ? 'btn--solid' : 'btn--inverted--solid'}`} >{t('caseAnsehen')}</a> {/* onClick={(e) => unhideCaseSlide(e, item.uid)} */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>

                                                {(focusedProject === item.uid) ?
                                                    <React.Fragment>
                                                        {item.data.body.map( (inner, i) =>
                                                            <item key={uuidv4()}>
                                                                {inner.slice_type=="module_type_1"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-0 col-lg-4 offset-xl-1 col-xl-3">
                                                                                        <div className="title1Module1">{inner.primary.title_1_module_1}</div>
                                                                                        <div className="description1Module1">{inner.primary.description_1_module_1}</div>
                                                                                    </div>
                                                                                    <div className="col-12 col-lg-7">
                                                                                        <img className="w-100" src={inner.primary.module_1_image.url}/>
                                                                                        <div className="row">
                                                                                            <div className="col-12 offset-lg-1 col-lg-11">
                                                                                                <div className="title2Module1">{inner.primary.title_2_module_1}</div>
                                                                                                <div className="description2Module1">{inner.primary.description_2_module_1}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}
                                                                {inner.slice_type=="module_type_2"?

                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module2">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    {/*<div className="col-12 order-1 order-md-1 offset-lg-5 col-lg-6">*/}
                                                                                    {/*    <div className="descriptionModule2">{inner.primary.description_module_2}</div>*/}
                                                                                    {/*</div>*/}
                                                                                    <div className="col-12 order-1  offset-lg-1 col-lg-3">

                                                                                        <ul className="row">
                                                                                            <li className="col-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-md-12">
                                                                                                        <span className="valueNumber">{inner.items[0].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-md-12">
                                                                                                        <span className="valueText">{inner.items[0].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="col-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-md-12">
                                                                                                        <span className="valueNumber">{inner.items[1].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-md-12">
                                                                                                        <span className="valueText">{inner.items[1].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="col-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-md-12">
                                                                                                        <span className="valueNumber">{inner.items[2].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-md-12">
                                                                                                        <span className="valueText">{inner.items[2].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="col-12 order-2 col-lg-8">
                                                                                        { inner.primary.video_embed_link ? <div className="video_wrapper">
                                                                                            <LiteYoutubeEmbed id={inner.primary.video_id}/>
                                                                                        </div> : <div></div>}
                                                                                        { inner.primary.image_link.url ? <div className="imgModule2">
                                                                                            <img className="w-100" src={inner.primary.image_link.url} alt=""/>
                                                                                        </div> : <div></div>}

                                                                                        <span className="smallText">{inner.primary.small_text}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}
                                                                {inner.slice_type=="module_type_3"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module3">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-1 col-lg-4">
                                                                                        <div className="titleModule3">{inner.primary.title_module_3}</div>
                                                                                        <div className="descriptionModule3"><RichText render={inner.primary.description_module_3_rich}/></div>
                                                                                    </div>
                                                                                    <div className="col-12 col-lg-6">
                                                                                        <ul className="module3List">
                                                                                            {inner.items.map(list =>
                                                                                                <li key={uuidv4()}>{list.list_item}</li>
                                                                                            )}

                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}


                                                                {inner.slice_type=="module_type_4"?

                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`} style={{ backgroundImage: `url(${inner.primary.full_image.url})`, backgroundPosition: 'center center' }}>
                                                                        <div className="slideContentHolder fullImageModule">

                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-1 col-lg-5">
                                                                                        <div className="bigTextModule4">{inner.primary.big_text}</div>
                                                                                        <div className="descriptionModule4"><RichText render={inner.primary.image_credit}  /></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}


                                                                {inner.slice_type=="module_type_5"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide `}>
                                                                        <div className="slideContentHolder module5">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                                                                                        <div className="titleModule5">{inner.primary.big_text}</div>
                                                                                        <div className="descriptionModule5">{inner.primary.caption}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}

                                                                {inner.slice_type=="module_type_6"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module6">
                                                                            <div className="container">

                                                                                { inner.primary.istextonleft ?
                                                                                    <div className="row">
                                                                                        <div className="col-12 col-md-5 col-xl-4 offset-xl-1">
                                                                                            <div className="titleModule6">{inner.primary.title_module_6}</div>
                                                                                            <div className="descriptionModule6">{inner.primary.description_module_6}</div>
                                                                                        </div>
                                                                                        <div className="col-12 col-md-7 col-xl-7">
                                                                                            <div className="imgModule6">
                                                                                                <img src={inner.primary.image_module_6.url} alt=""/>
                                                                                                <p className="imageCaption">{inner.primary.image_caption_module_6}</p>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                    :

                                                                                    <div className="row">
                                                                                        { inner.primary.isimageportrait ?
                                                                                            <div className="col-8 col-md-4">
                                                                                                <div className="imgModule6">
                                                                                                    <img src={inner.primary.image_module_6.url} alt=""/>
                                                                                                    <p className="imageCaption">{inner.primary.image_caption_module_6}</p>
                                                                                                </div>

                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-12 col-md-4">
                                                                                                <div className="imgModule6">
                                                                                                    <img src={inner.primary.image_module_6.url} alt=""/>
                                                                                                    <p className="imageCaption">{inner.primary.image_caption_module_6}</p>
                                                                                                </div>

                                                                                            </div>
                                                                                        }


                                                                                        <div className="col-12 col-md-8 col-xl-6 offset-xl-1">
                                                                                            <div className="titleModule6">{inner.primary.title_module_6}</div>
                                                                                            <div className="descriptionModule6">{inner.primary.description_module_6}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}

                                                                {inner.slice_type=="module_gallery"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentSliderHolder modulegallery">
                                                                            <Swiper

                                                                                loop={false}
                                                                                breakpoints={{
                                                                                    // when window width is >= 320px
                                                                                    320: {
                                                                                        //slidesPerView: 2,
                                                                                    },
                                                                                }}
                                                                                observer={true}
                                                                                draggable={false}
                                                                                navigation
                                                                                onSlideChange={() => {/*...*/}}
                                                                                observeParents={true}
                                                                                observeSlideChildren={true}
                                                                                onObserverUpdate={() =>{}}
                                                                                spaceBetween={30}
                                                                                slidesPerView={'auto'}
                                                                                centeredSlides={true}
                                                                            >
                                                                                {inner.items.map(list =>
                                                                                    <SwiperSlide key={uuidv4()} className={`slidegallery1 `}>

                                                                                        <img src={list.slider_image.url} alt=""/>


                                                                                        <p>{list.image_caption}</p>
                                                                                    </SwiperSlide>
                                                                                )}

                                                                            </Swiper>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}


                                                                {inner.slice_type=="module_type_8"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module8">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-md-10 offset-md-1">
                                                                                        <div className="titleModule8">{inner.primary.title_module_8}</div>
                                                                                    </div>

                                                                                    <div className="col-12 col-md-10 offset-md-1">
                                                                                        <div className="row">
                                                                                            {inner.items.map(list =>
                                                                                                <div key={uuidv4()} className="col-12 col-md-6 col-lg-4 offset-lg-1">
                                                                                                    <div className="list-item" style={{ backgroundImage: `url(${checkMark})` }}>{list.list_item}</div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}

                                                                {inner.slice_type=="module_type_9"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module9">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-0 col-lg-4 offset-xl-1 col-xl-3">
                                                                                        <div className="titleModule3">{inner.primary.title_module_9}</div>
                                                                                        <div className="descriptionModule3"><RichText render={inner.primary.description_module_9_rich}/></div>
                                                                                    </div>
                                                                                    <div className="col-12 col-lg-7">
                                                                                        { inner.primary.video_embed_link ? <div className="video_wrapper">
                                                                                            <LiteYoutubeEmbed id={inner.primary.video_id}/>

                                                                                        </div> : <div></div>}
                                                                                        { inner.primary.image_module_9.url ? <div className="imgModule9">
                                                                                            <img className="w-100" src={inner.primary.image_module_9.url} alt=""/>
                                                                                        </div> : <div></div>}

                                                                                        <div className="row">
                                                                                            <div className="col-12 offset-lg-1 col-lg-11">
                                                                                                <div className="listContainer">
                                                                                                    <p className="title-prelist">{inner.primary.title_prelist}</p>
                                                                                                    <p>{inner.primary.text_prelist}</p>
                                                                                                    <ul className="module9List">
                                                                                                        {inner.items.map(list =>
                                                                                                            <li key={uuidv4()} style={{ backgroundImage: `url(${checkMark})` }}>{list.list_item}</li>
                                                                                                        )}

                                                                                                    </ul>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}

                                                                {inner.slice_type=="module_type_10" ?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`} style={{ backgroundImage: `url(${inner.primary.full_image.url})`, backgroundPosition: 'center center' }}>
                                                                        <div className="slideContentHolder fullImageModule module-10">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-1 col-lg-4">
                                                                                        <div className="bigTextModule10">{inner.primary.title1}</div>
                                                                                        <ul className="module10List">
                                                                                            {inner.items.map(list =>
                                                                                                <li key={uuidv4()}>{list.list_item}</li>
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>


                                                                    : null}


                                                                {inner.slice_type=="module_type_11"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module-10">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <div className="col-12 offset-lg-0 col-lg-5 offset-xl-1 col-xl-4">
                                                                                        <div className="titleModule11">{inner.primary.title1}</div>
                                                                                        <div className="descriptionModule11"><RichText render={inner.primary.description1}  /></div>
                                                                                        { inner.primary.subtitle_list ? <div className="subtitleModule11">
                                                                                            {inner.primary.subtitle_list}
                                                                                        </div> : <div></div>}
                                                                                        <ul className="module11List">

                                                                                            {inner.items.map(list =>
                                                                                                <li key={uuidv4()}>{list.list_item}</li>
                                                                                            )}

                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="col-12 col-lg-6 col-xl-6">
                                                                                        { inner.primary.image.url ? <div className="imgModule11" style={{ backgroundImage: `url(${inner.primary.image.url})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                                                                                        </div> : <div></div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}

                                                                {inner.slice_type=="module_type_12"?

                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module12">
                                                                            <div className="container">
                                                                                <div className="row">

                                                                                    <div className="col-12 order-1  offset-lg-0 col-lg-4 offset-xl-1 col-xl-3">
                                                                                        <div className="moduleTitle12">{inner.primary.title1}</div>
                                                                                        <div className="moduleDescription12"><RichText render={inner.primary.description1}/></div>
                                                                                    </div>
                                                                                    <div className="col-12 order-2 col-lg-7">

                                                                                        { inner.primary.video_embed_link ? <div className="video_wrapper">
                                                                                            <LiteYoutubeEmbed id={inner.primary.video_id} />
                                                                                        </div> : <div></div>}
                                                                                        { inner.primary.image_link.url ? <div className="imgModule12">
                                                                                            <img className="w-100" src={inner.primary.image_link.url} alt=""/>
                                                                                        </div> : <div></div>}


                                                                                        <ul className="offset-sm-1 row mt-4">
                                                                                            <li className="col-12 col-sm-4">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-sm-12">
                                                                                                        <span className="valueNumber">{inner.items[0].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-sm-12">
                                                                                                        <span className="valueText">{inner.items[0].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="col-12 col-sm-4">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-sm-12">
                                                                                                        <span className="valueNumber">{inner.items[1].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-sm-12">
                                                                                                        <span className="valueText">{inner.items[1].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li className="col-12 col-sm-4">
                                                                                                <div className="row">
                                                                                                    <div className="col-5 col-sm-12">
                                                                                                        <span className="valueNumber">{inner.items[2].number_value}</span>
                                                                                                    </div>
                                                                                                    <div className="col-7 col-sm-12">
                                                                                                        <span className="valueText">{inner.items[2].number_description}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}


                                                                {inner.slice_type=="module_type_13"?
                                                                    <SwiperSlide key={uuidv4()} data-id={item.uid} className={`${item.uid} ${color} hidden main-slide`}>
                                                                        <div className="slideContentHolder module-13">
                                                                            { inner.primary.video_link ? <div className="video_wrapper">
                                                                                <LiteYoutubeEmbed id={inner.primary.video_id} mute={false} params="controls=0&start=10&end=30&modestbranding=2&rel=0&enablejsapi=1"/>
                                                                            </div> : <div></div>}
                                                                        </div>
                                                                    </SwiperSlide>
                                                                    :null}


                                                            </item>
                                                        )}
                                                    </React.Fragment>
                                                    : null}



                                            </React.Fragment>
                                        ) : (
                                            index == 5 ?
                                                (
                                                    <React.Fragment key={uuidv4()}>
                                                        {color = item.data.iswhite ? 'isLight' : 'isDark'}
                                                        {
                                                            console.log(item.data.body)

                                                        }
                                                        {/* //   {item[0].data.body.sliceTy} */}
                                                        <SwiperSlide key={uuidv4()} className={`${item.uid} ${color} ${item.data.extra_class} main-slide `} data-id={item.uid} >
                                                            <div className="slideContentHolder">
                                                                <React.Fragment>

                                                                    <div>
                                                                        {windowDimensions.width > 767 ?

                                                                            (<div className="caseStudyPageContainer">
                                                                                <div className="sectionTopJobs">
                                                                                    <div className="animationJobsRight">
                                                                                        <img src={shape3img} alt="" style={{width: '100%', height: '100%'}}/>
                                                                                    </div>
                                                                                    <div className=""
                                                                                         style={{zIndex: '3', position: 'relative', padding: '0px'}}>
                                                                                        <div className="row filtersContainerJobs">

                                                                                            <div style={{height: '800px', padding: '0px', margin: '0px'}}>

                                                                                                <div style={{height: '100%', float: 'left', width: '66.66%'}}>

                                                                                                    <div style={{height: '66.66%', width: '100%'}}>

                                                                                                        <div style={{float: 'left', width: '50%', height: '100%'}}>

                                                                                                            <div className={`card-container`} style={{
                                                                                                                width: 'calc(100% - 8px)',
                                                                                                                height: 'calc(50% - 6px)',
                                                                                                                margin: '4px',
                                                                                                                position: 'relative',
                                                                                                                backgroundSize: 'cover',
                                                                                                                backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[0] != 'undefined' ? casesPostsAll[0].case_image?.url : '') : '') + ')'
                                                                                                            }}>
                                                                                                                <div className="card-title">
                                                                                                                    {/*{(casesPostsAll != null ? (typeof casesPostsAll[0] != 'undefined' ? casesPostsAll[0]?.case_title[0].text : '') : '')}*/}
                                                                                                                </div>
                                                                                                                <div className={`card-info`}>
                                                                                                                    {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[0] != 'undefined' ? casesPostsAll[0]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                                    {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[0] != 'undefined' ? casesPostsAll[0].case_description[0].text : '') : '')}</p>*/}
                                                                                                                    <a href={`${(casesPostsAll != null ? (typeof casesPostsAll[0] != 'undefined' ? casesPostsAll[0].case_link : '') : '')}`}
                                                                                                                       className="btn--inverted--solid btn--regular">View
                                                                                                                        case</a>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className={`card-container`} style={{
                                                                                                                width: 'calc(100% - 8px)',
                                                                                                                height: 'calc(50% - 6px)',
                                                                                                                position: 'relative',
                                                                                                                margin: '4px',
                                                                                                                backgroundSize: 'cover',
                                                                                                                backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[1] != 'undefined' ? casesPostsAll[1].case_image?.url : '') : '') + ')'
                                                                                                            }}>
                                                                                                                <div className="card-title">
                                                                                                                    {/*{(casesPostsAll != null ? (typeof casesPostsAll[1] != 'undefined' ? casesPostsAll[1]?.case_title[0].text : '') : '')}*/}
                                                                                                                </div>
                                                                                                                <div className={`card-info`}>
                                                                                                                    {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[1] != 'undefined' ? casesPostsAll[1]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                                    {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[1] != 'undefined' ? casesPostsAll[1].case_description[0].text : '') : '')}</p>*/}
                                                                                                                    <a href={(casesPostsAll != null ? casesPostsAll[1] : '')}
                                                                                                                       className="btn--inverted--solid btn--regular">View
                                                                                                                        case</a>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        <div className={`card-container`} style={{
                                                                                                            float: 'left',
                                                                                                            width: 'calc(50% - 8px)',
                                                                                                            height: 'calc(100% - 8px)',
                                                                                                            position: 'relative',
                                                                                                            margin: '4px',
                                                                                                            backgroundPosition: 'center center',
                                                                                                            backgroundSize: 'cover',
                                                                                                            backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[2] != 'undefined' ? casesPostsAll[2].case_image?.url : '') : '') + ')'
                                                                                                        }}>
                                                                                                            <div className="card-title">
                                                                                                                {/*{(casesPostsAll != null ? (typeof casesPostsAll[2] != 'undefined' ? casesPostsAll[2]?.case_title[0].text : '') : '')}*/}
                                                                                                            </div>
                                                                                                            <div className={`card-info`}>
                                                                                                                {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[2] != 'undefined' ? casesPostsAll[2]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                                {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[2] != 'undefined' ? casesPostsAll[2].case_description[0].text : '') : '')}</p>*/}
                                                                                                                <a href={(casesPostsAll != null ? '' : '')}
                                                                                                                   className="btn--inverted--solid btn--regular">View
                                                                                                                    case</a>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div className={`card-container`} style={{
                                                                                                        width: 'calc(100% - 4px)',
                                                                                                        height: 'calc(33.33% - 4px)',
                                                                                                        position: 'relative',
                                                                                                        margin: '2px',
                                                                                                        backgroundSize: 'cover',
                                                                                                        backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[3] != 'undefined' ? casesPostsAll[3].case_image?.url : '') : '') + ')'
                                                                                                    }}>
                                                                                                        <div className={`card-info`}>
                                                                                                            {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[3] != 'undefined' ? casesPostsAll[3]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                            {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[3] != 'undefined' ? casesPostsAll[3].case_description[0].text : '') : '')}</p>*/}
                                                                                                            <a href={(casesPostsAll != null ? (typeof casesPostsAll[3] != 'undefined' ? casesPostsAll[3].case_link : '') : '')}
                                                                                                               className="btn--inverted--solid btn--regular">View case</a>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div style={{width: '33.33%', float: 'left', height: '100%'}}>
                                                                                                    <div className={`card-container`} style={{
                                                                                                        height: 'calc(33.33% - 4px)',
                                                                                                        width: 'calc(100% - 4px)',
                                                                                                        position: 'relative',
                                                                                                        margin: '2px',
                                                                                                        backgroundSize: 'cover',
                                                                                                        backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[4] != 'undefined' ? casesPostsAll[4].case_image?.url : '') : '') + ')'
                                                                                                    }}>
                                                                                                        <div className={`card-info`}>
                                                                                                            {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[4] != 'undefined' ? casesPostsAll[4]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                            {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[4] != 'undefined' ? casesPostsAll[4].case_description[0].text : '') : '')}</p>*/}
                                                                                                            <a href={(casesPostsAll != null ? (typeof casesPostsAll[4] != 'undefined' ? casesPostsAll[4].case_link : '') : '')}
                                                                                                               className="btn--inverted--solid btn--regular">View case</a>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className={`card-container`} style={{
                                                                                                        height: 'calc(66.66% - 4px)',
                                                                                                        width: 'calc(100% - 4px)',
                                                                                                        position: 'relative',
                                                                                                        margin: '2px',
                                                                                                        backgroundSize: 'cover',
                                                                                                        backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[5] != 'undefined' ? casesPostsAll[5].case_image?.url : '') : '') + ')'
                                                                                                    }}>
                                                                                                        <div className={`card-info`}>
                                                                                                            {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[5] != 'undefined' ? casesPostsAll[5]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                            {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[5] != 'undefined' ? casesPostsAll[5].case_description[0].text : '') : '')}</p>*/}
                                                                                                            <a href={(casesPostsAll != null ? (typeof casesPostsAll[5] != 'undefined' ? casesPostsAll[5].case_link : '') : '')}
                                                                                                               className="btn--inverted--solid btn--regular">View case</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                            <div style={{
                                                                                                backgroundColor: 'transparent',
                                                                                                height: '600px',
                                                                                                padding: '0px',
                                                                                                margin: '0px'
                                                                                            }}>
                                                                                                <div className={`card-container`} style={{
                                                                                                    margin: '4px',
                                                                                                    float: 'left',
                                                                                                    width: 'calc(33.33% - 8px)',
                                                                                                    height: 'calc(100% - 8px)',
                                                                                                    position: 'relative',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[6] != 'undefined' ? casesPostsAll[6].case_image?.url : '') : '') + ')'
                                                                                                }}>
                                                                                                    <div className={`card-info`}>
                                                                                                        {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[6] != 'undefined' ? casesPostsAll[6]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                        {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[6] != 'undefined' ? casesPostsAll[6].case_description[0].text : '') : '')}</p>*/}
                                                                                                        <a href={(casesPostsAll != null ? (typeof casesPostsAll[6] != 'undefined' ? casesPostsAll[6].case_link : '') : '')}
                                                                                                           className="btn--inverted--solid btn--regular">View case</a>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div style={{float: 'left', width: '66.66%', height: '100%'}}>

                                                                                                    <div style={{
                                                                                                        margin: '4px',
                                                                                                        width: 'calc(100% - 6px)',
                                                                                                        height: 'calc(100% - 8px)',
                                                                                                        backgroundRepeat: 'no-repeat',
                                                                                                        backgroundSize: 'cover',
                                                                                                        backgroundImage: 'url(' + (casesPostsAll != null ? (typeof casesPostsAll[7] != 'undefined' ? casesPostsAll[7].case_image?.url : '') : '') + ')'
                                                                                                    }}>
                                                                                                        <div className="card-container"
                                                                                                             style={{width: '100%', height: '100%', position: 'relative'}}>
                                                                                                            <div className={`card-info`}>
                                                                                                                {/*<h4>{(casesPostsAll != null ? (typeof casesPostsAll[7] != 'undefined' ? casesPostsAll[7]?.case_title[0].text : '') : '')}</h4>*/}
                                                                                                                {/*<p>{(casesPostsAll != null ? (typeof casesPostsAll[7] != 'undefined' ? casesPostsAll[7].case_description[0].text : '') : '')}</p>*/}
                                                                                                                <a href={(casesPostsAll != null ? (typeof casesPostsAll[7] != 'undefined' ? casesPostsAll[7].case_link : '') : '')}
                                                                                                                   className="btn--inverted--solid btn--regular">View case</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="animationCaseStudyLeft">
                                                                                        <img src={shape5img} alt="" style={{width: '100%', height: '100%'}}/>
                                                                                    </div>
                                                                                    <div className="shapesJobsPageKontakttrigger"></div>
                                                                                </div>

                                                                                <div className="shapesJobsPagetrigger"></div>


                                                                            </div>) :

                                                                            (<div className="caseStudyPageContainer">
                                                                                <div className="sectionTopJobs">
                                                                                    <div className="animationJobsRight">
                                                                                        <img src={shape3img} alt="" style={{width: '100%', height: '100%'}}/>
                                                                                    </div>
                                                                                    <div className="container"
                                                                                         style={{zIndex: '3', position: 'relative', padding: '0px !important'}}>
                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/bosch"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[0].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[0]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/xing"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[1].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[1]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/1-porsche"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[2].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[2]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/under-armour"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[3].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[3]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/xing"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[4].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[4]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/xing"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[5].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[5]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/xing"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[6].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[6]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <a href={"/" + i18n.language + "/page/xing"}>
                                                                                                <div className="col-12 case-card" style={{
                                                                                                    margin: '4px',
                                                                                                    height: '243px',
                                                                                                    backgroundSize: 'cover',
                                                                                                    backgroundRepeat: 'no-repeat',
                                                                                                    backgroundImage: 'url(' + (casesPostsAll.length > 0 ? casesPostsAll[7].case_image?.url : '') + ')'
                                                                                                }}>
                                                                                                    {/*<h4>{(casesPostsAll.length > 0 ? casesPostsAll[7]?.case_title[0].text : '')}</h4>*/}
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="animationCaseStudyLeft">
                                                                                        <img src={shape5img} alt="" style={{width: '100%', height: '100%'}}/>
                                                                                    </div>
                                                                                    <div className="shapesJobsPageKontakttrigger"></div>
                                                                                </div>

                                                                                <div className="shapesJobsPagetrigger"></div>


                                                                            </div>)
                                                                        }
                                                                    </div>

                                                                </React.Fragment>
                                                            </div>
                                                        </SwiperSlide>
                                                    </React.Fragment>
                                                ) : ('')
                                        )
                                })
                            }
                        </Swiper>
                    </React.Fragment>
                )}


        </div>
    )

}

export default SectionCasesModule;