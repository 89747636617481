import './style/ScrollToTop.scss';
import React, {useEffect, useState} from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import arrowTop from "../components/images/ic_arrow_up.svg"

function ScrollToTop() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };
    window.addEventListener('scroll', toggleVisible);

    return (
           <Link className="scrollToTop"
                 style={{display: visible ? 'inline' : 'none', backgroundImage: `url(${arrowTop})`}}
                 to="postHeader"
                 spy={true}
                 smooth={true}
                 offset={0}
                 duration={500}
           ></Link>

    );
}

export default ScrollToTop;
