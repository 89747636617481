import * as React from 'react'
import './style.scss'

const STYLES =[
    "btn--primary--solid",
    "btn--secondary--solid",
    "btn--inverted--solid"
]

const SIZES = [
    "btn--regular",
    "btn--big",
    "btn--small",
    "btn-h-40"
]
export const Button = ({children, type, onClick, buttonStyle, buttonSize}) => {

    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
            {children}
        </button>
    )
}