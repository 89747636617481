import React from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import {useEffect, useState} from "react";
import '../../components/style/SectionForm.scss';
import validate from './validateInfo';
import useForm1 from './useForm1';
import FormInput from "./form-input.component";
import arrowImage from "../images/ic_arrow_down_white_small.svg";
import {useTranslation} from "react-i18next";
import {Button} from "../blocks/button";
import axios from "axios";

const FormTab1 = ({ submitForm }) => {
    const { handleChange, handleSubmit, values, errors } = useForm1(
        submitForm,
        validate
    );
    // ---------- tz -------- //
    async function onChange(token) {
        console.log("Recaptcha token:", token);
        console.log("Recaptcha token:", 'changed');
    }
    // ---------- tz -------- //

    const [valuess, setValuess]  = useState('');
    const { t } = useTranslation();

    function inLogin() {
        fetch('https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=773x8xnlx5qx48&redirect_uri=https%3A%2F%2Fstage.pia.me%2F&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social')
            .then(response => response.json())
            .then(data => console.log(data));
    }

    function handleSuccess() {

    }

    function handleFailure()
    {

    }

    const handleLoginClick = () => {
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=773x8xnlx5qx48&redirect_uri=https%3A%2F%2Fstage.pia.me%2Fde%2Fcontact&state=fooobar&scope=r_liteprofile%20r_emailaddress`;
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');

        if (code) {
            getInfo(code)
                .then((resp) => {
                    console.log('ggg', resp.data);
                    setValuess({name: resp.data.firstname, forename: resp.data.lastname, email: resp.data.emailAddress })
                });
        }
    }, []);

    function getInfo(code) {
        return new Promise((res, rej) => {
            axios.post(
                'https://pia.me/server.php',
                `{"code":"${code}"}`
            ).then((resp) => {
                res(resp);
            });
        });
    }

    const fetchUserProfile = async (accessToken) => {
        const { data } = await axios.get(
            'https://api.linkedin.com/v2/me', {
                 crossdomain: true,
                     mode: 'no-cors',
                     withCredentials: true
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                     'Content-Type': 'application/json',
                     'Access-Control-Allow-Credentials': false,
                     "Access-Control-Allow-Origin": 'https://stage.pia.me/de/contact,https://stage.pia.me/en/contact'
                },
            }
        );
        return data;
    };

    return (
        <div className="formContainer mb-5 d-block ded">
            <GoogleReCaptchaProvider reCaptchaKey="6Lf3qY8pAAAAAH36x6tkJQaV2vX6IHrFWmKGnkMJ">

            <form id="formtab1" onSubmit={handleSubmit} method="POST" noValidate>

                <button onClick={handleLoginClick} className={`btn btn--primary--solid btn--regular btn-h-40`}>{t('linkedinBtn')}</button>

                <FormInput
                    name='name'
                    type='text'
                    onChange={handleChange}
                    value={values.name}
                    label={t('formName') + "*"}
                    error={errors.name}
                    required
                />
                <FormInput
                    name='forename'
                    type='text'
                    onChange={handleChange}
                    value={values.forename}
                    label={t('formForename') + "*"}
                    error={errors.forename}
                    required
                />

                <FormInput
                    name='email'
                    type='email'
                    onChange={handleChange}
                    value={values.email}
                    label={t('formEmail') + "*"}
                    error={errors.email}
                    required
                />
                <FormInput
                    name='phone'
                    type='tel'
                    onChange={handleChange}
                    value={values.phone}
                    label={t('formTelefon')}
                    error={errors.phone}

                />
                <FormInput
                    name='firma'
                    type='text'
                    onChange={handleChange}
                    value={values.company}
                    label={t('formFirma')}
                    error={errors.company}
                    required
                />
                <FormInput
                    name='title'
                    type='text'
                    onChange={handleChange}
                    value={values.title}
                    label='Title'
                    error={errors.title}

                />

                <FormInput
                    name='message'
                    type='textarea'
                    onChange={handleChange}
                    value={values.message}
                    label={t('formMessage')}
                    error={errors.message}
                    required
                />

                <div className="mt-5">
                    <GoogleReCaptcha onVerify={onChange} />
                </div>

                <div className="privacyContainer mt-5" htmlFor="privacy">
                    <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                           value="1"/>
                    <span>{t('formCheckboxText')}</span>
                </div>
                
                <div className="my-4">
                    <Button type={'submit'} buttonStyle='btn--solid' buttonSize='btn--regular'>{t('subBtn')}</Button>
                </div>




            </form>
            
            </GoogleReCaptchaProvider>
        </div>
    );
};

export default FormTab1;