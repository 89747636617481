import React from 'react';
import '../../../App.scss';
import '../../../Fonts.scss';
import quote_black from '../../../components/images/quote_black.svg';
import quote_white from '../../../components/images/quote_white.svg';
import '../../../components/style/CaseModules.scss';
import '../../../components/style/responsive/style.scss';
import '../../../i18n/config';

const viewportContext = React.createContext({});

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};
//Case services module
function ModuleType5(props) {
  const { width } = useViewport();
  const breakpoint = 768;
  const data = props.data;
  let quote = quote_black;
  let color = '';

  const dims = {
    width: data.primary.image.dimensions.width,
    height: data.primary.image.dimensions.height,
  };

  if (data.primary.iswhite1) {
    quote = quote_white;
  }

  console.log('module type 5', data);

  return (
    <div
      className={`wideBgContainer imgbg module-5`}
      style={{
        height: `${dims.height}px`,
        backgroundImage:
          'url(' +
          (typeof data.primary.image.url != 'undefined'
            ? data.primary.image.url
            : '') +
          ')',
      }}
    >
      <div
        className="container-fluid container"
        style={{
          position: 'relative',
          top: '40%',
        }}
      >
        <div className="row">
          <div className="col-12  col-lg-5 offset-lg-1">
            <img className="mb-5" src={quote} alt="" />
            {data.primary.big_text != null ? (
              <h3
                style={{ color: data.primary.iswhite1 ? '#fff' : '#000' }}
                className={`mb-80`}
              >
                {data.primary.big_text}
              </h3>
            ) : (
              ''
            )}

            {data.primary.title1.length > 0 ? (
              <h5
                style={{ color: data.primary.iswhite1 ? '#fff' : '#000' }}
                className="quote-title"
              >
                {data.primary.title1[0].text}
              </h5>
            ) : (
              ''
            )}

            {data.primary.caption != null ? (
              <p
                style={{ color: data.primary.iswhite1 ? '#fff' : '#000' }}
                className="quote-caption"
              >
                {data.primary.caption}
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleType5;
