import React, { useState, useEffect, useRef } from "react";

import '../../components/style/caseStudyContainer.scss'
import arrowImage from "../../components/images/ic_arrow_down_white_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import shape3img from "../../components/images/shapes/new/shape09-min.jpeg";
import shape5img from "../../components/images/shapes/new/shape07-min.jpeg";
import shape10img from "../../components/images/shapes/new/shape13-min.jpeg";

import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";
import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";

function CaseStudyPageContainer() {
    gsap.registerPlugin(ScrollTrigger);

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);

    const {t, i18n} = useTranslation();

    let isGerman;
    let langStr;

    if (i18n.language=='en') {
        isGerman = false;
        langStr = 'en-US';
    }
    else {
        langStr = 'de-De';
        isGerman = true;
    }

    const [doc, setDocData] = React.useState([]);
    const [docClone, setDocCloneData] = React.useState([]);
    const [docDe, setDocDataDe] = React.useState(null);


    const [categoriesDe] = React.useState([t('caseIndustries.finance'), t("caseIndustries.energy"), t("caseIndustries.automotive"), t("caseIndustries.lifestyle"), t("caseIndustries.healthcare"), t("caseIndustries.food"), t("caseIndustries.public"), t("caseIndustries.tourism"), t("caseIndustries.retail"), t("caseIndustries.media"), t("caseIndustries.manufacturing")]);
    const [categoriesEn] = React.useState([t('caseIndustries.finance'), t("caseIndustries.energy"), t("caseIndustries.automotive"), t("caseIndustries.lifestyle"), t("caseIndustries.healthcare"), t("caseIndustries.food"), t("caseIndustries.public"), t("caseIndustries.tourism"), t("caseIndustries.retail"), t("caseIndustries.media"), t("caseIndustries.manufacturing")]);

    const [solutionsDe] = React.useState({
        finance: ["Amex", "Allianz", "BIG", "COMDIRECT", "Deutsche Bank Persönlichkeitstest -> Nordpol", "Finance App", "UNIQUA - > Dymatrix"]
    });
    const [solutionsEn] = React.useState({
        finance: ["Amex", "Allianz", "BIG", "COMDIRECT", "Deutsche Bank Persönlichkeitstest -> Nordpol", "Finance App", "UNIQUA - > Dymatrix"]
    });

    let categories;
    let solutions = [];

    if(isGerman) {
        let i, index;
        for(i in solutionsDe) {
            for(index = 0; index<solutionsDe[i].length;index++) {
                solutions.push(solutionsDe[i][index]);
            }
        }
        categories = categoriesDe;
    } else {
        let i, index;
        for(i in solutionsEn) {
            for(index = 0; index<solutionsEn[i].length;index++) {
                solutions.push(solutionsEn[i][index]);
            }
        }
        categories = categoriesEn;
    }

    console.log('solutions', solutions);

    const handleChange = e => {
    }

    const [categorySelected, solutionSelected, setSelected] = React.useState("All");
    //await Client.getByUID('case_article_post','under-armour-en', {}) fetch by id, todo: delete this after
    React.useEffect(() => {
        const fetchData = async () => {

            const response = await Client.getByUID('case_list', 'cases',
                { lang : langStr },
            );
            if (response) {
                setDocData(response);
                setDocCloneData(response);
            }
        }
        fetchData()
    }, [])

    function filter(event) {
        console.log('docClone', docClone);
        let val = event.target.value;
        let i;
        let data = { data: { cases: [] } };
        for(i in docClone.data.cases) {
            console.log(docClone.data.cases[i]);
            if(doc.data.cases[i].branch == val) {
                data.data.cases.push(docClone.data.cases[i]);
            }
        }

        doc.data = data.data;

        setDocData(doc);
        console.log('filter', doc);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    console.log('data', doc);

    return (
        doc ?
            (
                <div>
                    {windowDimensions.width > 767 ?

                        (<div className="caseStudyPageContainer">
                            <div className="sectionTopJobs">
                                <div className="animationJobsRight">
                                    <img src={shape3img} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className=""
                                     style={{zIndex: '3', position: 'relative', padding: '0px'}}>
                                    <div className="container">
                                        <div className="row pt-filter">
                                            <div className="col-12 offset-lg-1 col-lg-11 PageDescription">
                                                <div className="">{t('caseStudyPageTitle')}</div>
                                                <div className="mt-2 PageTitle">{t('caseStudyPageDescription')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row filtersContainerJobs">

                                        <div style={{height: '1200px', padding: '0px', margin: '0px'}}>

                                            <div style={{height: '100%', float: 'left', width: '66.66%'}}>

                                                <div style={{height: '66.66%', width: '100%'}}>

                                                    <div style={{float: 'left', width: '50%', height: '100%'}}>

                                                        <div className={`card-container`} style={{
                                                            width: 'calc(100% - 6px)',
                                                            height: 'calc(50% - 6px)',
                                                            margin: '4px',
                                                            position: 'relative',
                                                            backgroundSize: 'cover',
                                                            backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[0] != 'undefined' ? doc.data.cases[0].case_image.url : '') : '') + ')'
                                                        }}>
                                                            <div className="card-title">
                                                                {(typeof doc.data != 'undefined' ? (typeof doc.data.cases[0] != 'undefined' ? doc.data.cases[0].case_title[0].text : '') : '')}
                                                            </div>
                                                            <div className={`card-info`}>
                                                                <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[0] != 'undefined' ? doc.data.cases[0].case_title[0].text : '') : '')}</h4>

                                                                <a href={`${(typeof doc.data != 'undefined' ? (typeof doc.data.cases[0] != 'undefined' ? doc.data.cases[0].case_link.url.replace('{lang}', i18n.language) : '') : '')}`}
                                                                   className="btn--inverted--solid btn--regular btn-h-40">View
                                                                    case</a>
                                                            </div>
                                                        </div>

                                                        <div className={`card-container`} style={{
                                                            width: 'calc(100% - 6px)',
                                                            height: 'calc(50% - 6px)',
                                                            position: 'relative',
                                                            margin: '4px',
                                                            backgroundSize: 'cover',
                                                            backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[1] != 'undefined' ? doc.data.cases[1].case_image.url : '') : '') + ')'
                                                        }}>
                                                            <div className="card-title">
                                                                {(typeof doc.data != 'undefined' ? (typeof doc.data.cases[1] != 'undefined' ? doc.data.cases[1].case_title[0].text : '') : '')}
                                                            </div>
                                                            <div className={`card-info`}>
                                                                <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[1] != 'undefined' ? doc.data.cases[1].case_title[0].text : '') : '')}</h4>

                                                                <a href={(typeof doc.data != 'undefined' ? doc.data.cases[1].case_link.url.replace('{lang}', i18n.language) : '')}
                                                                   className="btn--inverted--solid btn--regular btn-h-40">View
                                                                    case</a>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={`card-container`} style={{
                                                        float: 'left',
                                                        width: 'calc(50% - 8px)',
                                                        height: 'calc(100% - 8px)',
                                                        position: 'relative',
                                                        margin: '4px',
                                                        backgroundPosition: 'center center',
                                                        backgroundSize: 'cover',
                                                        backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[2] != 'undefined' ? doc.data.cases[2].case_image.url : '') : '') + ')'
                                                    }}>
                                                        <div className="card-title">
                                                            {(typeof doc.data != 'undefined' ? (typeof doc.data.cases[2] != 'undefined' ? doc.data.cases[2].case_title[0].text : '') : '')}
                                                        </div>
                                                        <div className={`card-info`}>
                                                            <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[2] != 'undefined' ? doc.data.cases[2].case_title[0].text : '') : '')}</h4>

                                                            <a href={(typeof doc.data != 'undefined' ? doc.data.cases[2].case_link.url.replace('{lang}', i18n.language) : '')}
                                                               className="btn--inverted--solid btn--regular btn-h-40">View
                                                                case</a>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className={`card-container`} style={{
                                                    width: 'calc(100% - 6px)',
                                                    height: 'calc(33.33% - 6px)',
                                                    position: 'relative',
                                                    margin: '2px',
                                                    backgroundPosition: 'center center',
                                                    backgroundSize: 'cover',
                                                    backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[3] != 'undefined' ? doc.data.cases[3].case_image.url : '') : '') + ')'
                                                }}>
                                                    <div className={`card-info`}>
                                                        <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[3] != 'undefined' ? doc.data.cases[3].case_title[0].text : '') : '')}</h4>

                                                        <a href={(typeof doc.data != 'undefined' ? (typeof doc.data.cases[3] != 'undefined' ? doc.data.cases[3].case_link.url.replace('{lang}', i18n.language) : '') : '')}
                                                           className="btn--inverted--solid btn--regular btn-h-40">View case</a>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style={{width: '33.33%', float: 'left', height: '100%'}}>
                                                <div className={`card-container`} style={{
                                                    height: 'calc(33.33% - 4px)',
                                                    width: 'calc(100% - 4px)',
                                                    position: 'relative',
                                                    margin: '2px 2px 6px 2px',
                                                    backgroundSize: 'cover',
                                                    backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[4] != 'undefined' ? doc.data.cases[4].case_image.url : '') : '') + ')'
                                                }}>
                                                    <div className={`card-info`}>
                                                        <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[4] != 'undefined' ? doc.data.cases[4].case_title[0].text : '') : '')}</h4>

                                                        <a href={(typeof doc.data != 'undefined' ? (typeof doc.data.cases[4] != 'undefined' ? doc.data.cases[4].case_link.url.replace('{lang}', i18n.language) : '') : '')}
                                                           className="btn--inverted--solid btn--regular btn-h-40">View case</a>
                                                    </div>
                                                </div>

                                                <div className={`card-container`} style={{
                                                    height: 'calc(66.66% - 8px)',
                                                    width: 'calc(100% - 4px)',
                                                    position: 'relative',
                                                    margin: '2px',
                                                    backgroundSize: 'cover',
                                                    backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[5] != 'undefined' ? doc.data.cases[5].case_image.url : '') : '') + ')'
                                                }}>
                                                    <div className={`card-info`}>
                                                        <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[5] != 'undefined' ? doc.data.cases[5].case_title[0].text : '') : '')}</h4>

                                                        <a href={(typeof doc.data != 'undefined' ? (typeof doc.data.cases[5] != 'undefined' ? doc.data.cases[5].case_link.url.replace('{lang}', i18n.language) : '') : '')}
                                                           className="btn--inverted--solid btn--regular btn-h-40">View case</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div style={{
                                            backgroundColor: 'transparent',
                                            height: '600px',
                                            padding: '0px',
                                            margin: '0px'
                                        }}>
                                            <div className={`card-container`} style={{
                                                margin: '4px',
                                                float: 'left',
                                                width: 'calc(33.33% - 8px)',
                                                height: 'calc(100% - 8px)',
                                                position: 'relative',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[6] != 'undefined' ? doc.data.cases[6].case_image.url : '') : '') + ')'
                                            }}>
                                                <div className={`card-info`}>
                                                    <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[6] != 'undefined' ? doc.data.cases[6].case_title[0].text : '') : '')}</h4>

                                                    <a href={(typeof doc.data != 'undefined' ? (typeof doc.data.cases[6] != 'undefined' ? doc.data.cases[6].case_link.url.replace('{lang}', i18n.language) : '') : '')}
                                                       className="btn--inverted--solid btn--regular btn-h-40">View case</a>
                                                </div>
                                            </div>

                                            <div style={{float: 'left', width: '66.66%', height: '100%'}}>

                                                <div style={{
                                                    margin: '4px',
                                                    width: 'calc(100% - 6px)',
                                                    height: 'calc(100% - 8px)',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                                    backgroundImage: 'url(' + (typeof doc.data != 'undefined' ? (typeof doc.data.cases[7] != 'undefined' ? doc.data.cases[7].case_image.url : '') : '') + ')'
                                                }}>
                                                    <div className="card-container"
                                                         style={{width: '100%', height: '100%', position: 'relative'}}>
                                                        <div className={`card-info`}>
                                                            <h4>{(typeof doc.data != 'undefined' ? (typeof doc.data.cases[7] != 'undefined' ? doc.data.cases[7].case_title[0].text : '') : '')}</h4>

                                                            <a href={(typeof doc.data != 'undefined' ? (typeof doc.data.cases[7] != 'undefined' ? doc.data.cases[7].case_link.url.replace('{lang}', i18n.language) : '') : '')}
                                                               className="btn--inverted--solid btn--regular btn-h-40">View case</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="animationCaseStudyLeft">
                                    <img src={shape5img} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className="shapesJobsPageKontakttrigger"></div>
                            </div>

                            <div className="shapesJobsPagetrigger"></div>


                        </div>) :

                        (<div className="caseStudyPageContainer">
                            <div className="sectionTopJobs">
                                <div className="animationJobsRight">
                                    <img src={shape3img} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className="container"
                                     style={{zIndex: '3', position: 'relative', padding: '0px !important'}}>
                                    <div className="row pt-filter">
                                        <div className="col-12 offset-lg-1 col-lg-11 PageDescription">
                                            <div className="">{t('caseStudyPageTitle')}</div>
                                            <div className="PageTitle">{t('caseStudyPageDescription')}</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/bosch"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[0].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[0].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/xing"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[1].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[1].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/1-porsche"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[2].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[2].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/under-armour"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[3].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[3].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/xing"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[4].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[4].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/xing"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[5].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[5].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/xing"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[6].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[6].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="row">
                                        <a href={"/" + i18n.language + "/page/xing"}>
                                            <div className="col-12 case-card" style={{
                                                margin: '4px',
                                                height: '243px',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: 'url(' + (doc?.data?.cases.length > 0 ? doc.data.cases[7].case_image.url : '') + ')'
                                            }}>
                                                <h4>{(doc?.data?.cases.length > 0 ? doc.data.cases[7].case_title[0].text : '')}</h4>
                                            </div>
                                        </a>
                                    </div>

                                </div>

                                <div className="animationCaseStudyLeft">
                                    <img src={shape5img} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className="shapesJobsPageKontakttrigger"></div>
                            </div>

                            <div className="shapesJobsPagetrigger"></div>


                        </div>)
                    }
                </div>
            )
            : (<div className={`page-loading`}>Loading...</div>)
    );
}

export default CaseStudyPageContainer;
