import React from 'react';
import '../style/TextWithPointsOnRight.scss';
import { RichText } from 'prismic-reactjs';
import tick from "../images/icon-tick.png";

/**
 * @typedef {Object} TextTwoColProps
 * @property {Object} data - An object containing the data to be displayed.
 * @property {string} [data.pretitle] - optional
 * @property {string} [data.title] - optional
 * @property {string} [data.subtitle] - optional
 * @property {string} [data.text_left] - optional
 * @property {string} [data.text_right] - optional
 */

/**
 * A component that displays text in a two-column layout.
 * @param {TextWithPointsOnRight} props - The props of the component.
 */
const TextWithPointsOnRight = ({
    data
  }) => {
    console.log(data)
  return (
    <section className="two-text-col-withpoints  padding-topFull-bottomHalf pb-8">
      <div className="container">
        <div className="row margin-bottomBigger">
          <div className="col-12 col-lg-10 offset-lg-1">
            <h2 className="margin-bottomDefault ">{data.primary.title}</h2>
          </div>
          <div className="col-12 col-lg-5 offset-lg-1">
            < div className = "opacity64 m-0" >
              
            {
              RichText.render(data.primary.left_text) ?
                RichText.render(data.primary.left_text):
                ''
            }
            </div>
          </div>
          < div className = "col-12 col-lg-5 offset-lg-1" >
            <div className=" m-0">
          < ul className = "list-group list-group-flush" >
            {
              data.items.map((item, index) => (
              <li key={index} className="pb-3 d-flex ">
              <img src={tick} alt="Tick" className="me-3" style={{ width: '24px', height: '24px' }} />
                  {
                    RichText.render(item.point_text) ?
                      RichText.render(item.point_text):
                      ''
                  }
              </li>
            ))}
          </ul>


            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextWithPointsOnRight;
