import React, {useState, useEffect, Fragment} from "react";
import '../../components/style/newsArticle.scss'
import arrowImage from "../../components/images/ic_arrow_down_white_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import shape3img from "../../components/images/shapes/shape_03.jpg";
import iconLinkedIn from "../../components/images/ic_social_linkedin_white.svg";
import iconXing from "../../components/images/ic_social_xing_white.svg";
import iconTwitter from "../../components/images/ic_social_twitter_white.svg";

import { RichText, LinkResolver } from "prismic-reactjs";

import {Anchor} from "../blocks/anchor";
import shape6img from "../images/shapes/new/img_02-min.jpg";
import NotFound from "../../NotFound";
import {useTranslation} from "react-i18next";

import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";



const NewsItemPageContainer = ( data ) => {
    gsap.registerPlugin(ScrollTrigger);
    const url = window.location.pathname.split('/').pop();
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);

    const {t, i18n} = useTranslation();

    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);


    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'en-us' }
            )
            if (response) {
                setDocData(response.results[0]);
            }

        }
        fetchData()
    }, [])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'de-de' }
            )
            if (response) {
                setDocDataDe(response.results[0]);
            }

        }
        fetchData()
    }, [])


console.log(data)
     const title =
      data.data.title.length !== 0 ?
      RichText.asText(data.data.title) :
      'Untitled';

    const blogContent = data.data.body.map((slice, index) => {
        // Render the right markup for the given slice type

        // Text Slice
        if (slice.slice_type === 'content') {
            return RichText.render(slice.primary.content);

            // Image Gallery Slice
        } else if (slice.slice_type === 'image_gallery') {
            const galleryContent = slice.items.map((image, imageIndex) => (
                <img src={image.gallery_image.url} alt={image.gallery_image.alt} key={imageIndex} />
            ));
            return (
                <div className="image-gallery" key={index}>
                    <h2 className="gallery-title">
                        {RichText.asText(slice.primary.gallery_title)}
                    </h2>
                    {galleryContent}
                </div>
            );

            // Return null by default
        }
        else if (slice.slice_type === 'image') {
            const ImageContent = slice.items.map((image, imageIndex) => (
                <Fragment>
                    <img className="articleImage" src={slice.primary.image.url} alt={slice.primary.image_caption} key={imageIndex} />
                    <p>{slice.primary.image_caption}</p>
                </Fragment>
            ));

            return (
                <div className="image" key={index}>
                    {ImageContent}
                </div>
            );

            // Return null by default
        }
        else if (slice.slice_type === 'video') {

            return (
                <div className="video_wrapper">
                    <iframe
                        src={slice.primary.link.url}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen ></iframe>
                </div>
            );

            // Return null by default
        }
        else {
            return null;
        }
    });
    // const content =
    //     data.data.content.length !== 0 ?
    //         RichText.asText(data.data.body[1].primary.content) :
    //         'Untitled';
    return (

        <div className="newsContainer newsArticle">
            <div className="sectionTopArticle">

                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 offset-md-1 col-md-10">
                            <div className="d-flex justify-content-between"><span className="article-type">PIA Group:{ data.data.article_type.slug} </span><span className="article-date">{data.data.date}</span></div>
                        </div>
                        <div className="col-12 offset-md-1 col-md-10">
                            <h1 className="articleTitle">{title}</h1>
                        </div>
                        <div className="col-12 offset-md-1 col-md-10">
                            <div className="articlesubHeadline">{data.data.body[0].primary.quote}</div>
                        </div>


                    </div>
                    <div className="row mt-4">
                        <div className="col-12 offset-md-1 col-md-10 ">
                            <div className="contentArticle">
                                {blogContent}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="container" style={{zIndex: '4', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12">

                            <div className="row mt-4">
                                <div className="col-12 col-lg-10 offset-lg-1">
                                    <div className="socialBottom d-flex align-items-center justify-content-between">
                                        <div className="socialText">{t('ShareArticle')}</div>
                                        <ul>
                                            <li><a href=""><img src={iconLinkedIn} alt="linkedin"/></a></li>
                                            <li><a href=""><img src={iconXing} alt="xing"/></a></li>
                                            <li><a href=""><img src={iconTwitter} alt="twitter"/></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>


            <div className="sectionKontakt">
                <div className="animationNewsLeft">
                    <img src={shape6img} alt="" style={{width: '1200px', height: '1200px'}}/>
                </div>
                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                            <div className="KontaktTexts">
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <div className="title">{docDe.data.title}</div>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <div className="title">{doc.data.title}</div>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <div className="description">{docDe.data.description}</div>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <div className="description">{doc.data.description}</div>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="infoPersonHolder">
                    {
                        isGerman ? (

                            <React.Fragment>
                                {
                                    docDe ? (
                                        <img src={ docDe.data.photo.url} alt=""/>

                                    ) : <div></div>
                                }
                            </React.Fragment>
                        ) : <React.Fragment>
                            {
                                doc ? (
                                    <img src={ doc.data.photo.url} alt=""/>
                                ) : <div></div>
                            }
                        </React.Fragment>
                    }

                    <div className="userData">

                        {
                            isGerman ? (

                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <div className="teamsName">{docDe.data.user_name}</div>

                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <div className="teamsName">{doc.data.user_name}</div>
                                    ) : <div></div>
                                }
                            </React.Fragment>
                        }
                        {
                            isGerman ? (
                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <div className="teamsPosition">{docDe.data.user_position}</div>

                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <div className="teamsPosition">{doc.data.user_position}</div>
                                    ) : <div></div>
                                }
                            </React.Fragment>
                        }
                        <div className="d-none d-md-block">

                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <a href={"tel:"+ docDe.data.phone} className="teamsPhone">{docDe.data.phone}</a>

                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <a href={"tel:"+ doc.data.phone} className="teamsPhone">{doc.data.phone}</a>
                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            }

                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <div className="d-flex deskSocialCont">
                                                    <a href={"mailto:"+ docDe.data.email} className="btn--inverted--solid btn--regular">{docDe.data.email}</a>
                                                </div>

                                            ) : <div className="d-flex deskSocialCont"></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <div className="d-flex deskSocialCont">
                                                <a href={"mailto:"+ doc.data.email} className="btn--inverted--solid btn--regular">{doc.data.email}</a>
                                            </div>
                                        ) : <div className="d-flex deskSocialCont"></div>
                                    }
                                </React.Fragment>
                            }


                        </div>
                        <div className="d-block d-md-none">
                            <div className="d-flex">
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <a href={"tel:"+ docDe.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <a href={"tel:"+ doc.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <a href={"mailto:"+ docDe.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <a href={"mailto:"+ doc.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>


                    </div>
                </div>
                <div className="shapesNewsPageKontakttrigger"></div>
            </div>



        </div>


    );
}
export default NewsItemPageContainer;
