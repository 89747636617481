import React from "react";
import loadable from '@loadable/component'

import SectionOneMobile from "./mobile/SectionOneMobile/SectionOneMobile";
import Block1 from "./mobile/Block1";
const Block2 = loadable(() => import('./mobile/Block2'));








function SectionsMobileHolder() {

    return (
        <div className="MobileComponents" style={{ minHeight: "400vh" }}>
                <Block1/>
                <Block2/>

        </div>

    );
}

export default SectionsMobileHolder;
