import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType4(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;

    const dims = {
        width: data.primary.full_image.dimensions.width,
        height: data.primary.full_image.dimensions.height
    }

    console.log('module type 4', data);
    let overlay = false;
    if(typeof data.primary.has_overlay != 'undefined') {
        if(data.primary.has_overlay) {
            overlay = true;
        }
    }

    return (
        <div className={`wideBgContainer imgbg module-4`}  id={`section-${key}`} style={{ height: `${dims.height}px`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center 12%', backgroundSize: 'cover', backgroundImage: 'url('+(typeof data.primary.full_image.url != 'undefined' ? data.primary.full_image.url : '')+')' }}>
            {
                overlay ?
                    (
                        <div className="overlay"></div>
                    ) : ('')
            }
            <div className="container-fluid container left-0" style={{ position: 'relative', top: '65%' }}>
                <div className="row">
                    <div className="col-12 col-lg-5 offset-lg-1">
                        {
                            data.primary.big_text != null ?
                                (
                                    <h4 className={"f-28"}>{data.primary.big_text}</h4>
                                ) : ('')
                        }

                        {
                            typeof data.primary.image_credit[0] != 'undefined' ?
                                (
                                    <p className="poppins-regular f-18">{data.primary.image_credit[0].text}</p>
                                ) : ('')
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType4;