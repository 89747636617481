import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NotFound from './NotFound';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

//import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import Post from './components/Post';
import News from './News';
import Jobs from './Jobs';
import JobsPositionContainer from './JobsPosition';
import NewsItemContainer from './NewsItem';
import ScrollHandler from './components/ScrollHandler';
import KontaktHeadquarter from './Kontakt';
import NewsItem from './NewsItem';
import CaseItem from './CaseItem';
import Impressum from "./Impressum";
import Privacy from "./Privacy"
import Page from "./Page";
import PageVideos from "./PageVideos";
import PrivacySocial from "./PrivacySocial";
import Home from "./Home";
import Event from "./Event";
import Arbeiten from "./Arbeiten";
import Projects from "./Projects";
import CaseStudyLandingPage from "./CaseStudyLandingPage";
import B2BLandingPage from "./B2BLandingPage";
import Responsibility from './ResponsibilityComplaince';
import ResponsibilitySustainability from './ResponsibilitySustainability';
import ResponsibilityComplaince from './ResponsibilityComplaince';
import DymatrixReport from './DymatrixReport';

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Switch>
            <Route
            exact
            sensitive
            path = "/:lng/responsibility/sustainability"
            component = {
              ResponsibilitySustainability
            }
            />
              < Route
              exact
              sensitive
              path = "/:lng/responsibility/compliance"
              component = {
                ResponsibilityComplaince
              }
              />
              <Route exact sensitive path="/:lng/home" component={Home} />
              <Route exact sensitive path="/:lng/news" component={News} />
              <Route exact sensitive path="/:lng/jobs" component={Jobs} />
              <Route exact sensitive path="/:lng/b2b" component={B2BLandingPage} />
                            < Route exact sensitive path = "/:lng/dymatrix-report"
                            component = {
                              DymatrixReport
                            }
                            />

              <Route exact sensitive path="/:lng/projects" component={Projects} />
              <Route exact match sensitive path="/:lng/contact" component={KontaktHeadquarter} />
              <Route exact sensitive path="/:lng/jobs/:jobDbId" component={JobsPositionContainer} />
              <Route exact sensitive path="/:lng/news/:uid" component={NewsItem} />
              <Route exact sensitive path="/:lng/case/:uid" component={CaseItem} />
              <Route exact sensitive path="/:lng/:uid" component={Page} />
              <Route exact sensitive path="/:lng/page/homepageVideos" component={PageVideos} />
              <Route exact sensitive path="/" component={Home} />
              <Route exact sensitive path="/testcms" component={Post} />
              <Route exact sensitive path="/:lng/page/imprint" component={Impressum} />
              <Route exact sensitive path="/:lng/page/privacy" component={Privacy} />
              <Route exact sensitive path="/:lng/page/privacy-social" component={PrivacySocial} />
              <Route exact sensitive path="/:lng/page/event" component={Event} />
              <Route exact sensitive path="/:lng/page/arbeiten" component={Arbeiten} />
              <Route exact sensitive path="/:lng/page/:page" component={CaseStudyLandingPage} />
                
              <Route component={NotFound} />
              <ScrollHandler/>
          </Switch>
      </Router>

     
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
