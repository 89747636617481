import './style/SectionTeamSlider.scss';
import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Pagination, Autoplay
} from 'swiper/core';
import {Anchor} from "./blocks/anchor";
import {gsap} from "gsap";
import TeamSliderShape from "./images/shapes/shape_10.jpg";
import { Client } from "../utilities/prismicHelpers";
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { linkResolver } from '../prismic-configuration';
import iconPhone from "./images/ic_phone_white.svg";
import iconMail from "./images/ic_mail_white.svg";
import iconLinkedIn from "./images/ic_social_linkedin_white.svg";
import iconXing from "./images/ic_social_xing_white.svg";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);





function SectionTeamSliderNew() {



    const [doc, setDocData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'team_members'),
                { lang : 'de-de' , pageSize : 100, orderings : '[document.first_publication_date]' },
            )
            if (response) {
                setDocData(response.results);
                console.log(response.results);
            }

        }
        fetchData()
    }, [])

    let elHeight;
    // useEffect(() => {
    //     let elHeight = document.getElementById('swiperAlignmentId').clientHeight;
    //     onscroll = function () {
    //         var div = document.getElementById('swiperAlignmentId');
    //         var element = document.getElementsByClassName('sectionTeamsSlider');
    //         document.getElementById('sectionTeamsSlider').style.height = `${div.clientHeight}px`;
    //         onscroll = null; // remove function so wont get called next time
    //     };
    //     var addEvent = function(object, type, callback) {
    //         if (object == null || typeof(object) == 'undefined') return;
    //         if (object.addEventListener) {
    //             object.addEventListener(type, callback, false);
    //         } else if (object.attachEvent) {
    //             object.attachEvent("on" + type, callback);
    //         } else {
    //             object["on"+type] = callback;
    //         }
    //     };
    //     addEvent(window, "resize", function(event) {
    //         var div = document.getElementById('swiperAlignmentId');
    //         var element = document.getElementsByClassName('sectionTeamsSlider');
    //         document.getElementById('sectionTeamsSlider').style.height = `${div.clientHeight}px`;
    //     });
    //
    // }, [])


    return (
        <section id="sectionTeamsSlider" className="sectionTeamsSlider d-flex align-items-center justify-content-center">
                <div id="swiperAlignmentId" className="swiperAlignment">

                            <div className="swiperMotionContainer">
                                <Swiper
                                    autoplay={{
                                        "delay": 2500,
                                        "disableOnInteraction": false
                                    }}
                                    observer={true}
                                    observeParents={true}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    loop={true}
                                    freeMode={true}
                                    grabCursor={true}
                                    // onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    speed={5000}
                                    freeModeMomentum={false}
                                    breakpoints={{
                                        // when window width is >= 320px
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 640px
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            slidesPerView: 1,
                                        },
                                        840: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        1440: {
                                            slidesPerView: 3,
                                        },
                                    }}

                                >
                                    {doc?.length && doc.map((item) => (
                                        <SwiperSlide key={item.id}>
                                            <div className="userImageContainer">
                                                <img src={ item.data.member_picture.url} alt=""/>
                                                <div className="userData">
                                                    <div className="teamsName">{item.data.member_name}</div>
                                                    <div className="teamsPosition">{item.data.member_position}</div>

                                                    <div className="d-block">
                                                        <div className="d-flex mt-4">
                                                            <div className="d-flex">
                                                                <a href={"tel:"+ item.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                                                <a href={"mailto:"+ item.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                                                            </div>
                                                            <div>
                                                                { item.data.linkedin == null ? '' :  <a href={item.data.linkedin} data-value={item.linkedin} className="iconMobileUser"><img src={iconLinkedIn} alt="linkedin"/></a> }
                                                                { item.data.xing == null ? '' : <a href={item.data.xing} data-value={item.xing} className="iconMobileUser"><img src={iconXing} alt="xing"/></a> }

                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}

                                </Swiper>
                            </div>
                </div>
        </section>
    );
}

export default SectionTeamSliderNew;
