import React from 'react';
import '../../components/style/SectionForm.scss';
import validate from './validateInfo';
import useForm from './useForm';
import FormInput from "./form-input.component";
import arrowImage from "../images/ic_arrow_down_white_small.svg";
import {useTranslation} from "react-i18next";
import {Button} from "../blocks/button";

const FormEvent = ({ submitForm }) => {
    const { handleChange, handleSubmit, values, errors } = useForm(
        submitForm,
        validate
    );
    const { t } = useTranslation();

    return (
        <div className="formContainer">


                <form id="contact-form" onSubmit={handleSubmit} method="POST" noValidate>


                            <div className="form-group">
                                <label className="categoryLabel">Jobkategorie wählen</label>
                                <select name="contact" onChange={handleChange}
                                        value={values.contact} style={{backgroundImage: `url(${arrowImage})`}}>
                                    <option value="phoneContact" defaultValue>{t('All')}</option>
                                    <option value="AdTech">Ad Tech</option>
                                    <option value="Administration">Administration</option>
                                    <option value="Affiliate">Affiliate</option>




                                        <option value="Alle Gewerke">Alle Gewerke</option>
                                        <option value="Content Management">Content Management</option>
                                        <option value="Data">Data</option>
                                        <option value="Design & Kreation">Design & Kreation</option>
                                        <option value="International">International</option>
                                        <option value="IT Services">IT Services</option>
                                        <option value="Marketing & Strategy Consulting">Marketing & Strategy
                                            Consulting
                                        </option>
                                        <option value="Programmatic Advertising">Programmatic Advertising
                                        </option>
                                        <option value="Project Management">Project Management</option>
                                        <option value="Sales & New Business">Sales & New Business</option>
                                        <option value="SEA">SEA</option>
                                        <option value="SEO">SEO</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Software Development">Software Development</option>
                                        <option value="UX & UI">UX & UI</option>

                                </select>
                            </div>


                            <FormInput
                                name='name'
                                type='text'
                                onChange={handleChange}
                                value={values.name}
                                label={t('formName')}
                                error={errors.name}
                                required
                            />
                            <FormInput
                                name='forename'
                                type='text'
                                onChange={handleChange}
                                value={values.forename}
                                label={t('formForename')}
                                error={errors.forename}
                                required
                            />

                            <FormInput
                                name='email'
                                type='email'
                                onChange={handleChange}
                                value={values.email}
                                label={t('formEmail')}
                                error={errors.email}
                                required
                            />
                            <FormInput
                                name='phone'
                                type='tel'
                                onChange={handleChange}
                                value={values.phone}
                                label={t('formTelefon')}
                                error={errors.phone}
                                required
                            />


                            <FormInput
                                name='message'
                                type='textarea'
                                onChange={handleChange}
                                value={values.message}
                                label={t('formMessage')}
                                error={errors.message}
                                required
                            />

                            <div className="privacyContainer mt-5" htmlFor="privacy">
                                <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                                       value="1"/>
                                <span>{t('formCheckboxText')}</span>
                            </div>
                            <div className="my-4">
                                <Button type={'submit'} buttonStyle='btn--inverted--solid' buttonSize='btn--regular'>{t('formSendEmailButton')}</Button>
                            </div>




                </form>

        </div>
    );
};

export default FormEvent;