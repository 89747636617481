import React, { useState } from 'react';
import '../style/ImageTextHalf.scss';
import { RichText } from 'prismic-reactjs';
import useForm1 from '../../components/form/useForm1';
import FormInput from "../../components/form/form-input.component";
import '../../components/style/responsive/style.scss';
import "../../components/style/contact.scss";


//export default ImageTextHalf;
function parseCodes(textWithCodes) {
    const splitText = textWithCodes.split('[br]').map((text, index) => (
        <React.Fragment key={index}>
            {text}
            {index !== textWithCodes.length - 1 && <br />} {/* Add <br /> except for the last element */}
        </React.Fragment>
    ));

    return (
        <React.Fragment>
            {splitText}
        </React.Fragment>
    );
}

function buildHtml(entity, customClasses) {
    let customClassesStr = '';

    if (!Array.isArray(entity) || entity.length <= 0) {
        return null;
    }

    if (Array.isArray(customClasses) && customClasses.length > 0) {
        customClassesStr = customClasses.join(' ');
    }

    return entity.map((element, index) => {
        let text = parseCodes(element.text);

        if (typeof element.type === 'string') {
            switch (element.type) {
                case 'heading1':
                    return <h1 className={customClassesStr} key={index}>{text}</h1>;

                case 'heading2':
                    return <h2 className={customClassesStr} key={index}>{text}</h2>;

                case 'heading5':
                    return <h5 className={customClassesStr} key={index}>{text}</h5>;

                case 'heading6':
                    return <h6 className={customClassesStr} key={index}>{text}</h6>;

                // By default, treat as paragraph
                default:
                    return <p className={customClassesStr} key={index}>{text}</p>;
            }
        }
        return null;
    });
}
function Contact(props) {
    const content = props.content;

    const { handleChange, handleSubmit, values, errors } = useForm1(
        submitForm,
        validate
    );
    const [valuess, setValuess] = useState('');

    function submitForm() {

    }

    function validate() {

    }

    function handleSuccess() {

    }

    function handleFailure() {

    }

    return (
        < div class = "contact-section-container-module" >
           

            {/* <div className={`contact-module-container contact-module-contact`}>
                <div class="row">
                    <div class="col-12 col-md-2 t-36">
                        {
                            content.contact_title
                        }
                    </div>
                    <div class="col-12 col-md-8 right-col">
                        <form id="formtab1" onSubmit={handleSubmit} method="POST" noValidate>

                            <FormInput
                                style={{ marginTop: '0px' }}
                                name='name'
                                type='text'
                                onChange={handleChange}
                                value={valuess.name}
                                label="First name"
                                error={errors.name}
                                required
                            />
                            <FormInput
                                name='forename'
                                type='text'
                                onChange={handleChange}
                                value={valuess.forename}
                                label="Last name"
                                error={errors.forename}
                                required
                            />

                            <FormInput
                                name='email'
                                type='email'
                                onChange={handleChange}
                                value={valuess.email}
                                label="Email"
                                error={errors.email}
                                required
                            />
                            <FormInput
                                name='phone'
                                type='tel'
                                onChange={handleChange}
                                value={values.phone}
                                label="Phone"
                                error={errors.phone}

                            />

                            <div className="privacyContainer mt-5" htmlFor="privacy">
                                <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                                    value="1" />
                                <span class="contact-terms">
                                    {
                                        buildHtml(content.contact_terms)
                                    }
                                </span>
                            </div>
                            <div className="">

                                <a class="contact-optional-link" href="#">Optional link</a>

                                <a href="#" className="btn--inverted--solid btn--regular btn-h-40">
                                    Send
                                </a>
                            </div>

                        </form>
                    </div>
                </div>
            </div> */}

            <div class="contact-module-contact-person">
                {
                    <div class="first-section" style={{ backgroundImage: `url(${content.contact_second_image && content.contact_second_image.url ? content.contact_second_image.url : ''})` }}>
                        <div class="first-section-content">
                            <div class="align-content">
                                <h5 class="p-b-40 t-36 text-white">
                                    {
                                      content.bg_white?
                                       buildHtml(content.contact_headline, ['text-black']):
                                         buildHtml(content.contact_headline, ['text-white'])
                                      
                                    }
                                </h5>
                                {
                                   content.bg_white ?
                                     buildHtml(content.contact_description, ['text-black']):
                                     buildHtml(content.contact_description, ['text-white'])

                                }
                            </div>
                        </div>
                    </div>
                }
                <div class="second-section" style={{ backgroundImage: `url(${content.contact_image && content.contact_image.url ? content.contact_image.url : ''})` }}>
                    <div style={{ position: 'relative', height: '100%' }}>
                        <div style={{ position: 'absolute', left: '0px', bottom: '0px', width: '100%', height: '50%', background: `linear-gradient(180deg, rgba(255, 255, 255, 7.2e-05) 0%, rgba(128, 128, 128, 0.259146) 35.44%, rgba(0, 0, 0, 0.5184) 100%)` }}>
                            <div style={{ position: 'absolute', left: '40px', bottom: '60px' }}>
                                <h4 class="contact-name" style={{ color: '#ffffff' }}>
                                    {
                                        content.contact_name
                                    }
                                </h4>
                                <span class="contact-tagline">
                                    {
                                      content.contact_tagline
                                    }
                                </span>
                                <p class="contact-phone mb-3p" style={{ color: '#ffffff' }}>
                                    {
                                      content.contact_phone
                                      
                                    }
                                </p>
                                {
                                    typeof content.contact_cta_text != 'undefined' ?
                                        (
                                            < a href = {
                                                `mailto:${content.contact_cta_text}`
                                            }
                                            className = "btn--inverted--solid btn--regular ptop-1 btn-h-40" >
                                                {
                                                    content.contact_cta_text != '' ?
                                                        (
                                                            content.contact_cta_text
                                                        ) : ('View')
                                                }
                                            </a>
                                        ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
