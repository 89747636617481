import {useTranslation} from "react-i18next";


export const apiEndpoint = 'https://pia-website.prismic.io/api/v2'
export const accessToken = 'MC5XX2U4R1JJQUFDMEEyZzZ3.J--_vTnvv70lHu-_ve-_vXZ5fO-_vVHvv73vv73vv71u77-977-9ee-_ve-_vXEL77-9NO-_ve-_vRtdPe-_vQ' // This is where you would add your access token for a Private repository
// export const apiEndpoint = 'https://new-pia-website.prismic.io/api/v2'
// export const accessToken = 'MC5ZU2lqNmhBQUFDSUFpRFFU.77-977-9LDsIG--_vTUV77-9X2Dvv71TUu-_ve-_ve-_vQHvv73vv73vv70F77-9J1bvv70tN--_ve-_vVM'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc,lng) => {
    if (doc.type === 'news_article_detail')  return`/${lng}/news/${doc.uid}`
     if (doc.type === 'job_detail') return `/${lng}/jobs/${doc.uid}`
    return '/'
}
export const linkResolver_jobs = (doc,lng) => {
   return `/${lng}/jobs/${doc.jobDbId}`
}