import React, { useEffect, useRef} from "react";




function SectionCasesAnchor() {

    const myRef = useRef();

    function scrollToComponent() {
        if (window.location.hash === '#cases') {
            // myRef.current.scrollTo('#cases_location', {smooth: true, duration: 700})
            // window.location.hash = "#cases_location";
            // myRef.current.scrollIntoView(true);
            // myRef.current.focus();

            const id = "cases";
            const yOffset = 0;
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });

        }
    };
    useEffect( () => scrollToComponent(), [] );

    return (

        <div className="casesAnchor" ref={myRef} id='cases'>

        </div>
    )
}

export default SectionCasesAnchor;
