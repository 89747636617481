import { useState, useEffect } from 'react';
import axios from "axios";

const useForm = (callback, validate) => {
    const [values, setValues] = useState({
        name: "",
        forename: "",
        email: "",
        phone: "",
        contact:'phoneContact',
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);

    };

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                callback();
                var qs = require('qs');
                axios({
                    method: "POST",
                    url: "https://stage.pia.me/pia/mail/server.php",
                    data:  qs.stringify(values)
                }).then((response)=>{
                    if (response.data.status === 'success') {
                         //alert("Message Sent.");  //Change Message Here
                        document.querySelector('.pageContent').remove();
                        document.querySelector('.ynwabtntop').remove();

                    } else if (response.data.status === 'fail') {
                         //alert("Message failed to send.")  //Change Message Here
                    }
                }).catch((error) => {
                    // Error
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the
                        // browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });

            }
        },
        [errors, callback, isSubmitting, values]
    );

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;