import './style/SectionNews.scss';
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
// Import Swiper styles
import 'swiper/swiper.scss';
import "swiper/components/pagination/pagination.min.css"
import { Client } from "../utilities/prismicHelpers";
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { linkResolver } from '../prismic-configuration';

// import Swiper core and required modules
import SwiperCore, {
    Pagination, Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

function SectionNewsSliderNew() {



    const [prismicData, setPrismicData] = useState({newsPosts: null,newsPostsCopy:null});
    const [notFound, toggleNotFound] = useState(false);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [articles, setArticles] = useState([]);
    let languageKey;
    let lang = i18n.language;

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }
    const fetchPrismicData = async (languageKey) => {
        try {
            const newsPosts = await Client.query([
                    Prismic.Predicates.at('document.type', 'news_article_detail'),
                    Prismic.Predicates.at('my.news_article_detail.isonhomepage', true)],
                { lang : languageKey , orderings : '[my.news_article_detail.date desc]' },
            );
            if (newsPosts) {
                // var temp =newsPosts.results.filter(data=>data.lang ===languageKey)
                setPrismicData({ newsPosts:  newsPosts.results,newsPostsCopy:newsPosts.results});
                //check if page finished
            } else {
                console.warn('No Data');
                toggleNotFound(true);
            }
        } catch (error) {
            console.error(error);
            toggleNotFound(true);
        }
    }
    useEffect( () => {

        if (i18n.language=='en') {
            if(prismicData.newsPostsCopy){
                fetchPrismicData('en-us');
            }
        }
        else{
            if(prismicData.newsPostsCopy){
                fetchPrismicData('de-de');
            }
        }

    } , [i18n.language]);





    useEffect(() => {
        // fetch('/data/articles.json')
        //     .then(response => response.json())
        //     .then(data => setArticles(data));
        // if(inView){
        fetchPrismicData(languageKey);
        // }
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    var newsPostsAll1;
    var  title='Untitled';
    if(prismicData.newsPosts){
        newsPostsAll1=prismicData.newsPosts;
        console.log(newsPostsAll1);



    }


    return (

        <section className="sectionNewsSlider d-flex align-items-start justify-content-center" >
                <div className="swiperAlignment">
                    <Swiper
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false
                        }}
                        loop={true}
                        freeMode={true}
                        grabCursor={true}
                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1440: {
                                slidesPerView: 3,
                            },
                        }}
                        observer={true}
                        observeParents={true}
                        speed={5000}
                        freeModeMomentum={false}
                        spaceBetween={50}
                        slidesPerView={3}
                    >
                        {newsPostsAll1?.length && newsPostsAll1.map( news =>
                            <SwiperSlide key={news.id}>
                                <div className="newsItemContainer">
                                    <div className="newsImageContainer">
                                        { news.data.isexternal ? <a target="_blank" href={news.data.externallink.url}><img src={news.data.meta_open_graph_image ?news.data.meta_open_graph_image.url:''} alt=""/></a> :
                                            <Link to={linkResolver(news,lang)}><img src={news.data.meta_open_graph_image ?news.data.meta_open_graph_image.url:''} alt=""/></Link>}

                                    </div>
                                    {/*<div className="newsCategory">{news.data.article_type ?news.data.article_type.slug:''}</div>*/}
                                    <div className="newsTitle mt-5">
                                        { news.data.isexternal ? <a target="_blank" href={news.data.externallink.url}><p className="" >{RichText.asText(news.data.title)}</p></a> :
                                            <Link to={linkResolver(news,lang)}>
                                                <p className="" >{RichText.asText(news.data.title)}</p>
                                            </Link>}
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}

                    </Swiper>
                </div>
        </section>
    );
}

export default SectionNewsSliderNew;
