import React, { useEffect,useState } from "react";
import { gsap } from 'gsap';
import '../../i18n/config';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom';
import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";
import {CustomView, isMobile, isTablet} from "react-device-detect";

import {
    ModuleType1,
    ModuleType10,
    ModuleType11,
    ModuleType12,
    ModuleType13, ModuleType2, ModuleType3, ModuleType4, ModuleType5, ModuleType6,
    ModuleType7,
    ModuleType8,
    ModuleType9,
    ModuleGallery,
    ModuleHero,
    ResultsTable
} from "./case_modules/Modules";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function Test(props) {
    let caseStudy = props.case;
    let width = props.width;
    const breakpoint = 768;
    const mobileBreakpoint = 576;
    gsap.registerPlugin(ScrollTrigger);

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);

    const {t, i18n} = useTranslation();
    let langStr;

    if(i18n.language == 'en') {
        langStr = 'en-Us';
    } else {
        langStr = 'de-De';
    }

    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);
    const location = useLocation();
    const [uid, setUid] = React.useState(location);

    React.useEffect(() => {
        const fetchData = async () => {
            let uidStr = uid.pathname.split('/');
            uidStr = uidStr[3];

            const response = await Client.getByUID('case_article_post', uidStr,
                { lang : langStr },
            );
            if (response) {
                setDocData(response);
            }
        }
        fetchData()
    }, [])

    if(doc) {
        for(let i=0;i<doc.length;i++) {
            if(doc[i].uid == '1-porsche-en') {
                caseStudy = doc[i];
            }
        }
    }

    console.log('case', doc);
    let [projectDetailsVisible, setProjectDetailsVisiblity] = React.useState(null);

    function toggleProjectDetails() {
        setProjectDetailsVisiblity(!projectDetailsVisible);
        console.log('vis', projectDetailsVisible);
    }

    let color = 'isDark';
    let extraClass = '';
    if(doc) {
        if(doc.data.iswhite) {
            color = ' isWhite';
        }

        if(doc.data.isbackgroundwhite) {
            color = color + ' isLight';
        }

        if(doc.data.extra_class) {
            extraClass = doc.data.extra_class;
        }

        if(width < mobileBreakpoint) {
            if(typeof doc.data.case_image_mobile.url != 'undefined') {
                doc.data.case_image_desktop = doc.data.case_image_mobile;
            }
        } else if(width < breakpoint) {
            if(typeof doc.data.case_image_tablet.url != 'undefined') {
                doc.data.case_image_desktop = doc.data.case_image_tablet;
            }
        }

        doc.data.body.map((item, index) => {
            if(width <= mobileBreakpoint) {

                //Module 13 - Full width Video module
                if(item.slice_type == 'module_type_13') {

                }

                if(item.slice_type == 'module_type_12') {
                    if(typeof doc.data.body[index].primary.image_link_mobile.url != 'undefined') {
                        doc.data.body[index].primary.image_link = doc.data.body[index].primary.image_link_mobile;
                    }
                }

                //Module 11 - Case headline subline bulletpoints module
                if(item.slice_type == 'module_type_11') {
                    if(typeof doc.data.body[index].primary.image_mobile.url != 'undefined') {
                        doc.data.body[index].primary.image = doc.data.body[index].primary.image_mobile;
                    }
                }

                //Module type 10 - Case services module
                if(item.slice_type == 'module_type_10') {
                    if(typeof doc.data.body[index].primary.full_image.url != 'undefined') {
                        doc.data.body[index].primary.full_image = doc.data.body[index].primary.full_image_mobile;
                    }
                }

                //Module 9 - Case title, image or video, list module
                if(item.slice_type == 'module_type_9') {

                }

                //Module 8 - Cases title list, if first will be show in hero section
                if(item.slice_type == 'module_type_8' && index > 0) {

                }

                //Module 7 - Case title description and list module
                if(item.slice_type == 'module_type_7') {

                }

                //Module 6 - Cases title, description, image
                if(item.slice_type == 'module_type_6') {
                    if(typeof doc.data.body[index].primary.image_module_6_mobile.url != 'undefined') {
                        doc.data.body[index].primary.image_module_6 = doc.data.body[index].primary.image_module_6_mobile;
                    }
                }
                //Module 5 - New: Cases big heading module / Old desc:Quote module - with bg
                if(item.slice_type == 'module_type_5') {
                    if(typeof doc.data.body[index].primary.image_mobile.url != 'undefined') {
                        doc.data.body[index].primary.image = doc.data.body[index].primary.image_mobile;
                    }
                }

                //Module 4 - Case wide bg with title and description over
                if(item.slice_type == 'module_type_4') {
                    if(typeof doc.data.body[index].primary.full_image_mobile.url != 'undefined') {
                        doc.data.body[index].primary.full_image = doc.data.body[index].primary.full_image_mobile;
                    }
                }

                //Module 3 - Cases title, list, description module
                if(item.slice_type == 'module_type_3') {

                }

                //Module 2 - Left title and description, with right image and/or video and 3 list elements for stats.
                if(item.slice_type == 'module_type_2') {
                    if(typeof doc.data.body[index].primary.image_link_mobile.url != 'undefined') {
                        doc.data.body[index].primary.image_link = doc.data.body[index].primary.image_link_mobile;
                    }
                }
                //Module 1 - Left image with two paragraphs with title each, paragraphs may be 1 or 2.
                if(item.slice_type == 'module_type_1') {
                    console.log('doctest', doc.data.body[index]);
                    if(typeof doc.data.body[index].primary.module_1_image_mobile.url != 'undefined') {
                        doc.data.body[index].primary.module_1_image = doc.data.body[index].primary.module_1_image_mobile;
                    }
                }
            }else if(width <= breakpoint) {
                //Module 13 - Full width Video module
                if(item.slice_type == 'module_type_13') {

                }

                if(item.slice_type == 'module_type_12') {
                    if(typeof doc.data.body[index].primary.image_link_tablet.url != 'undefined') {
                        doc.data.body[index].primary.image_link = doc.data.body[index].primary.image_link_tablet;
                    }
                }

                //Module 11 - Case headline subline bulletpoints module
                if(item.slice_type == 'module_type_11') {
                    if(typeof doc.data.body[index].primary.image_tablet.url != 'undefined') {
                        doc.data.body[index].primary.image = doc.data.body[index].primary.image_tablet;
                    }
                }

                //Module type 10 - Case services module
                if(item.slice_type == 'module_type_10') {
                    if(typeof doc.data.body[index].primary.full_image_tablet.url != 'undefined') {
                        doc.data.body[index].primary.full_image = doc.data.body[index].primary.full_image_tablet;
                    }
                }

                //Module 9 - Case title, image or video, list module
                if(item.slice_type == 'module_type_9') {

                }

                //Module 8 - Cases title list, if first will be show in hero section
                if(item.slice_type == 'module_type_8' && index > 0) {

                }

                //Module 7 - Case title description and list module
                if(item.slice_type == 'module_type_7') {

                }

                //Module 6 - Cases title, description, image
                if(item.slice_type == 'module_type_6') {
                    if(typeof doc.data.body[index].primary.image_module_6_tablet.url != 'undefined') {
                        doc.data.body[index].primary.image_module_6 = doc.data.body[index].primary.image_module_6_tablet;
                    }
                }
                //Module 5 - New: Cases big heading module / Old desc:Quote module - with bg
                if(item.slice_type == 'module_type_5') {
                    if(typeof doc.data.body[index].primary.image_tablet.url != 'undefined') {
                        doc.data.body[index].primary.image = doc.data.body[index].primary.image_tablet;
                    }
                }

                //Module 4 - Case wide bg with title and description over
                if(item.slice_type == 'module_type_4') {
                    if(typeof doc.data.body[index].primary.full_image_tablet.url != 'undefined') {
                        doc.data.body[index].primary.full_image = doc.data.body[index].primary.full_image_tablet;
                    }
                }

                //Module 3 - Cases title, list, description module
                if(item.slice_type == 'module_type_3') {

                }

                //Module 2 - Left title and description, with right image and/or video and 3 list elements for stats.
                if(item.slice_type == 'module_type_2') {
                    if(typeof doc.data.body[index].primary.image_link_tablet.url != 'undefined') {
                        doc.data.body[index].primary.image_link = doc.data.body[index].primary.image_link_tablet;
                    }
                }
                //Module 1 - Left image with two paragraphs with title each, paragraphs may be 1 or 2.
                if(item.slice_type == 'module_type_1') {
                    console.log('doctest', doc.data.body[index]);
                    if(typeof doc.data.body[index].primary.module_1_image_tablet.url != 'undefined') {
                        doc.data.body[index].primary.module_1_image = doc.data.body[index].primary.module_1_image_tablet;
                    }
                }
            }
        });
    }

    return doc ? (
        <div className={`landingPage ${extraClass}`}>
            <div className={`${color} pageHeader`}>

                <ModuleHero data={doc.data} />
            </div>

            <div className={`${color}`}>
                {doc.data.body.map((item, index) => {
                    //Module gallery
                    if(item.slice_type == 'module_gallery') {
                        if(index > 0) {
                            return (
                                <div key={`custom-view-` + index}>
                                    <div key={`module-spacer-` + index} className={`module-spacer`}>&nbsp;</div>
                                    <ModuleGallery moduleNo={index} key={index} data={item}></ModuleGallery>
                                </div>
                            );
                        }

                        return (
                            <ModuleGallery moduleNo={index} key={index} data={item}></ModuleGallery>
                        );
                    }

                    //Module 13 - Full width Video module
                    if(item.slice_type == 'module_type_13') {
                        return (
                            <ModuleType13 moduleNo={index} key={index} data={item}></ModuleType13>
                        );
                    }

                    //Module 12 - Case headline numbers module
                    //If has Projects as title it appears in the hero section if clicked on the projects btn
                    if(item.slice_type == 'module_type_12' && item.primary.title1 != 'Projects') {
                        return (
                            <ModuleType12 moduleNo={index} key={index} data={item}></ModuleType12>
                        );
                    }

                    //Module 11 - Case headline subline bulletpoints module
                    if(item.slice_type == 'module_type_11') {
                        return (
                            <ModuleType11 extraClass={doc.data.extra_class} moduleNo={index} key={index} data={item}></ModuleType11>
                        );
                    }

                    //Module type 10 - Case services module
                    if(item.slice_type == 'module_type_10') {
                        return (
                            <ModuleType10 moduleNo={index} key={index} data={item}></ModuleType10>
                        );
                    }

                    //Module 9 - Case title, image or video, list module
                    if(item.slice_type == 'module_type_9') {
                        return (
                            <ModuleType9 moduleNo={index} key={index} data={item}></ModuleType9>
                        );
                    }

                    //Module 8 - Cases title list, if first will be show in hero section
                    if(item.slice_type == 'module_type_8' && index > 0) {
                        return (
                            <ModuleType8 moduleNo={index} key={index} data={item}></ModuleType8>
                        );
                    }

                    //Module 7 - Case title description and list module
                    if(item.slice_type == 'module_type_7') {
                        return (
                            <ModuleType7 moduleNo={index} key={index} data={item}></ModuleType7>
                        );
                    }

                    //Module 6 - Cases title, description, image
                    if(item.slice_type == 'module_type_6') {
                        return (
                            <ModuleType6 moduleNo={index} key={index} data={item}></ModuleType6>
                        );
                    }
                    //Module 5 - New: Cases big heading module / Old desc:Quote module - with bg
                    if(item.slice_type == 'module_type_5') {
                        return (
                            <ModuleType5 moduleNo={index} key={index} data={item}></ModuleType5>
                        );
                    }

                    //Module 4 - Case wide bg with title and description over
                    if(item.slice_type == 'module_type_4') {
                        return (
                            <ModuleType4 moduleNo={index} key={index} data={item}></ModuleType4>
                        );
                    }

                    //Module 3 - Cases title, list, description module
                    if(item.slice_type == 'module_type_3') {
                        return (
                            <ModuleType3 moduleNo={index} key={index} data={item}></ModuleType3>
                        );
                    }

                    //Module 2 - Left title and description, with right image and/or video and 3 list elements for stats.
                    if(item.slice_type == 'module_type_2') {
                        return (
                            <ModuleType2 moduleNo={index} key={index} data={item}></ModuleType2>
                        );
                    }
                    //Module 1 - Left image with two paragraphs with title each, paragraphs may be 1 or 2.
                    if(item.slice_type == 'module_type_1') {
                        return (
                            <ModuleType1 moduleNo={index} key={index} data={item}></ModuleType1>
                        );
                    }

                    //Results table
                    if(item.slice_type == 'resultstable') {
                        return (
                            <ResultsTable moduleNo={index} key={index} data={item}></ResultsTable>
                        );
                    }

                })}

                <div className="caseStudyPageContainer d-block mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <a className="btn--inverted--solid btn--regular btn-h-40">Weitere Cases entdecken</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="caseStudyPageContainer">&nbsp;</div>
            </div>
        </div>
    ) : (<div className={`page-loading`}>Loading</div>);
}

export default Test;
