import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType1(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;

    let imgUrl = typeof data.primary.module_1_image.url != 'undefined' ? data.primary.module_1_image.url : null;
    let dims = {
        width: data.primary.module_1_image.dimensions.width,
        height: data.primary.module_1_image.dimensions.height
    }
    console.log('module 1', data);

    return (
        <div className={`caseStudyPageContainer module-1`} id={`section-${key}`}>
            <div className="container-fluid container">
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 ">
                        <div className="row text-left mt-5">
                            {
                                typeof data.primary.module_1_image.url != 'undefined' ?
                                (
                                    <div className="col-12 col-lg-5">
                                        <img src={imgUrl} style={{width: '100%', height: 'auto'}}/>
                                    </div>
                                ) : ('')
                            }

                            <div className="col-12 col-lg-7">
                                {
                                    data.primary.title_1_module_1 != null || data.primary.description_1_module_1 != null ?
                                        (
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        data.primary.title_1_module_1 != null ?
                                                            (
                                                                <h3 className={`f-36`}>{data.primary.title_1_module_1}</h3>
                                                            ) : ('')
                                                    }
                                                    {
                                                        data.primary.description_1_module_1 != null ?
                                                        (
                                                            <p className="poppins-regular">{data.primary.description_1_module_1}</p>
                                                        ) : ('')
                                                    }
                                                </div>
                                            </div>
                                        ) : ('')
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <h3 className={`f-20`}>{data.primary.title_2_module_1}</h3>
                                        <p className="poppins-regular">{data.primary.description_2_module_1}</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType1;