import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ResultsTable(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;

    console.log('module type results table', data)


    return (
        <div className={`caseStudyPageContainer`} id={`section-${key}`}>
            <div className={`container-fluid container`}>
                <div className={`row text-left`}>
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <h2 style={{ fontSize: '32px' }}>The results*:</h2>
                        <table className="poppins-regular results-table" style={{width: '100%', color: 'gray'}}>
                            <thead>
                            <tr>
                                <th></th>
                                <th class={`text-white`}>Pre Optimization</th>
                                <th class={`text-white`}>Post Optimization</th>
                                <th class={`text-white`}>Improvement</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>First Content Paint (FCP)
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>6,33 seconds
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>2,09 seconds
                                </td>
                                <td className={`standOut`} style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>+67%
                                </td>
                            </tr>

                            <tr>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>Largest Contentful Paint (LCP)
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>13,8 seconds
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>14,6 seconds
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>-6%
                                </td>
                            </tr>

                            <tr>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>Cummulative Layout Shift (CLS)
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>0,51 seconds
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>0,34 seconds
                                </td>
                                <td className={`standOut`} style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>+33%
                                </td>
                            </tr>

                            <tr>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>Time to interactiven (TTI)
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>22,8 seconds
                                </td>
                                <td style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>18,2 seconds
                                </td>
                                <td className={`standOut`} style={{
                                    borderTop: '1px solid gray',
                                    borderBottom: '1px solid gray'
                                }}>+20%
                                </td>
                            </tr>

                            <tr>
                                <td style={{borderTop: '1px solid gray'}}>Speed Score <span className={`td-info`}>*Source: Google Developer Tools (LAB Data)</span></td>
                                <td style={{borderTop: '1px solid gray'}}>5,5</td>
                                <td style={{borderTop: '1px solid gray'}}>21,8</td>
                                <td className={`standOut`} style={{borderTop: '1px solid gray'}}>+393%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultsTable;