import React, { useEffect,useState } from "react";
import './App.scss';
import './Fonts.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import './i18n/config';
import './components/style/responsive/style.scss';
import ScrollToTop from "./components/ScrollToTop";
// import {Helmet} from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useTranslation} from "react-i18next";
import Footer from "./components/Footer";
import { RichText } from 'prismic-reactjs';
import { Client } from "./utilities/prismicHelpers";
import NotFound from "./NotFound";
import CaseItemPageContainer from "./components/pages/caseItem";

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

const CaseItem = ({ match }) => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const uid = match.params.uid;

    const href = window.location.href;

    useEffect(() => {
        console.log(`Page href is: ${href}`)
    }, [href]);

    useEffect(()=> {
        window.emos3.send({
            content: 'Case Item ' + match.params.uid
        });
    }, []);

    const { t , i18n } = useTranslation();
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await Client.getByUID('case_article_post', uid ,{lang:languageKey});

                if (doc) {
                    setPrismicDoc(doc);
                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);

    if (prismicDoc) {

        const metaTitle = prismicDoc.data.meta_title.length !== 0 ?
            RichText.asText(prismicDoc.data.meta_title) :
            'PIA';
        const metaDescription = prismicDoc.data.meta_description.length !== 0 ?
            prismicDoc.data.meta_description :
            ' ';
        const metaTags = prismicDoc.data.meta_tags.length !== 0 ?
            RichText.asText(prismicDoc.data.meta_tags) :
            ' ';
        // const title =
        //     prismicDoc.data.title.length !== 0 ?
        //         RichText.asText(prismicDoc.data.title) :
        //         'Untitled';
        return (
            <HelmetProvider>
                <div className="App inverted">
                    <Helmet>
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDescription} />
                        <meta name="keywords" content={metaTags} />
                        <link rel="canonical" href={href} />

                    </Helmet>
                    <ViewportProvider>
                        <HeaderComponent />
                        <div id="postHeader"></div>
                        <CaseItemPageContainer data={prismicDoc.data}/>
                    </ViewportProvider>

                    <Footer/>


                    <ScrollToTop/>
                </div>
            </HelmetProvider>
        )
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}


export default CaseItem;

