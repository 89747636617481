// import logo from './logo.svg';
import logoDN from '../logo1.svg';
import './style/Header.scss';
import React, { useState, useEffect, useRef } from 'react';
import { debounce } from '../utilities/helpers';
import { useTranslation } from 'react-i18next';
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as Link2 } from 'react-router-dom';
import BurgerMenu from "./navBurger";




function HeaderMobile() {
    const headerRef = useRef(null);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    function changeLanguage(e) {
        i18n.changeLanguage(e.target.value);
    }
    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 71);

        setPrevScrollPos(currentScrollPos);
    }, 100);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, visible, handleScroll]);

  const handleClick = (event, lng) => {
      event.preventDefault();
      const currentPath = window.location.pathname;

      let newPath = currentPath;

      // Toggle '/en/' with '/de/' and vice versa
      if (currentPath.includes('/en/')) {
          newPath = currentPath.replace('/en/', '/de/');
      } else if (currentPath.includes('/de/')) {
          newPath = currentPath.replace('/de/', '/en/');
      }

      window.location.href = newPath; // Redirect to the new URL
  };

    const headerStyles = {
            position: 'fixed',
            height: '72px',
            width: '100%',
            textAlign: 'center',
            transition: 'top 0.2s'
    }
    var languageButton;
    var languageKey;

   if (i18n.language == 'en') {
    languageKey = '/en/homepage';
    languageButton = (
      <ul className="nav-lang">
        <li>
          <a component = {
            Link2
          }
          href = '/'
          onClick = {
            (e) => handleClick(e, 'de')
          } >
            De
          </a>
        </li>
        <li>
          < a component = {
            Link2
          }
          href = '/'

          onClick = {
            (e) => handleClick(e, 'en')
          }
          className = "active" >
            En
          </a>
        </li>
      </ul>
    );
 
  } else {
    languageButton = (
      <ul className="nav-lang">
        <li>
          < a component = {
            Link2
          }
          href = '/'

          onClick = {
            (e) => handleClick(e, 'de')
          }
          className = "active" >
            De
          </a>
        </li>
        <li>
          < a component = {
            Link2
          }
          href = '/'

          onClick = {
            (e) => handleClick(e, 'en')
          } >
            En
          </a>
        </li>
      </ul>
    );
    languageKey = '/de/homepage';
  
  }

    return (
        <header id="headerSection" className="App-header d-flex align-items-center" style={{ ...headerStyles, top: visible ? '0' : '-72px' }} ref={headerRef}>
            <div className="container d-none d-lg-block">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-md-1">
                      {/* <Link2 to={languageKey}><img src={logo} className="App-logo" alt="logo" /></Link2> */}
                      </div>
                    <div className="col-12 col-md-9 offset-md-1">
                        <div className="d-flex justify-content-between">
                            <ul className="nav-top menu-left">
                                <li><Link
                                    activeClass="active"
                                    to="sectionMission"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >{t('header-mission')}</Link></li>
                                <li><Link
                                    activeClass="active"
                                    to="sectionMachen"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >{t('header-machen')}</Link></li>
                                <li><Link
                                    activeClass="active"
                                    to="sectionAgenturen"
                                    spy={true}
                                    smooth={true}
                                    offset={0}
                                    duration={500}
                                >{t('header-sind')}</Link></li>
                            </ul>
                            <ul className="nav-top menu-right">
                                <li><a href={"/" + i18n.language + "/page/arbeiten"}>{t('header-arbeiten')}</a></li>
                                <li className="hasSubmenu"><a href="#" className="jobs-link-menu">{t('header-careers')}</a>
                                    <ul className="submenu">
                                        <li><a href={"/" + i18n.language + "/page/arbeiten"} className="kareers-link-menu">{t('header-arbeiten')}</a></li>
                                        <li><a href={"/" + i18n.language + "/jobs"} className="jobs-link-menu">Jobs</a></li>
                                    </ul>
                                </li>
                                

                                <li><a href={"/" + i18n.language + "/news"}>News</a></li>
                                <li><a href={"/" + i18n.language + "/contact"}>{t('header-kontakt')}</a></li>

                                {/*<li><Link*/}
                                {/*    activeClass="active"*/}
                                {/*    to="sectionKontakt"*/}
                                {/*    spy={true}*/}
                                {/*    smooth={true}*/}
                                {/*    offset={0}*/}
                                {/*    duration={500}*/}
                                {/*>{t('header-kontakt')}</Link></li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        {languageButton}
                    </div>
                </div>
            </div>
            <div className="container-fluid d-block d-lg-none">
                <div className="d-flex align-items-center justify-content-between">
                <a href = {'https://www.dymatrix.de/'} className="kareers-link-menu">
                  <img src={logoDN} className="App-logo" style={{ width: '200px' }} alt="logo" />
                  {/*<img src={logoWhite} className="App-logo-white" alt="logo" />*/}
                </a>
                    {/* <BurgerMenu/> */}
                </div>
            </div>
        </header>
    );
}

export default HeaderMobile;
