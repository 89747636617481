import './style/ScrollToTop.scss';
import React, {useEffect, useState} from "react";
import arrowTop from "./images/ic_arrow_up.svg";

function ScrollAnimation() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(false)
        }
        else if (scrolled <= 300){
            setVisible(true)
        }
    };
    window.addEventListener('scroll', toggleVisible);

    return (
        <div className="scrollAnimationContainer" style={{display: visible ? 'inline' : 'none'}}>
             <a className="scrollX" href="#"></a>
        </div>

    );
}

export default ScrollAnimation;
