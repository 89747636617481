import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType2(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;
    let dims = null;
    if(typeof data.primary.image_link.dimensions != 'undefined') {
        dims = {
            width: data.primary.image_link.dimensions.width,
            height: data.primary.image_link.dimensions.height
        };
    }
    console.log('module type 2', data);

    return (
        <div className={`caseStudyPageContainer module-2`} id={`section-${key}`}>
            <div className="container-fluid container">
                <div className="row mt-5 mb-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-12 col-lg-5 ela">
                                <h3>
                                    {data.primary.description_module_2}
                                </h3>
                                {
                                    data.primary.small_text != null ?
                                        data.primary.small_text.length > 0 ?
                                            (
                                                typeof data.primary.small_text == 'string' ?
                                                    (
                                                        <p className="poppins-regular">{data.primary.small_text}</p>
                                                    ) : (
                                                        data.primary.small_text.map((text, index) => <p key={index} className="poppins-regular">{text.text}</p>)
                                                    )
                                            ) : ('')
                                        : ''
                                }
                            </div>

                            <div className="col-12 col-lg-7 tablet-img-full img-full">
                                { typeof data.primary.image_link.url != 'undefined' ?
                                    (
                                        <img src={data.primary.image_link.url} style={{width: '100%', height: 'auto' }}/>
                                    ) : ('')
                                }

                                { data.primary.video_id != null ?
                                    (
                                        data.primary.video_source == 'Youtube' ?
                                            (
                                                <iframe width="100%" height="315" src={`https://www.youtube.com/embed/` + data.primary.video_id} title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen></iframe>
                                            ) : (
                                                data.primary.video_source == 'Vimeo' ?
                                                    (
                                                        <iframe src={`https://player.vimeo.com/video/` + data.primary.video_id}
                                                                width="640" height="360" frameBorder="0"
                                                                allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    ) : (
                                                        <video width="100%" height="315" controls>
                                                            <source src={data.primary.video_embed_link} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                        </video>
                                                    )
                                            )
                                    ) : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col-12 col-lg-5  offset-lg-6">
                        <div className="row mt-mobile-5">
                            {data.items.map((item, index) => {
                                return (
                                    <div key={`module-2-results-numbers-` + index} className="col-12 col-md-4 flex-mobile">
                                        <h3 className="percent-label mb-3">{item.number_value}</h3>
                                        <p className="poppins-regular ">{item.number_description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType2;