import React from "react";
import loadable from '@loadable/component'

import Block1 from "./Block1";
const Block2 = loadable(() => import('./Block2'));
// import SectionOne from "./SectionOne";
// import SectionTwo from "./SectionTwo";
// import SectionTwoSec from "./SectionTwoSec";
// import SectionThree from "./SectionThree";
// import SectionCase1 from "./SectionCase1";
// import SectionCase2 from "./SectionCase2";
// import SectionCase3 from "./SectionCase3";
// import SectionKunden from "./SectionKunden";
// import SectionArbeiten from "./SectionArbeiten";
// import SectionVideos from "./SectionVideos";
// import SectionCircles from "./SectionCircles";
// import SectionValueStrategy from "./SectionValuesStrategy";
// import SectionValuesUX from "./SectionValueUX";
// import SectionValueContent from "./SectionValueContent";
// import SectionValueMedia from "./SectionValueMedia";
// import SectionValueTech from "./SectionValueTech";
// import SectionAgenturen from "./SectionAgenturen";
// import SectionAgenturenSlide from "./SectionAgenturenSlide";
// import SectionJobs from "./SectionJobs";
// import SectionTeam from "./SectionTeam";
// import SectionTeamSlider from "./SectionTeamSlider";
// import SectionNews from "./SectionNews";
// import SectionNewsSlider from "./SectionNewsSlider";
// import SectionKontakt from "./SectionKontakt";
// import SectionFormFunction from "./SectionFormFunction";
// import SectionFinalHeadquarter from "./SectionFinalHeadquarter";






function SectionsDesktopHolder() {

    return (
        <div>
            <Block1/>
            <Block2/>
        </div>

    );
}

export default SectionsDesktopHolder;
