import React from 'react';
import '../style/ImageHalfTwoDesc.scss';
import { RichText } from 'prismic-reactjs';

function ImageTextHalfwithtwodesc(props) {
  const data = props.data;
console.log(data);
  const returnContent = () => {
   
      return (
        <>
          <div className="col-12 col-lg-5 offset-lg-1">
            <img className="w-100" src={data.primary.img.url} alt="" />
          </div>
          < div className = "col-12 col-lg-5 d-flex flex-column  mobile-top" >
            <div>
              <h4 className = "custom-heading-style-20" > {
                data.primary.title
                } </h4>
              <div className="margin-bottomDefault opacity64">
                {RichText.render(data.primary.description1)
                  ? RichText.render(data.primary.description1)
                  : ''}
              </div>
             
              
               {data.primary.link1.url ? (
                <a
                  href={data.primary.link1.url}
                  className="btn--regular btn--primary--solid d-inline-block"
                  rel="noreferrer"
                  download
                >
                    {
                      data.primary.button_1_text
                    }
                </a>
              ) : (
                ''
              )}
                <h4 className = "custom-heading-style-20 pt-20" > {
                 data.primary.title2
                } </h4>
               <div className = "margin-bottomDefault opacity64" > {
                   RichText.render(data.primary.description2) ?
                   RichText.render(data.primary.description2) :
                     ''
                 } </div>
              {
                data.primary.link2.url ? (
                < a download
                  href={data.primary.link2.url}
                  target="_blank"
                  className="btn--regular btn--primary--solid d-inline-block"
                  rel="noreferrer"
                >
                 {
                   data.primary.button_2_text
                 }
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      );
    }


  return (
    < section className = {
      `section6Arbeiten padding-topFull-bottomHalf_1 ${data.primary.bg_black ?  'bg-black' :'bg-white'}`
    }
      
    >
      <div className="container">
        <div className="row align-items-center">{returnContent()}</div>
      </div>
    </section>
  );
}

export default ImageTextHalfwithtwodesc;
