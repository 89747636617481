import React, { useState, useEffect } from "react";
import '../../components/style/jobsContainer.scss'
import { slice, concat, } from 'lodash';
import arrowImage from "../../components/images/ic_arrow_down_white_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import shape3img from "../../components/images/shapes/new/shape09-min.jpeg";
import shape5img from "../../components/images/shapes/new/shape07-min.jpeg";
import shape10img from "../../components/images/shapes/new/shape13-min.jpeg";
import axios from 'axios';

import blueSummit from "../../components/images/logos/agencies/ic_logo_agency_bluesummit.svg"
import { useTranslation } from "react-i18next";
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Client } from "../../utilities/prismicHelpers";
import JobItem from "../jobs/jobItem";
import { Anchor } from "../blocks/anchor";
import SectionValueStrategy from "../SectionValuesStrategy";

function JobsPageContainer() {
    const Loading = () =>
        <div style={{ textAlign: 'center' }}>
            Loading...
        </div>
    const [Jobdata, setJobData] = useState({ jobPosts: null, jobPostsCopy: null });
    const [jobWorkExpData, setWorkExp] = useState({ jobExp: null });
    const [jobsUnTouch, setjobsUnTouch] = useState({
        data: null
    });

    const [jobCatData, setJobCat] = useState({ jobCat: null });
    const [message, setMessageNote] = useState({ msg: false });
    const [showMore, setShowMore] = useState(true);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    var locChange = null;
    var catChange = null;
    var workChange = null;
    const [locSelected, setLocSelect] = useState({ opt: null });
    const [catSelected, setCatSelect] = useState({ opt: null });
    const [workSelected, setWorkSelect] = useState({ opt: null });
    const [lang, setLang] = useState('');
    const [notFound, toggleNotFound] = useState(false);
    gsap.registerPlugin(ScrollTrigger);
    var perPage = 12; //perPage
    var jobPostsAll = [];
    var categories = [];
    const { t, i18n } = useTranslation();
    let languageKey;

    if (i18n.language == 'en') {
        languageKey = 'en';
    }
    else {
        languageKey = 'de';
    }

    useEffect(()=> {
        window.emos3.send({
            content: 'Jobs page'
        });
    }, []);

    const pagging = (data, all, iteration = 0) => {
        if (iteration === 1) {
            setjobsUnTouch({ data: all })
        }
        //pagging
        if (data.length > 12) {
            setShowMore(true);
            setTotal(data.length);
            setPage(1);
            setJobData({ jobPosts: data.slice(0, perPage), jobPostsCopy: all });
            console.log(data.slice(0, perPage));
        }
        else if (data.length === 0) {
            setJobData({
                jobPosts: [],
                jobPostsCopy: all
            });
              
        }
        else {
        setJobData({
            jobPosts: data,
            jobPostsCopy: all
        });
            setShowMore(false);
        }
    }

    useEffect(() => {
        const fetchJobsData = async () => {
            axios.request({
                url: `https://api.softgarden.io/api/rest/v3/frontend/jobslist/76012_extern`,
                method: "get",
                auth: {
                    username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
                    password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
                }
            }).then(function (results) {
                setJobData({ jobPosts: results.data.results, jobPostsCopy: results.data.results });
                pagging(results.data.results, results.data.results, 1);

            });
        }
        const fetchWorkExp = async () => {
            axios.request({
                url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/WORK_EXPERIENCE?locale=${languageKey}`,
                method: "get",
                auth: {
                    username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
                    password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
                }
            }).then(function (results) {
                setWorkExp({ jobExp: results.data });
            });
        }
        const fetchCategories = async () => {
            axios.request({
                url: `https://api.softgarden.io/api/rest/v3/frontend/catalogs/JOB_CATEGORY?locale=${languageKey}`,
                method: "get",
                auth: {
                    username: "2a9aedf9-243d-43b2-a54f-1a867a635f16",
                    password: "2a9aedf9-243d-43b2-a54f-1a867a635f16"
                }
            }).then(function (results) {
                setJobCat({ jobCat: results.data });

            });
        }
        fetchWorkExp();
        fetchCategories();
        fetchJobsData();

    }, []);
    const loadMore = () => {
        var p = page + 1;
        var copy = jobsUnTouch.data;
        var all = jobsUnTouch.data;
        if (workSelected.opt != null) {
            all = all.filter(data => data.workExperiences[0] === workSelected.opt)
        }
        if (catSelected.opt != null) {
            all = all.filter(data => data.jobCategories[0] === catSelected.opt)
        }
        if (locSelected.opt != null) {
            all = all.filter(data => data.geo_city === locSelected.opt)
        }

        setPage(p);
        var start = p * perPage;
        var d = start + perPage;
        var totals = total - 1;
        if (total - d >= 0) {
            setJobData({ jobPosts: [...Jobdata.jobPosts, ...all.slice(start - perPage, d - 1)], jobPostsCopy: copy });
        }
        else {
            var start = p * perPage;
            setJobData({ jobPosts: [...Jobdata.jobPosts, ...all.slice(start - perPage, total)], jobPostsCopy: copy });
            setShowMore(false);
        }
    }
    const [contacts, setContacts] = React.useState([{
        "id": "1",
        "company": "delasocial",
        "title": "Texter:in/ Kreativ-Konzepter:in (all genders)",
        "location": "Hamburg, Munchen",
        "link": "https://pia.me/de/news/meta-namedescription-contentpia-group-bleibt-auf-platz-zwei-der-top-digitalagenturen"
    }, {
        "id": "2",
        "company": "bluesummit",
        "title": "Texter:in/ Kreativ-Konzepter:in (all genders)",
        "location": "Hamburg, Munchen",
        "link": "https://pia.me/de/news/meta-namedescription-contentpia-group-bleibt-auf-platz-zwei-der-top-digitalagenturen"
    }, {
        "id": "3",
        "company": "bluesummit",
        "title": "Texter:in/ Kreativ-Konzepter:in (all genders)",
        "location": "Hamburg, Munchen",
        "link": "https://pia.me/de/news/meta-namedescription-contentpia-group-bleibt-auf-platz-zwei-der-top-digitalagenturen"
    }]);
    const [levels, setLevels] = React.useState(["All", "Junior", "Mid", "Expert"]);
    const [locations] = React.useState(["All", "Hamburg", "München", "Herrenberg", "Mainz", "Ludwigsburg", "Berlin", "Köln", "Remote", "Stuttgart", "Barcelona", , "Bukarest"]);

    const [categorySelected, setSelected] = React.useState("All");



    const handleChange = e => {
        setSelected(e.target.value)
    }
    const ChangeLocationHelper = (data) => {
        var jobPostsAll = data;
        if (workSelected.opt != null) {
            jobPostsAll = jobPostsAll.filter(data => data.workExperiences[0] === workSelected.opt)
        }
        if (catSelected.opt != null) {
            jobPostsAll = jobPostsAll.filter(data => data.jobCategories[0] === catSelected.opt)
        }
        return jobPostsAll;
    }

    const ChangeWorkExpHelper = (data) => {
        var jobPostsAll = data;
        if (catSelected.opt != null) {
            // alert('one');
            jobPostsAll = jobPostsAll.filter(data => data.jobCategories[0] === catSelected.opt)
        }
        if (locSelected.opt != null) {
            // alert("two");
            jobPostsAll = jobPostsAll.filter(data => data.geo_city === locSelected.opt)
        }

        return jobPostsAll;
    }


    //Handle Location Filter
    const handleChangeLocation = e => {
        setSelected(e.target.value)
        // alert(e.target.value)
        if (e.target.value == 'All') {
            jobPostsAll = Jobdata.jobPostsCopy;
            setLocSelect({ opt: null });
            setMessageNote({
                msg: false
            });
        }
        else {
            setLocSelect({ opt: e.target.value });
            jobPostsAll = Jobdata.jobPostsCopy.filter(data => data.geo_city === e.target.value)
            jobPostsAll = ChangeLocationHelper(jobPostsAll);
            // alert(jobPostsAll.length);
            if (jobPostsAll.length <= 0) {
                setMessageNote({ msg: true });
                setShowMore(false);
            }
            else {
                setMessageNote({ msg: false });
            }
        }
        console.log(jobPostsAll);
        //setJobData({ jobPosts: jobPostsAll, jobPostsCopy: Jobdata.jobPostsCopy });
        pagging(jobPostsAll, jobsUnTouch.data);


    }
    const ChangeCategoryHelper = (data) => {
        var jobPostsAll = data;
        if (workSelected.opt != null) {
            jobPostsAll = jobPostsAll.filter(data => data.workExperiences[0] === workSelected.opt)
        }
        if (locSelected.opt != null) {
            jobPostsAll = jobPostsAll.filter(data => data.geo_city === locSelected.opt)
        }
        return jobPostsAll;
    }

    const handleChangeCategory = e => {
        setSelected(e.target.value)
        if (e.target.value == 'All') {
            jobPostsAll = Jobdata.jobPostsCopy;
            jobPostsAll = ChangeCategoryHelper(jobPostsAll);
            setCatSelect({ opt: null });
            setMessageNote({
                msg: false
            });


        }
        else {
            setCatSelect({ opt: e.target.value });
            jobPostsAll = Jobdata.jobPostsCopy.filter(data => data.jobCategories[0] === e.target.value)
            jobPostsAll = ChangeCategoryHelper(jobPostsAll);
            if (jobPostsAll.length <= 0) {
                setMessageNote({ msg: true });
                setShowMore(false);
            }
            else {
                setMessageNote({ msg: false });
            }
        }

        pagging(jobPostsAll, jobsUnTouch.data);

    }

    const handleChangeWorkExp = e => {
        setSelected(e.target.value)
        if (e.target.value == 'All') {
            jobPostsAll = Jobdata.jobPostsCopy
            jobPostsAll = ChangeWorkExpHelper(jobPostsAll);
            setWorkSelect({ opt: null });
            setMessageNote({
                msg: false
            });

        }
        else {
            setWorkSelect({ opt: e.target.value });
            jobPostsAll = Jobdata.jobPostsCopy.filter(data => data.workExperiences[0] === e.target.value)
            console.log(Jobdata.jobPostsCopy);
            jobPostsAll = ChangeWorkExpHelper(jobPostsAll);
            if (jobPostsAll.length <= 0) {
                setMessageNote({ msg: true });
                setShowMore(false);

            }
            else {
                setMessageNote({ msg: false });
            }


        }
        console.log(jobsUnTouch.data)
        pagging(jobPostsAll, jobsUnTouch.data);

        console.log(jobPostsAll);

    }
    useEffect(() => {


        if (window.location.href.indexOf('jobs') > 0) {
            document.querySelector("header").classList.add("inverted");
            document.querySelector("footer").classList.add("darkVariant");
        }

    });

    const url = window.location.pathname.split('/').pop();
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('jobs') > 0) {
            document.querySelector(".jobs-link-menu").classList.add("active");
        }

    }, [url]);

    // To filter Data by Lang
    useEffect(() => {

        // if (i18n.language=='en') {
        //     console.log(i18n.language);
        //     if(prismicData.jobPostsCopy){
        //         console.log(prismicData.jobPostsCopy)
        //    jobPostsAll=prismicData.jobPostsCopy.filter(data=>data.lang === 'en-us')
        //     setPrismicData({jobPosts: jobPostsAll,jobPostsCopy:prismicData.jobPostsCopy });
        //     }
        // }
        // else{
        //     if(prismicData.jobPostsCopy){
        //    jobPostsAll=prismicData.jobPostsCopy.filter(data=>data.lang === 'de-de')
        //   setPrismicData({jobPosts: jobPostsAll,jobPostsCopy:prismicData.jobPostsCopy });
        //     }
        // }

    }, [i18n.language]);


    var workExp = [];
    let filteredContacts = contacts;
    if (categorySelected !== "All") {
        filteredContacts = contacts.filter(contact => contact.category == categorySelected);
    }
    if (Jobdata.jobPosts) {
        jobPostsAll = Jobdata.jobPosts;
        console.log(Jobdata.jobPosts);
    }
    if (jobCatData.jobCat) {
        categories = jobCatData.jobCat;
    }
    if (jobWorkExpData.jobExp) {
        workExp = jobWorkExpData.jobExp;
    }


    return (
        <div className="jobsPageContainer">
            <div className="sectionTopJobs">
                <div className="animationJobsRight">
                    <img src={shape3img} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className="container" style={{ zIndex: '3', position: 'relative' }}>
                    <div className="row">
                        <div className="col-12 offset-lg-1 col-lg-11">
                            <h1 className="PageTitle">Jobs</h1>
                        </div>
                        <div className="col-12 offset-lg-1 col-lg-11">
                            <div className="PageDescription">{t('jobsPageDescription')}</div>
                        </div>
                    </div>
                    <div className="row filtersContainerJobs">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4">
                                    <label htmlFor="">{t('Location')}</label>
                                    <select
                                        onChange={e => handleChangeLocation(e)} style={{ backgroundImage: `url(${arrowImage})` }}>
                                        {locations.map(category =>
                                            <option key={category} value={category}>{category}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <label htmlFor="">{t('Category')}</label>
                                    <select
                                        onChange={e => handleChangeCategory(e)} style={{ backgroundImage: `url(${arrowImage})` }}>
                                        <option value="All">All</option>
                                        {Object.entries(categories).map(([key, value]) => {
                                            return (<option value={key} key={key} >{value}</option>)
                                        })}

                                    </select>

                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <label htmlFor="">{t('Experience')}</label>
                                    <select
                                        onChange={e => handleChangeWorkExp(e)} style={{ backgroundImage: `url(${arrowImage})` }}>
                                        <option value="All">All</option>
                                        {Object.entries(workExp).map(([key, value]) => {
                                            return (<option value={key} key={key} >{value}</option>)
                                        })}

                                    </select>

                                </div>

                                {/*<div className="col-12 col-md-4 col-lg-12">*/}
                                {/*    <label htmlFor="">Job Level</label>*/}
                                {/*    <select*/}
                                {/*        onChange={e => handleChange(e)} style={{backgroundImage: `url(${arrowImage})`}}>*/}
                                {/*        {levels.map(category =>*/}
                                {/*            <option key={category} value={category}>{category}</option>*/}
                                {/*        )}*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-12 col-md-12  col-lg-12">
                            <div className="row">
                                {message.msg ?
                                    <h5 style={{ fontSize: '20px', textAlign: "center" }} >{t('NoJob')}</h5>
                                    : null}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId} />
                                ))}
                                {/* {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))}
                                {jobPostsAll.map((job, index) => (
                                    <JobItem job={job} key={job.jobDbId}  />
                                ))} */}
                            </div>
                            <div className="row">
                                {showMore ?
                                    loading ? (<Loading />) :
                                        <button onClick={loadMore} className="btn--regular btn--inverted--solid showMoreBtn">{t('MoreArticlesButton')}</button>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="animationJobsLeft">
                    <img src={shape5img} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className="shapesJobsPageKontakttrigger"></div>
            </div>


            <div className="sectionKontakt">
                <div className="ImagejobsKontaktLeft">
                    <img src={shape10img} alt="" style={{ width: '100%', height: '100%' }} />
                </div>

                <div className="container" style={{ zIndex: '3', position: 'relative' }}>
                    <div className="row">
                        <div className="col-12 offset-md-1 col-md-9 col-lg-6 offset-lg-1">
                            <div className="KontaktTexts">
                                <div  className="title">{t('InternalHRTitle')}</div>
                                <div className="description">{t('InternalHRDescription')}</div><br /><br />
                                <Anchor children={t('SoftgardenTextCV')} anchorStyle="btn--inverted--solid" anchorSize="btn--regular" href={'https://short.sg/a/14147169'} target='_blank' />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="infoPersonHolder">
                    <img src={"/team/img_team1.avif"} alt="" />
                    <div className="userData">
                        <div className="teamsName">Christine Löw</div>
                        <div className="teamsPosition">Group Lead People & Culture</div>
                        <div className="d-none d-md-block">
                            <a href={"tel:+49 151 11 421529"} className="teamsPhone">+49 151 54679127</a>
                            <div className="d-flex deskSocialCont">

                                {/* <Anchor children={'jobs@pia.me'} anchorStyle="btn--inverted--solid" href={'mailto:jobs@pia.me'} /> */}


                            </div>
                        </div>
                        <div className="d-block d-md-none">
                            <div className="d-flex">
                                <a href={"tel:+49 176 62 427699"} className="iconMobileUser"><img src={iconPhone} alt="email" /></a>
                                <a href={"mailto:annika-sophie.butt@pia.me"} className="iconMobileUser"><img src={iconMail} alt="email" /></a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div className="container" style={{ zIndex: 3, position: 'relative' }}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-lg-4 offset-lg-1">
                                <div className="titleJobsBottom">{t('InternalAboutUs')}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 offset-lg-1">
                                <div className="descriptionJobsBottom1">
                                    <p>{t('InternalAboutText1')}</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="descriptionJobsBottom2">
                                    <p>{t('InternalAboutText2')}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="shapesJobsPagetrigger"></div>


        </div>


    );
}

export default JobsPageContainer;
