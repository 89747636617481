import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType3(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;

    console.log('module type 3', data);

    return (
        <div className={`caseStudyPageContainer module-3`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {
                            data.primary.title_module_3 != null ?
                            (
                                <h3>{data.primary.title_module_3}</h3>
                            ) : ('')
                        }

                        {
                            typeof data.primary.description_module_3[0] != 'undefined' ?
                            (
                                <p className={`poppins-regular`}>
                                    {data.primary.description_module_3[0].text}
                                </p>
                            ) : ('')
                        }

                        {
                            typeof data.primary.description_module_3_rich[0] != 'undefined' ?
                                (
                                    <p className={`poppins-regular`}>
                                        {data.primary.description_module_3_rich[0].text}
                                    </p>
                                ) : ('')
                        }

                        {
                            data.items.length > 0 ?
                            (
                                <ul>
                                    {data.items.map((item, index) => {
                                        return (
                                            <li key={index} className={`poppins-regular`}>
                                                {item.list_item}
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : ('')
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType3;