import React, { useEffect, useState } from 'react';
import '../style/ImageTextHalf.scss';
import { RichText } from 'prismic-reactjs';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import "../style/B2B.scss";
import "../style/careousel.scss";

// function ImageTextHalf(props) {
//   const data = props.data;

//   const returnContent = () => {
//     if (data.primary.reverse) {
//       return (
//         <>
//           <div className="col-12 col-lg-5 offset-lg-1 d-flex flex-column justify-content-center">
//             <div>
//               <h3>{data.primary.title}</h3>
//               <div className="margin-bottomDefault opacity64">
//                 {RichText.render(data.primary.description)
//                   ? RichText.render(data.primary.description)
//                   : ''}
//               </div>
//               {data.primary.link.url ? (
//                 <a
//                   href={data.primary.link.url}
//                   target="_blank"
//                   className="btn--regular btn--primary--solid d-inline-block"
//                   rel="noreferrer"
//                 >
//                   show More
//                 </a>
//               ) : (
//                 ''
//               )}
//             </div>
//           </div>
//           <div className="col-12 col-lg-5">
//             <img className="w-100" src={data.primary.image.url} alt="" />
//           </div>
//         </>
//       );
//     } else {
//       return (
//         <>
//           <div className="col-12 col-lg-5 offset-lg-1">
//             <img className="w-100" src={data.primary.image.url} alt="" />
//           </div>
//           <div className="col-12 col-lg-5 d-flex flex-column justify-content-center">
//             <div>
//               < h2 className="custom-heading-style" >  {
//                   RichText.render(data.primary.title)
//                 } </h2>
//               <div className="margin-bottomDefault opacity64">
//                 {RichText.render(data.primary.description)
//                   ? RichText.render(data.primary.description)
//                   : ''}
//               </div>
//               {data.primary.link.url ? (
//                 <a
//                   href={data.primary.link.url}
//                   target="_blank"
//                   className="btn--regular btn--primary--solid d-inline-block"
//                   rel="noreferrer"
//                 >
//                   show More
//                 </a>
//               ) : (
//                 ''
//               )}
//             </div>
//           </div>
//         </>
//       );
//     }
//   };

//   return (
//     <section className="section6Arbeiten padding-topFull-bottomHalf">
//       <div className="container">
//         <div className="row">{returnContent()}</div>
//       </div>
//     </section>
//   );
// }

//export default ImageTextHalf;
function parseCodes(textWithCodes) {
    const splitText = textWithCodes.split('[br]').map((text, index) => (
        <React.Fragment key={index}>
            {text}
            {index !== textWithCodes.length - 1 && <br />} {/* Add <br /> except for the last element */}
        </React.Fragment>
    ));

    return (
        <React.Fragment>
            {splitText}
        </React.Fragment>
    );
}

function buildHtml(entity, customClasses) {
    let customClassesStr = '';

    if (!Array.isArray(entity) || entity.length <= 0) {
        return null;
    }

    if (Array.isArray(customClasses) && customClasses.length > 0) {
        customClassesStr = customClasses.join(' ');
    }

    return entity.map((element, index) => {
        let text = parseCodes(element.text);

        if (typeof element.type === 'string') {
            switch (element.type) {
                case 'heading1':
                    return <h1 className={customClassesStr} key={index}>{text}</h1>;

                case 'heading2':
                    return <h2 className={customClassesStr} key={index}>{text}</h2>;

                case 'heading5':
                    return <h5 className={customClassesStr} key={index}>{text}</h5>;

                case 'heading6':
                    return <h6 className={customClassesStr} key={index}>{text}</h6>;

                // By default, treat as paragraph
                default:
                    return <p className={customClassesStr} key={index}>{text}</p>;
            }
        }
        return null;
    });
}
function CarouselContainer(props) {

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
        const handleClick = (link) => {
            if(link.url){
            window.open(link.url, '_blank');}
        };
    
    const content = props.data;
    return (
      < section className = 'bg-dark1' >
          <div className='container '>
        < div className = {
          `carousel-container  left-right-padd carousel-module `
        } >
           

            < div className = {
              
               ` col-lg-12 offset-lg-1`
            } >
            <div class="swiper-controls">
               
                    {
                        content.primary.main_title?
                        buildHtml(content.primary.main_title, ['f-36']):''
                    }
         <div className="swiper-button-prev" ref={navigationPrevRef}></div>
          <div className="swiper-button-next" ref={navigationNextRef}></div>
              
            </div>
            <Swiper
               loop={true}
                            observer={true}
                            freeMode={false}
                            grabCursor={false}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 32
                                },
                                768: {
                                    spaceBetween: 32,
                                    slidesPerView: 3,
                                },
                            }}
                            observeParents = {
                                true
                            }
                            navigation={{
                               prevEl: '.swiper-button-prev',
                                   nextEl: '.swiper-button-next',
                            }}
                            speed={1000}
                            autoplay={false}
                            spaceBetween={32}
                            slidesPerColumn={1}
                            slidesPerView = {
                             3
                            }
            >
                  
               {
                    content.items.map((item, index) => {
                        return (
                            <SwiperSlide  key={`presentation-slider-${props.name}-${index}`}>
                                <img  onClick={() => handleClick(item.link)} style={{ maxWidth: '408px',cursor:'pointer' }}class="carousel-slider-image" src={item.item_image.url} />
                            {
                            < span style = {
                                {
                                    cursor: 'pointer'
                                }
                            }
                            class = "break-line t-16 carousel-tagline text-white"
                            onClick = {
                                    () => handleClick(item.link)
                                } > {
                        item.item_text?
                        buildHtml(item.item_text, ['f-17']): ''
                    }</span>}
                               
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div> </div></div></section>
    )
}

export default CarouselContainer;