import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";
import apos from "../../images/apos.svg";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType6(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;

    let imageUrl = typeof data.primary.image_module_6.url != 'undefined' ? data.primary.image_module_6.url : '';

    console.log('module type 6', data);

    return (
        <div className={`caseStudyPageContainer module-6`} id={`section-${key}`}>
            <div className="container-fluid container">
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <div className="row">
                            {
                                data.primary.istextonleft ?
                                    (
                                        <div className="col-12 col-lg-4">
                                            <h3>{data.primary.title_module_6}</h3>
                                            <p className="poppins-regular">{data.primary.description_module_6}</p>
                                        </div>
                                    ) : ('')
                            }

                            <div className="col-12 col-md-12 col-lg-8">
                                <div className="row">
                                    <div className={`col-lg-12 tablet-img-full img-full`}>
                                        <img src={data.primary.image_module_6.url} style={{width: '100%', height: 'auto'}}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={`col-12 col-lg-11 offset-lg-1 mt-3`}>
                                        <p className="poppins-regular">{ data.primary.image_caption_module_6 }</p>
                                    </div>
                                </div>
                            </div>

                            {
                                !data.primary.istextonleft ?
                                    (
                                        <div className="col-12 col-lg-4">
                                            <h3>{data.primary.title_module_6}</h3>
                                            <p className="poppins-regular">{data.primary.description_module_6}</p>
                                        </div>
                                    ) : ('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className={`wollen-img imgbg-quote`} style={{ backgroundImage: 'url('+imageUrl+')' }}>
        //     <div className="text-over-bg2">
        //         {
        //             data.primary.image_caption_module_6 != null ?
        //             (
        //                 <CustomView>
        //                     <img src={apos} />
        //                     <h3>{data.primary.image_caption_module_6}</h3>
        //                 </CustomView>
        //             ) : ('')
        //         }
        //
        //         {
        //             data.primary.title_module_6 != null ?
        //             (
        //                 <p className={`bold mt-5 mb-0 f-28 f-w-600`}>{data.primary.title_module_6}</p>
        //             ) : ('')
        //         }
        //
        //         {
        //             data.primary.description_module_6 != null ?
        //             (
        //                 <p className={`f-16`}>{data.primary.description_module_6}</p>
        //             ) : ('')
        //         }
        //     </div>
        // </div>
    );
}

export default ModuleType6;