import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './App.scss';
import './Fonts.scss';
import FooterDark from './components/FooterDark';
import HeaderDesktop from './components/HeaderDesktop';
import HeaderMobile from './components/HeaderMobile';
import ScrollToTop from './components/ScrollToTop';
import Sustainability from './components/pages/Sustainability';
import './components/style/responsive/style.scss';
import './i18n/config';
import Compliance from './components/pages/Compliance';
import {
  useLocation
} from "react-router-dom"

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

const HeaderComponent = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};

function Page(props) {
  const { width } = useViewport();
  const page = props.page;

    return <Compliance width = {
      width}/>;
 
}

const ResponsibilityComplaince = (props) => {
  const { t } = useTranslation();

  const href = window.location.href;
  const page = props.page;
 const location = useLocation()
 const params = new URLSearchParams(location.search)
 
  return (
    <div className="App">
      <Helmet>
        <title>{t('homepage-metaTitle')}</title>
        <meta name="description" content="{t('homepage-metaDescription')}" />
        <link rel="canonical" href={href} />
      </Helmet>
      <ViewportProvider>
        <HeaderComponent />
        <div id="postHeader"></div>
        < Page/>
      </ViewportProvider>
      <FooterDark />
      <div className="inverted">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default ResponsibilityComplaince;
