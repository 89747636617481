import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: 'customDetector',
    lng: window.location.href.split("/"),
});

i18n.use(LanguageDetector).use(initReactI18next).init({
    //lng: 'de',
    fallbackLng: 'en',
    resources: {
        de: {
            translations: require('./locales/de/translations.json')
        },
        en: {
            translations: require('./locales/en/translations.json')
        }

    },
    detection: {
        order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator'],
        lookupQuerystring: 'lng'
    },

    // cache user language on
    caches: ['localStorage', 'cookie'],


    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['de', 'en'];

export default i18n;