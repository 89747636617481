import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType8(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;

    console.log('module type 8', data);

    return (
        <div className={`caseStudyPageContainer module-8`}>
            <div className={`container`}>
                <div className={`row`}>
                    <div className={`col-12`}>
                        {
                            data.primary.title_module_8 != null ?
                                (
                                    data.primary.title_module_8.length > 0 ?
                                        (
                                            data.primary.title_module_8.map((text,index) => {
                                                return (
                                                    <h3 key={index}>{text.text}</h3>
                                                )
                                            })
                                        ) : ('')
                                ) : ('')
                        }

                        {
                            data.items.length > 0 ?
                                (
                                    <ul>
                                        {
                                            data.items.map((item, index) => {
                                                return (
                                                    <li key={index} className={`poppins-regular`}>{item.list_item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : ('')
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType8;