import React from 'react';
import '../style/ImageTextHalf.scss';
import { RichText } from 'prismic-reactjs';

function ImageTextHalf(props) {
  const data = props.data;

  const returnContent = () => {
    if (data.primary.reverse) {
      return (
        <>
          <div className="col-12 col-lg-5 offset-lg-1 d-flex flex-column ">
            <div>
                 < h2 className="custom-heading-style" >  {
                  RichText.render(data.primary.title)
                } </h2>
              <div className="margin-bottomDefault opacity64">
                {RichText.render(data.primary.description)
                  ? RichText.render(data.primary.description)
                  : ''}
              </div>
              {data.primary.link.url ? (
                <a
                  href={data.primary.link.url}
                  target="_blank"
                  className="btn--regular btn--primary--solid d-inline-block mb-md-1"
                  rel="noreferrer"
                >
                  {
                    data.primary.link_text
                  }
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <img className="w-100" src={data.primary.image.url} alt="" />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-12 col-lg-5 offset-lg-1">
            <img className="w-100" src={data.primary.image.url} alt="" />
          </div>
          <div className="col-12 col-lg-5 d-flex flex-column ">
            <div>
              < h2 className="custom-heading-style" >  {
                  RichText.render(data.primary.title)
                } </h2>
              <div className="margin-bottomDefault opacity64">
                {RichText.render(data.primary.description)
                  ? RichText.render(data.primary.description)
                  : ''}
              </div>
              {data.primary.link.url ? (
                <a
                  href={data.primary.link.url}
                  target="_blank"
                  className="btn--regular btn--primary--solid d-inline-block"
                  rel="noreferrer"
                >
                  {
                    data.primary.link_text
                  }
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <section className="section6Arbeiten padding-topFull-bottomHalf">
      <div className="container">
        <div className="row align-items-center">{returnContent()}</div>
      </div>
    </section>
  );
}

export default ImageTextHalf;
