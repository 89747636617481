import React from "react";
import loadable from '@loadable/component';

import SectionOneMobile from "../mobile/SectionOneMobile/SectionOneMobile";
const SectionTwoMobile = loadable(() => import('../mobile/SectionTwoMobile/SectionTwoMobile'));
const SectionThreeMobile = loadable(() => import('../mobile/SectionThreeMobile/SectionThreeMobile'));


function Block1() {

    return (
        <div>
            <SectionOneMobile/>
            <SectionTwoMobile/>
            <SectionThreeMobile/>
        </div>

    );
}

export default Block1;
