import React, {useState, useEffect, Fragment} from "react";
import '../../components/style/newsArticle.scss'
import arrowImage from "../../components/images/ic_arrow_down_white_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import shape3img from "../../components/images/shapes/shape_03.jpg";
import iconLinkedIn from "../../components/images/ic_social_linkedin_white.svg";
import iconXing from "../../components/images/ic_social_xing_white.svg";
import iconTwitter from "../../components/images/ic_social_twitter_white.svg";

import { RichText, LinkResolver } from "prismic-reactjs";

import {Anchor} from "../blocks/anchor";
import NotFound from "../../NotFound";
import {useTranslation} from "react-i18next";

import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";



const CaseItemPageContainer = ( data ) => {
    gsap.registerPlugin(ScrollTrigger);
    const url = window.location.pathname.split('/').pop();
    useEffect(() => {

        console.log(`You changed the page to: ${url}`)
        if (window.location.href.indexOf('news') > 0) {
            document.querySelector(".news-link-menu").classList.add("active");
        }

    }, [url]);

    const {t, i18n} = useTranslation();

    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);


    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'en-us' }
            )
            if (response) {
                setDocData(response.results[0]);
            }

        }
        fetchData()
    }, [])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'de-de' }
            )
            if (response) {
                setDocDataDe(response.results[0]);
            }

        }
        fetchData()
    }, [])


    console.log(data)
    const title =
        data.data.title.length !== 0 ?
            RichText.asText(data.data.title) :
            'Untitled';


    // const content =
    //     data.data.content.length !== 0 ?
    //         RichText.asText(data.data.body[1].primary.content) :
    //         'Untitled';
    return (

        <div className="newsContainer newsArticle">




        </div>


    );
}
export default CaseItemPageContainer;
