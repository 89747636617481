import React from "react";
import loadable from '@loadable/component';

import SectionOne from "./SectionOne";
// import SectionOneTest from "./SectionOneTest";

const SectionTwo = loadable(() => import('./SectionTwo'));
const SectionTwoSec = loadable(() => import('./SectionTwoSec'));
const SectionTwoInter = loadable(() => import('./SectionTwoInter'));
const SectionTwoLast = loadable(() => import('./SectionTwoLast'));

function Block1() {

    return (
        <div>

            <SectionOne/>
            {/*<SectionOneTest/>*/}
            <SectionTwo/>
            <SectionTwoInter/>
            <SectionTwoSec/>
            {/*<SectionTwoLast/>*/}
        </div>

    );
}

export default Block1;
