import logo from './logoLight.svg';
import logoDN from '../logo1.svg';
import './style/Footer.scss';
import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, animateScroll as scroll } from "react-scroll";
import linkedin from './images/ic_social_linkedin_white.svg';
import xing from './images/ic_social_xing_white.svg';
import youtube from './images/ic_social_youtube_white.svg';
import {scroller} from "react-scroll";
import { NavHashLink } from 'react-router-hash-link';
import {debounce} from "../utilities/helpers";


function FooterDark() {
    const { t, i18n } = useTranslation();

    const footerRef = useRef(null);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;

        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 20) || currentScrollPos < 79);

        setPrevScrollPos(currentScrollPos);
    }, 100);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);

    }, [prevScrollPos, visible, handleScroll]);

    let languageKey;
    let contactLink;
    let newsLink;
    let jobsLink;
    let imprintLink;

    if (i18n.language=='en') {
        languageKey = '/en/home';

        contactLink = "/en/contact";
        newsLink = "/en/news";
        jobsLink = "/en/jobs";
        imprintLink = "/en/imprint";
    } else {

        languageKey = '/de/home'
        contactLink = "/de/contact";
        newsLink = "/de/news";
        jobsLink = "/de/jobs";
        imprintLink = "/de/imprint";
    }
    const today = new Date();
    return (
        <footer ref={footerRef} className="darkVariant">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-2">
                        <img className="logo invert-1" src={logoDN} alt=""/>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Plattform</div>
                        <ul className="footerSectionList">
                            <li><a href="https://www.dymatrix.de/loesungen/customer-data-experience-platform" target="_blank">CXP</a></li>
                            <li><a href="https://www.dymatrix.de/loesungen/customer-data-platform" target="_blank">Customer Data Platform</a></li>
                            <li><a href="https://www.dymatrix.de/loesungen/marketing-automation" target="_blank">Marketing Automation</a></li>
                            <li><a href="https://www.dymatrix.de/loesungen/customer-insights" target="_blank">Customer Insights</a></li>
                            <li><a href="https://www.dymatrix.de/loesungen/customer-prediction" target="_blank">Customer Prediction</a></li>
                            <li><a href="https://www.dymatrix.de/loesungen/customer-care" target="_blank">Customer Care</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Unternehmen</div>
                        <ul className="footerSectionList">
                            <li><a href="https://www.dymatrix.de/unternehmen" target="_blank">Über DYMATRIX</a></li>
                            <li><a href="https://www.dymatrix.de/referenzen" target="_blank">Unsere Referenzen</a></li>
                            <li><a href="https://www.dymatrix.de/success-stories" target="_blank">Success Stories</a></li>
                            <li><a href="https://www.dymatrix.de/karriere" target="_blank">Karriere</a></li>
                            <li><a href="https://www.dymatrix.de/presse" target="_blank">Presse</a></li>
                            <li><a href="https://www.dymatrix.de/kontakt" target="_blank">Kontakt</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Allgemeines</div>
                        <ul className="footerSectionList">
                            <li><a href="https://www.dymatrix.de/impressum" target="_blank">Impressum</a></li>
                            <li><a href="https://www.dymatrix.de/datenschutz" target="_blank">Datenschutz</a></li>
                            <li><a href="https://www.dymatrix.de/agbs" target="_blank">AGB</a></li>
                            <li><a href="https://pia.speakup.report/de-DE/pia/home" target="_blank">Whistleblower-System</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-lg-2">
                        <div className="footerSectionTitle">Social Media</div>
                        <div className="footerSectionSocialList d-flex">
                            <a href="https://www.linkedin.com/company/1231515/" target="_blank" rel="noreferrer"><img src={linkedin} alt=""/></a>
                            <a href="https://www.youtube.com/channel/UCWxhxGy48KbvD1qsei6bA0A" target="_blank" rel="noreferrer"><img src={youtube} alt=""/></a>
                        </div>


                    </div>
                    <div className="col-12 col-lg-2">
                        <div className="copy">&copy;{today.getFullYear()} DYMATRIX CONSULTING GROUP GmbH</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterDark;