import React from 'react';
import '../style/TextRightWithImageBg.scss';
import { RichText } from 'prismic-reactjs';

/**
 * @typedef {Object} TextTwoColProps
 * @property {Object} data - An object containing the data to be displayed.
 * @property {string} [data.pretitle] - optional
 * @property {string} [data.title] - optional
 * @property {string} [data.subtitle] - optional
 * @property {string} [data.text_left] - optional
 * @property {string} [data.text_right] - optional
 */

/**
 * A component that displays text in a two-column layout.
 * @param {TextRightWithImageBg} props - The props of the component.
 */
const TextRightWithImageBg = ({
    data
  }) => {
    console.log(data.primary.background_image.url)
  return (
    < section className = {
        `TextRightWithImageBg  padding-topFull-bottomHalf pb-8 ${data.primary.background_image.url ? ' bg_blackClass' : 'bg_whiteClass'}`
    }
     style = {
       {
        backgroundSize:'auto',
         backgroundImage: data.primary.background_image.url ? `url(${data.primary.background_image.url})` : ''
       }
     }
    >
      <div className="container">
        <div className="row margin-bottomBigger">
      
          <div className="col-12 col-lg-5 offset-lg-1">
            {/* <div className="opacity64 m-0">
              {RichText.render(data.primary.text_column_1)
                ? RichText.render(data.primary.text_column_1)
                : ''}
            </div> */}
          </div>
          <div className="col-12 col-lg-5">
            <div className=" m-0">
              < h2 className = "margin-bottomDefault" > {
                RichText.render(data.primary.title) ?
                RichText.render(data.primary.title):
                  ''
              } </h2>
              {RichText.render(data.primary.text)
                ? RichText.render(data.primary.text)
                : ''}
                 {
                data.primary.link.url ? (
                <a
                                  className = "mt-3 btn--regular btn--primary--solid d-inline-block "

                   style = {
                     {
                       color: data.primary.background_image.url ? 'white' : 'black',
                       border: data.primary.background_image.url ? '2px solid white' : 'black'
                     }
                   }
                  href={data.primary.link.url}
                  target="_blank"
                  rel="noreferrer"
                >
                 {
                   data.primary.link_text
                 }
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextRightWithImageBg;
