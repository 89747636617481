import React from 'react';
import '../../components/style/SectionForm.scss';
import validate from './validateInfo';
import useForm from './useForm';
import FormInput from "./form-input.component";
import arrowImage from "../images/ic_arrow_down_small.svg";
import {useTranslation} from "react-i18next";
import {Button} from "../blocks/button";

const FormContact = ({ submitForm }) => {
    const { handleChange, handleSubmit, values, errors } = useForm(
        submitForm,
        validate
    );
    const { t } = useTranslation();

    return (
        <div className="formContainer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="subtitle">{t('slideKontaktSubtitle')}</div>
                        <h2 className="title">{t('slideKontaktTitle')}</h2>
                    </div>
                </div>

            </div>
            <div className="container">
                <form id="contact-form" onSubmit={handleSubmit} method="POST" noValidate>
                    <div className="row">
                        <div className="col-12 col-md-4 offset-md-2">
                            <FormInput
                                name='name'
                                type='text'
                                onChange={handleChange}
                                value={values.name}
                                label={t('formName')}
                                error={errors.name}
                                required
                            />
                            <FormInput
                                name='forename'
                                type='text'
                                onChange={handleChange}
                                value={values.forename}
                                label={t('formForename')}
                                error={errors.forename}
                                required
                            />
                            <FormInput
                                name='firma'
                                type='text'
                                onChange={handleChange}
                                value={values.firma}
                                label={t('formFirma')}
                                error={errors.firma}
                                required
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <FormInput
                                name='email'
                                type='email'
                                onChange={handleChange}
                                value={values.email}
                                label={t('formEmail')}
                                error={errors.email}
                                required
                            />
                            <FormInput
                                name='phone'
                                type='tel'
                                onChange={handleChange}
                                value={values.phone}
                                label={t('formTelefon')}
                                error={errors.phone}
                                required
                            />
                            <div className="form-group">
                                <select name="contact" onChange={handleChange}
                                        value={values.contact} style={{backgroundImage: `url(${arrowImage})`}}>
                                    <option value="phoneContact" defaultValue>{t('formSelectPhone')}</option>
                                    <option value="emailContact">{t('formSelectEmail')}</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 offset-md-2">
                            <FormInput
                                name='message'
                                type='textarea'
                                onChange={handleChange}
                                value={values.message}
                                label={t('formMessage')}
                                error={errors.message}
                                required
                            />
                        </div>
                        <div className="col-12 col-md-4 offset-md-6">
                            <div className="privacyContainer" htmlFor="privacy">
                                <input type="checkbox" required className="form-check-input" id="privacy" name="privacy"
                                       value="1"/>
                                <span>{t('formCheckboxText')}</span></div>

                            <Button type={'submit'}>{t('formSendEmailButton')}</Button>
                        </div>


                    </div>


                </form>
            </div>
        </div>
    );
};

export default FormContact;