import React, { useState, useEffect } from 'react';
import { Client } from '../../utilities/prismicHelpers';
import i18n from '../../i18n/config';
import QuoteWithBg from '../reusable/QuoteWithBg';
import TextTwoCol from '../reusable/TextTwoCol';
import ImageTextHalf from '../reusable/ImageTextHalf';
import SimpleText from '../reusable/SimpleText';
import ImageTextHalfwithtwodesc from '../reusable/ImageTextHalfwithtwodesc';
import LeftHeadingRightText from '../reusable/LeftHeadingRightText';
import CarouselContainer from '../reusable/careouselContainer';
import TextTwoColSingleHeading from '../reusable/TextTwoColSingleHeading';
import Contact from '../reusable/contact';
import TextWithPointsOnRight from '../reusable/TextWithPointsOnRight';
import TextRightWithImageBg from '../reusable/TextRightWithImageBg';

function Compliance(props) {
  let width = props.width;
  const breakpoint = 768;
  const mobileBreakpoint = 576;
  const [docData, setDocData] = useState(null);
  const uid = 'responsibility-compliance';
   const bg=true;
  let languageKey;

  if (i18n.language === 'en') {
    languageKey = 'en-us';
  } else {
    languageKey = 'de-de';
  }

  useEffect(() => {
    const fetchPrismicData = async () => {
      const doc = await Client.getByUID('dynamic_page', uid, {
        lang: languageKey,
      });
      if (doc) {
        setDocData(doc);
      }
    };

    fetchPrismicData();
  });

  console.log(docData);

  if (docData) {
    docData.data.body.forEach((item, index) => {
      if (width <= mobileBreakpoint) {
        if (item.slice_type === 'module_type_5') {
          if (
            typeof docData.data.body[index].primary.image_mobile.url !=
            'undefined'
          ) {
            docData.data.body[index].primary.image =
              docData.data.body[index].primary.image_mobile;
          }
        }
      } else if (width <= breakpoint) {
        if (item.slice_type === 'module_type_5') {
          if (
            typeof docData.data.body[index].primary.image_tablet.url !=
            'undefined'
          ) {
            docData.data.body[index].primary.image =
              docData.data.body[index].primary.image_tablet;
          }
        }
      }
    });
  }

  return docData ? (
    <div>
      {docData.data.body.map((item, index) => {
        console.log(item.slice_type)
        if (item.slice_type === 'hidden_text_module' && index===0) {
          return (
            <TextTwoCol key={index} data={item} width={width}></TextTwoCol>
          );
        }

         if (item.slice_type === 'points_on_right' ) {
          return (
            <TextWithPointsOnRight key={index} data={item} width={width}></TextWithPointsOnRight>
          );
        }

           if (item.slice_type === 'image_text_half_with_two_desc') {
          return <ImageTextHalfwithtwodesc key={index} data={item}></ImageTextHalfwithtwodesc>;
        }

          if (item.slice_type === 'heading_text_btn_bg') {
          return <TextRightWithImageBg key={index} data={item}></TextRightWithImageBg>;
        }


        return null;
      })

      }
       < div className = {
         `col-12`
       } >
                             < Contact content = {
                               docData.data
                             }
                             />
</div>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default Compliance;
