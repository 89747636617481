import React, { useEffect, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import Prismic from "@prismicio/client";


import { Client } from '../../src/utilities/prismicHelpers';

/**
 * Blog post page component
 */
const Post = ({ match }) => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const uid = match.params.uid;

    // Get the blog post document from Prismic
    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await Client.query(
                    Prismic.Predicates.at('document.type', 'news_item')
                );

                if (doc) {
                    setPrismicDoc(doc);
                } else {
                    console.warn('Blog post document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);

    // Return the page if a document was retrieved from Prismic
    if (prismicDoc) {
        const title =
            prismicDoc.data.news_title.length !== 0 ?
                RichText.asText(prismicDoc.data.news_title) :
                'Untitled';

        return (
            <section wrapperClass="main" seoTitle={title}>
                <div className="outer-container">

                    <h1>{title}</h1>
                </div>

            </section>
        );
    } else if (notFound) {
        return <div>not found</div>;
    }
    return null;
}

export default Post;