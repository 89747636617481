import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import './style/ynwaPage.scss';
import ynwaVideoDesktop from './videos/ynwa-desktop.mp4';
import ynwaVideoMobile from './videos/ynwa-mobile.mp4'
import {CustomView, isDesktop, isMobile, isTablet} from "react-device-detect";
import { Link, animateScroll as scroll } from "react-scroll";
import FormSuccess from "./form/FormSuccess";

import FormEvent from "./form/FormEvent";
import FormContact from "./form/FormContact";
import {Client} from "../utilities/prismicHelpers";
import Prismic from "@prismicio/client";
import uuid from "react-tabs/lib/helpers/uuid";
import {RichText} from "prismic-reactjs";
import NotFound from "../NotFound";




function SectionYnwa() {

    const {t,i18n} = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const uid = 'ynwa';

    function submitForm() {
        setIsSubmitted(true);
    }
    let isGerman;
    let title;
    let languageKey;


    useEffect(()=> {
        window.emos3.send({
            content: 'ynwa event page'
        });
    }, []);


    if (i18n.language=='en') {
        languageKey = 'en-us';
        isGerman = false;
    }
    else {
        languageKey = 'de-de';
        isGerman = true;
    }
    // Get the blog post document from Prismic
    useEffect(() => {
        const fetchPrismicData = async () => {
            try {

                const doc = await Client.getByUID('event_page', 'ynwa' ,{lang:languageKey});

                if (doc) {
                    setPrismicDoc(doc);
                    console.log(doc);


                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);


    if (prismicDoc) {
        const title = prismicDoc.data.title[0].text;

        return (
            <div className="ynwapage">
                <div className="heading-container">
                    <CustomView condition={isMobile === true || isTablet === true}>
                        <div className="topVideoContainer d-block">
                            <video className="w-100" src={ynwaVideoMobile} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                            <Link
                                className="ynwabtntop btn--regular btn--inverted--solid"
                                activeClass="active"
                                to="eventForm"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            > Join Us!</Link>
                        </div>
                    </CustomView>
                    <CustomView condition={isMobile === false && isTablet === false}>
                        <div className="topVideoContainer d-block">
                            <video className="w-100" src={ynwaVideoDesktop} autoPlay={true} muted={true} playsInline={true} loop={false}></video>
                            <Link
                                className="ynwabtntop btn--regular btn--inverted--solid"
                                activeClass="active"
                                to="eventForm"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                            > Join Us!</Link>
                        </div>
                    </CustomView>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">


                            {
                                isGerman ? (
                                    <div className="pageContent">
                                        <h2>{title}</h2>

                                        {RichText.render(prismicDoc.data.description)}


                                    </div>
                                ) :  <div className="pageContent">
                                    <h2>{title}</h2>
                                    {RichText.render(prismicDoc.data.description)}
                                </div>
                            }






                        </div>
                        <div className="col-12 col-md-8 offset-md-1" id="eventForm">
                            {!isSubmitted ? (
                                <FormEvent submitForm={submitForm} />
                            ) : (
                                <FormSuccess />
                            )}


                        </div>
                    </div>
                </div>


            </div>
        );
    }
    else if (notFound) {
        return <NotFound />;
    }
    return null;

}

export default SectionYnwa;