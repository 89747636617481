import React, { useState, useEffect } from "react";
import {v4 as uuidv4} from "uuid";
import {useTranslation} from "react-i18next";
import {Client} from "../../utilities/prismicHelpers";
import Prismic from "@prismicio/client";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import userTestimonialImage from "../images/arbeiten/5-min.jpg";
import userTestimonialImage2 from "../images/arbeiten/4-min.jpg";
import NotFound from "../../NotFound";
import {NavHashLink} from "react-router-hash-link";
import {RichText} from "prismic-reactjs";




function TeamSliderModule() {

    const { t, i18n  } = useTranslation();

    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);
    let languageKey;
    const uid = 'carrer';

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await Client.getByUID('employer_branding_page', uid ,{lang:languageKey});

                if (doc) {
                    setPrismicDoc(doc);
                    console.log(doc);


                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);

    let ttl1;
    let ttl2;

    if (prismicDoc) {

        prismicDoc.data.body.map( item =>
            <React.Fragment key={item.id}>
            {item.slice_type=="team_slider_module_1" ?
            <>
                {
                    ttl1 = item.primary.tab_title
                }
            </>

            :null}
            {item.slice_type=="team_sldier_module_2" ?
                <>
                    {
                        ttl2 = item.primary.tab_title
                    }
                </>

                :null}
            </React.Fragment>
        )

    return (
        <section className="section5Arbeiten pt-0 padding-topFull-bottomHalf">
            <div className="container swiperTabPanelMain">

                    <Tabs className={`row`}>


                        {prismicDoc.data.body.map( item =>
                            <React.Fragment key={item.id}>
                                {item.slice_type=="team_slider_module_1" ?
                                    <>
                                        <TabList className={`col-12 col-lg-10 offset-lg-1 tablist d-flex`}>
                                            <Tab>{ttl1}</Tab>
                                            <Tab>{ttl2}</Tab>
                                        </TabList>
                                        <TabPanel className={`swiperTabPanel`}>
                                            <Swiper
                                                className="tabsSwiper tabsSwiper1"
                                                observer={true}
                                                observeParents={true}
                                                spaceBetween={15}
                                                slidesPerView={"auto"}
                                                navigation
                                                loop={false}
                                                onSlideChange={() => console.log('slide change')}
                                                onSwiper={(swiper) => console.log(swiper)}

                                            >
                                                {item.items.map(list =>
                                                    <SwiperSlide>
                                                        <div className="d-flex flex-column flex-md-row">
                                                            <div className="userTestimonialImage" style={{ backgroundImage: `url(${list.image.url})`}}>

                                                            </div>
                                                            <div className="userTestimonialData justify-content-start justify-content-xl-center">
                                                                <div className="tabTestimonial">
                                                                    {list.quote}
                                                                </div>
                                                                <div className="tabUser">{list.name}</div>
                                                                <div className="tabPosition">{list.position}</div>
                                                            </div>
                                                        </div>


                                                    </SwiperSlide>
                                                )}

                                            </Swiper>
                                        </TabPanel>
                                    </>

                                    :null}
                                {item.slice_type=="team_sldier_module_2" ?
                                    <TabPanel className={`swiperTabPanel`}>
                                        <Swiper
                                            className="tabsSwiper tabsSwiper1"
                                            observer={true}
                                            observeParents={true}
                                            spaceBetween={15}
                                            slidesPerView={"auto"}
                                            navigation
                                            loop={false}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}

                                        >
                                            {item.items.map(list =>
                                                <SwiperSlide>
                                                    <div className="d-flex flex-column flex-md-row">
                                                        <div className="userTestimonialImage" style={{ backgroundImage: `url(${list.image.url})`}}>

                                                        </div>
                                                        <div className="userTestimonialData justify-content-start justify-content-xl-center">
                                                            <div className="tabTestimonial">
                                                                {list.quote}
                                                            </div>
                                                            <div className="tabUser">{list.name}</div>
                                                            <div className="tabPosition">{list.position}</div>
                                                        </div>
                                                    </div>


                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                    </TabPanel>
                                    :null}
                            </React.Fragment>
                        )}




                    </Tabs>
            </div>
        </section>
    )
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}

export default TeamSliderModule;
