import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { linkResolver_jobs } from '../../prismic-configuration';
import { useTranslation } from 'react-i18next';
import {useState, useEffect} from "react";
import axios from 'axios';


/**
 * Post list item component
 */

const JobItem = ({ job },lng) => {
    const { i18n } = useTranslation();
    let myStr = job.company_name;
    let firstWord = myStr.split(" ")[0];
    let imgName = myStr.replace(/ /g,"_");
    let lang = i18n.language;
  return (
      <div className="col-12 col-md-6 col-lg-4">
                                    <div className="jobContainer">
                                        <div className="companyLogo"><img src={'/images/companies/' + imgName + '.png'} alt={imgName}/></div>
                                        {/*<div className="companyLogo"><img src={job.data.meta_open_graph_image.url} alt="blueSummit"/></div>*/}
                                        {/* {job.data.isexternal ? <a  target="_blank" href={job.data.linkexternal.url}> <p className="jobTitle" >{title}</p></a> : */}
                                        {

                                        }
                                            <Link to={linkResolver_jobs(job,lang)}>
                                                 <p className="jobTitle" >{job.externalPostingName}</p>
                                            </Link>

                                        {/* } */}
                                        {<div className="jobLocation">{job.geo_city}</div>}

                                    </div>
                                </div>
   
  );
};

export default JobItem;
