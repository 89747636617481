import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";
import porsche_05 from "../../images/porsche_05.jpg";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType12(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;
    console.log('mod12data', data);

    let imageUrl = typeof data.primary.image_link.url != 'undefined' ? data.primary.image_link.url : null;
    let dims;

    if(typeof data.primary.image_link.url != 'undefined') {
        dims = {
            width: data.primary.image_link.dimensions.width,
            height: data.primary.image_link.dimensions.height
        }
    } else {
        dims = {
            width: 0, //data.primary.image_link.dimensions.width,
            height: 0 //data.primary.image_link.dimensions.height
        }
    }

    console.log('module type 12', data)
    if(data.primary.title1 == 'Projects') {
        return ('')
    }

    let classStr1 = '';
    let classStr2 = '';
    if(data.primary.is_text_left) {
        classStr1 = 'col-12 col-lg-3 offset-lg-1';
        classStr2 = 'col-12 col-lg-7';
    } else {
        classStr1 = 'col-12 col-lg-3 offset-lg-1';
        classStr2 = 'col-12 col-lg-7';
    }

    return (
        <div className={`caseStudyPageContainer module-12`} id={`section-${key}`}>
            <div className={`container-fluid container`}>
                <div className={`row`}>
                    {
                        data.primary.is_text_left == true ?
                            (
                                <div className={`${classStr1} order-1`}>
                                    <h3>{data.primary.title1}</h3>
                                    {
                                        data.primary.description1 != null ?
                                            data.primary.description1.length > 0 ?
                                                data.primary.description1.map((text,index) => {
                                                    return (
                                                        <p className="poppins-regular" key={index}>{text.text}</p>
                                                    )
                                                }) : ('')
                                            : ('')
                                    }
                                </div>
                            ) : (
                                <div className={`${classStr1} order-2`}>
                                    <h3>{data.primary.title1}</h3>
                                    <p className="poppins-regular">
                                        {
                                            Array.isArray(data.primary.description1) && data.primary.description1.length > 0 ?
                                            data.primary.description1[0].text : ''
                                        }
                                    </p>
                                </div>
                            )
                    }

                    <div className={`${classStr2} order-2`}>
                        {
                            imageUrl != null ?
                                (
                                    <img src={imageUrl} style={{ width: '100%' }} />
                                ) : ('')
                        }

                        {
                            data.primary.video_id != null || data.primary.video_embed_link ?
                                (
                                    data.primary.video_source == 'Youtube' ?
                                        (
                                            <iframe width="100%" height="415" src={`https://www.youtube.com/embed/` + data.primary.video_id} title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen></iframe>
                                        ) : data.primary.video_source == 'Vimeo' ?
                                            (
                                                <iframe width="100%" height="415" src={`https://player.vimeo.com/video/` + data.primary.video_id} title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen></iframe>
                                            ) : (
                                                <video width="100%" height="315" controls>
                                                    <source src={data.primary.video_embed_link} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )
                                ) : ('')
                        }
                    </div>
                </div>

                <div className="row mt-5 mt-mobile-5">
                    <div className="col-12 col-lg-9 offset-lg-2 col-xxl-6 offset-xxl-5">
                        <div className="row">
                            {data.items.map((item,index) => {
                                return (
                                    <div key={index} className="col-12 col-md-4 flex-mobile">
                                        <h3 className="percent-label white-32 mb-3">{item.number_value}</h3>
                                        <p className="poppins white-32" style={{lineHeight: '24px'}}>{item.number_description}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType12;