import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType11(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;
    const key = props.moduleNo;
    const extraClass = props.extraClass;

    let imgUrl = typeof data.primary.image.url != 'undefined' ? data.primary.image.url : null;
    let dims = {
        width: data.primary.image.dimensions.width,
        height: data.primary.image.dimensions.height
    };

    console.log('module type 11', data);

    return (
        <div className="caseStudyPageContainer module-11" id={`section-${key}`}>
            <div className="container-fluid container">
                <div className="row">
                    <div className={`col-12 col-lg-10 offset-lg-1`}>
                        <div className="row">

                            {
                                data.primary.textleft == false ?
                                    (
                                        <div className={extraClass == 'porsche' || extraClass == 'mediamarkt' || extraClass == 'brown-forman' || extraClass == 'hyperice' || extraClass == 'bosch' || extraClass == 'epuron' ? `col-12 col-lg-7` : `col-12 col-lg-8`}>
                                            <img style={{width: '100%', height: 'auto'}} src={data.primary.image.url} />
                                        </div>
                                    ) : ('')
                            }

                            <div className={extraClass == 'porsche' || extraClass == 'mediamarkt' || extraClass == 'brown-forman' || extraClass == 'hyperice' || extraClass == 'bosch' || extraClass == 'epuron' ? `col-12 col-lg-5` : `col-12 col-lg-4`}>
                                {
                                    data.primary.title1 != null ?
                                        (
                                            <h3 className={`f-36`}>{data.primary.title1}</h3>
                                        ) : ('')
                                }

                                {
                                    data.primary.description1 != null ?
                                        data.primary.description1.length > 0 ?
                                            (
                                                data.primary.description1.map((text, index) => <p key={index} className="poppins-regular f-20">{text.text}</p>)
                                            ) : ('')
                                        : ''
                                }

                                {
                                    data.items.length > 0 ?
                                        (
                                            <ul className={`bullet-list`} style={{ paddingLeft: '17px' }}>
                                                {data.items.map((item,index) => {
                                                    if(item.list_item != null) {
                                                        return (
                                                            <li className={`poppins-regular f-20`} style={{ paddingLeft: '16px', paddingBottom: '8px'}} key={index}>{item.list_item}</li>
                                                        )
                                                    } else {
                                                        return '';
                                                    }
                                                })}
                                            </ul>
                                        ) : ('')
                                }
                            </div>

                            {
                                data.primary.textleft == true ?
                                    (
                                        <div className="col-12 col-lg-7 width80 text-center">
                                            <img style={{width: '100%', height: 'auto'}} src={data.primary.image.url} />
                                        </div>
                                    ) : ('')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModuleType11;