import * as React from 'react'
import './style.scss'

const STYLES =[
    "btn--primary--solid",
    "btn--dark--solid",
    "btn--underline",
    "btn--secondary--solid",
    "btn--inverted--solid"
]

const SIZES = [
    "btn--regular",
    "btn--big",
    "btn--small"
]
export const Anchor = ({children, href, target, onClick, anchorStyle, anchorSize}) => {

    const checkAnchorStyle = STYLES.includes(anchorStyle) ? anchorStyle : STYLES[0];
    const checkAnchorSize = SIZES.includes(anchorSize) ? anchorSize : SIZES[0];

    return (
        <a className={`${checkAnchorStyle} ${checkAnchorSize}`} onClick={onClick} href={href} target={target}>
            {children}
        </a>
    )
}