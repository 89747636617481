import React, { useEffect,useState } from "react";
import '../../../i18n/config';
import {useTranslation} from "react-i18next";
import { RichText } from 'prismic-reactjs';
import { Client } from "../../../utilities/prismicHelpers";
import NotFound from "../../../NotFound";



const PrivacyEn = ({ match }) => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const uid = 'privacy-en';
    const { t , i18n } = useTranslation();


    useEffect(() => {
        const fetchPrismicData = async () => {
            try {


                const doc = await Client.getByUID('page', 'privacy-en' ,{lang: 'en-us'});

                if (doc) {
                    setPrismicDoc(doc);
                    console.log(doc);

                } else {
                    console.warn('Not Found');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        }

        fetchPrismicData();
    }, [uid]);


    if (prismicDoc) {

        const title = prismicDoc.data.meta_title[0].text

        const blogContent = prismicDoc.data.body1.map((slice, index) => {
            // Render the right markup for the given slice type

            // Text Slice
            if (slice.slice_type === 'simple_content') {
                return RichText.render(slice.primary.content);

                // Image Gallery Slice
            }

            else {
                return null;
            }
        });

        return (

            <div className="row">
                <div className="col-12 col-lg-3 offset-lg-2">
                    <h2 className="mb-4 text-lg-start">{title}</h2>
                </div>
                <div className="col-12 col-lg-7 mt-lg-1">
                    {blogContent}
                </div>
            </div>




        )
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
}


export default PrivacyEn;

