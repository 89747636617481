import React from 'react';
import '../style/TextTwoCol.scss';
import { RichText } from 'prismic-reactjs';

/**
 * @typedef {Object} TextTwoColProps
 * @property {Object} data - An object containing the data to be displayed.
 * @property {string} [data.pretitle] - optional
 * @property {string} [data.title] - optional
 * @property {string} [data.subtitle] - optional
 * @property {string} [data.text_left] - optional
 * @property {string} [data.text_right] - optional
 */

/**
 * A component that displays text in a two-column layout.
 * @param {TextTwoColProps} props - The props of the component.
 */


const TextTwoColSingleHeading = ({
    data}) => {
  return (
    <section className = "two-text-col-signle-head   padding-topFull-bottomHalf " >
      <div className="container">
        <div className="row margin-bottomBigger">
          <div className="col-12 col-lg-10 offset-lg-1">
              {data.primary.pretitle?
            <h2 className="margin-bottomDefault">{data.primary.pretitle}</h2>:''}
              {
                data.primary.subtitle ?
            <h2 className="margin-bottomDefault">{data.primary.subtitle}</h2>:''}
          </div>
          <div className="col-12 col-lg-5 offset-lg-1">
            <div className="opacity64 m-0">
              {RichText.render(data.primary.text_column_1)
                ? RichText.render(data.primary.text_column_1)
                : ''}
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="opacity64 m-0">
              {RichText.render(data.primary.text_column_2)
                ? RichText.render(data.primary.text_column_2)
                : ''}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextTwoColSingleHeading;
