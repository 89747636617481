import React from 'react';

import './form-input.styles.scss';

const FormInput = ({ handleChange, label, error, ...otherProps }) => (
    <div className='form-group'>
        <input className='form-input' onChange={handleChange} {...otherProps} />
        <span className="text-danger">{error}</span>
        {label ? (
            <label
                className={`${
                    otherProps.value?.length ? 'labelMove' : ''
                } form-input-label`}
            >
                {label}
            </label>
        ) : null}
    </div>
);

export default FormInput;