import Menu from 'react-burger-menu/lib/menus/slide'
import React, {useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from 'react-i18next';

import { Link } from "react-scroll";
import { Link as Link2 } from 'react-router-dom';
import {scroller} from "react-scroll";
import { NavHashLink } from 'react-router-hash-link';

import menuIcon from "./ic_menu.svg";
import menuCrossIcon from "./ic_close.svg"
import './style/navBurger.scss';

class BurgerMenu extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false,
            isToggle: false,
            isToggle1: false

        }
    }
  handleClick = (event, lng) => {
     event.preventDefault();
     const currentPath = window.location.pathname;

     let newPath = currentPath;

     // Toggle '/en/' with '/de/' and vice versa
     if (currentPath.includes('/en/')) {
         newPath = currentPath.replace('/en/', '/de/');
     } else if (currentPath.includes('/de/')) {
         newPath = currentPath.replace('/de/', '/en/');
     }

     window.location.href = newPath; // Redirect to the new URL
 };

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }


    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }



    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }

    

    openSubmenu = () => {
        this.setState(state => ({isToggle: !state.isToggle}))

    }
    openSubmenu1 = () => {
        this.setState(state => ({
            isToggle1: !state.isToggle1
        }))

    }
    

    render () {
        const { t, i18n } = this.props;

    var languageButton;

        let languageKey;
        let contactLink;
        let newsLink;
        let jobsLink;
        let imprintLink;

    if (i18n.language == 'en') {
    languageKey = '/en/homepage';
    languageButton = (
    <>
          <a component = {
            Link2
          }
          className = "menu-item"
          href = '/'
          onClick = {
            (e) => this.handleClick(e, 'de')
          } >
            De
          </a>
          < a component = {
            Link2
          }
          href = '/'
          onClick = {
            (e) => this.handleClick(e, 'en')
          }
          className = "active menu-item" >
            En
          </a>
       </>
    );
 
  } else {
    languageButton = (
        <>
          < a component = {
            Link2
          }
          href = '/'

          onClick = {
            (e) => this.handleClick(e, 'de')
          }
          className = "active menu-item" >
            De
          </a>
        
          < a component = {
            Link2
          }
          href = '/'
className = 'menu-item'
          onClick = {
            (e) => this.handleClick(e, 'en')
          } >
            En
          </a></>
        
    );
    languageKey = '/de/homepage';
  
  }

        const changeLanguage = lng => {
            i18n.changeLanguage(lng);
        };
        function refreshPage(){
            window.location.reload();
        }



        // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
        return (
            <Menu  isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} right width={ '100vw' } htmlClassName={ "menu-opened" } customBurgerIcon={ <img src={menuIcon} alt="burger-menu" /> } customCrossIcon={ <img src={menuCrossIcon} alt="close" /> }>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md4">
                            <div className="menuBurgerList">
                                <NavHashLink onClick={() => this.closeMenu()} to={languageKey+"#sectionMission"}>{t('header-mission')}</NavHashLink>
                                <NavHashLink onClick={() => this.closeMenu()} to={languageKey+"#sectionMachen"} >{t('header-machen')}</NavHashLink>
                                <NavHashLink onClick={() => this.closeMenu()} to={languageKey+"#sectionTeam"} >{t('header-sind')}</NavHashLink>


                            </div>

                        </div>
                        <div className="col-12 col-sm-4 col-md4">
                            <div className="menuBurgerList">
                               {/* // <a href={"/" + i18n.language + "/responsibility/sustainability"} className="news-link-menu">{t('header-responsibility_su')}</a> */}
                                {/* <a href={"/" + i18n.language + "/responsibility/compliance"} className="news-link-menu">{ t('header-responsibility_com')}</a> */}
                                <div className="hasSubmenu1" onClick={this.openSubmenu1}><a href="#" className="jobs-link-menu">  {t('header-responsibility')}</a>
                                    <ul className="submenu burgerSubmenu" style={{display: this.state.isToggle1 ? 'block': 'none'}}>
                                        <li><a href={"/" + i18n.language + "/responsibility/sustainability"} className="kareers-link-menu">{t('header-responsibility_su')}</a></li>
                                        <li><a href={"/" + i18n.language + "/responsibility/compliance"}  className="jobs-link-menu">{ t('header-responsibility_com')}</a></li>
                                    </ul>
                                </div>

                                 <div className="hasSubmenu" onClick={this.openSubmenu}><a href="#" className="jobs-link-menu">{t('header-careers')}</a>
                                    <ul className="submenu burgerSubmenu" style={{display: this.state.isToggle ? 'block': 'none'}}>
                                        <li><a href={"/" + i18n.language + "/page/arbeiten"} className="kareers-link-menu">{t('header-arbeiten')}</a></li>
                                        <li><a href={"/" + i18n.language + "/jobs"} className="jobs-link-menu">Jobs</a></li>
                                    </ul>
                                </div>
                            

                                <a href={"/" + i18n.language + "/news"} className="news-link-menu">News</a>
                                <a href={"/" + i18n.language + "/contact"} className="contact-link-menu">{t('header-kontakt')}</a>

                            </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md4">
                            <div className="menuBurgerList">
                               {
                                   languageButton
                               }
                            </div>
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}
const CompBurger = withTranslation()(BurgerMenu);

export default CompBurger;