import React, {useEffect, useState} from "react";
import "../components/style/newsItem.scss";
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';


import { linkResolver } from '../prismic-configuration';

// function NewsItem() {
//
//     return (
//         <div className="newsItemContainer" data-category="news">
//                 <div className="newsImageContainer"><img src="/news/img_news_01.jpg" alt=""/></div>
//                 <div className="newsCategory">PIA Group: News</div>
//                 <div className="newsTitle"><a
//                     href="https://pia.me/de/news/meta-namedescription-contentpia-group-bleibt-auf-platz-zwei-der-top-digitalagenturen">BVDW
//                     Internetagentur-Ranking 2021</a></div>
//         </div>
//     );
// }
//
// export default NewsItem;


/**
 * Post list item component
 */
const NewsItem = ({ news_item }) => {


    console.log(news_item);
    const title =
        RichText.asText(news_item.data.news_title) ?
            RichText.asText(news_item.data.news_title) :
            'Untitled';

    return (
        <div className="blog-post">
            <Link to={linkResolver(news_item)}>
                <h2>{title}</h2>
            </Link>




        </div>
    );
};

export default NewsItem;