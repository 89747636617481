import React, {useState, useEffect} from "react";
import { RichText, LinkResolver } from "prismic-reactjs";
import '../../components/style/landingPage.scss'
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import "swiper/components/pagination/pagination.min.css"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Parallax } from 'react-parallax';
import 'react-tabs/style/react-tabs.css';
import SectionCasesModule from "../SectionCasesModule";
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionTeamSliderNew from "../SectionTeamSliderNew";
import SectionNewsSliderNew from "../SectionNewsSliderNew";
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";


const LandingPageContainerVideos = ( data ) => {
    const url = window.location.pathname.split('/').pop();
    useEffect(() => {
        console.log(`You changed the page to: ${url}`)
    }, [url]);

    console.log(data);
    let color;


    color = data.data.islight ? 'isLight' : 'isDark';

    function removeClass(e) {
        let element;
        element = document.getElementById("controller-id");

        element.classList.remove("hidden-all");
    }

    useEffect(() => {
        color = data.data.islight;

        if (!color) {
            document.querySelector("header").classList.add("inverted");
            document.querySelector("footer").classList.add("darkVariant");
        }



    });


    return (
        <div className="landingPage">
            <div className={`${color} pageHeader`} style={{ backgroundImage: `url(${data.data.image.url})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: '115%' }}>
                {/*<img src={data.data.image.url} alt="" className="w-100"/>*/}
                <div className="container">
                    <div className="row text-center">
                        <p><b>{data.data.pretext}</b></p>
                        <h1>{RichText.asText(data.data.title)}</h1>
                    </div>
                </div>

            </div>


            {data.data.body1.map( item =>
                <section key={item.uid} className={item.uid}>

                    {item.slice_type=="heading_with_two_columns"?
                        <div className="slideContentHolder">
                            <h2>{RichText.asText(item.primary.title)}</h2>
                        </div>
                        :null}


                    {item.slice_type=="tab_module"?
                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} tab_module`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-10 offset-lg-1">
                                        <p><b>{item.primary.pretitle}</b></p>
                                        <h2>{RichText.asText(item.primary.title1)}</h2>
                                        <h3>{RichText.asText(item.primary.subtitle)}</h3>
                                        <h3>Page Videos</h3>
                                    </div>
                                    <div className="col-12 col-lg-10 offset-lg-1">



                                        <Tabs defaultIndex={9}>
                                            <TabList>
                                                {item.items.map((list, index) =>
                                                    <Tab onClick={(e) => removeClass(item)}>
                                                        <a key={list.uid} data-service={`serviceNrShow${index}`}><strong>{list.name}</strong></a>
                                                    </Tab>
                                                )}
                                            </TabList>

                                            <div id="controller-id" className="hidden-all">
                                                {item.items.map((list, index) =>
                                                    <TabPanel className={`serviceNrShow${index}`}>

                                                        <div className="row opacity-48">
                                                            <div className="col-12">
                                                                {list.name ? <h2>{list.name}</h2> : ''}
                                                            </div>
                                                            <div className="col-12 col-lg-8">
                                                                {list.description1 ? <h3>{list.description1}</h3> : ''}
                                                            </div>
                                                            <div className="col-12 col-lg-4">
                                                                <h4>{list.services_title}</h4>
                                                                <ul>
                                                                    {list.service1 ? <li>{list.service1}</li> : ''}
                                                                    {list.service2 ? <li>{list.service2}</li> : ''}
                                                                    {list.service3 ? <li>{list.service3}</li> : ''}
                                                                    {list.service4 ? <li>{list.service4}</li> : ''}
                                                                    {list.service5 ? <li>{list.service5}</li> : ''}
                                                                    {list.service6 ? <li>{list.service6}</li> : ''}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </TabPanel>
                                                )}
                                            </div>


                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :null}


                    {item.slice_type=="parallax_module"?
                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} parallax_module text-start`}>
                            <Parallax bgImage={item.primary.image1.url} bgImageAlt="" strength={200}>
                                <div className="container" style={{ height: '100vh' }}>
                                    <div className="row d-flex align-items-start flex-column justify-content-end h-100">
                                        <div className="col-12 col-lg-7 offset-lg-1 quote-container">
                                            {item.primary.quote ? <h3>{item.primary.quote}</h3> : ''}
                                            {item.primary.name ? <h4>{item.primary.name}</h4> : ''}
                                            {item.primary.position ? <span>{item.primary.position}</span> : ''}
                                        </div>


                                    </div>
                                </div>
                            </Parallax>
                        </div>

                        :null}

                    {item.slice_type=="text_image_module"?
                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} text_image_module`}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-7 offset-xl-1">
                                        {item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                    </div>
                                    <div className="col-12 col-xl-10 offset-xl-1">
                                        {RichText.asText(item.primary.subtitle) ? <h3>{RichText.asText(item.primary.subtitle)}</h3> : ''}
                                    </div>
                                    <div className="col-12 d-block d-xl-none">

                                    </div>

                                </div>
                            </div>

                            {item.primary.image1 ? <img className="w-100" src={item.primary.image1.url} alt=""/> : 'nu are image'}

                        </div>
                        :null}

                    {item.slice_type=="case_module"?

                        <div>
                            {item.primary.casemodule ? <SectionCasesModule/> : 'deactive'}
                        </div>

                        :null}

                    {item.slice_type=="mini_carousel_module"?

                        <div className={`${item.primary.alignment ? 'rightside' : 'leftside'} ${item.primary.islight1 ? 'isLight' : 'isDark'} mini_swiper_module`}>
                            <Swiper
                                loop={false}
                                breakpoints={{
                                    // when window width is >= 320px
                                    320: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        spaceBetween: 50,
                                        slidesPerView: 4,
                                    },
                                }}
                                observer={true}
                                draggable={false}

                                onSlideChange={() => {}}
                                observeParents={true}
                                observeSlideChildren={true}
                                onObserverUpdate={() =>{}}
                                //centeredSlides={true}
                                spaceBetween={50}
                                slidesPerView={4}
                            >
                                {item.items.map(list =>
                                    <SwiperSlide>
                                        <img key={list.uid} className={list.uid} src={list.image1.url} alt=""/>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>

                        :null}


                    {item.slice_type=="carousel_module_team"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} team_module`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}
                                    </div>
                                </div>
                                <SectionTeamSliderNew/>
                                <div className="row">
                                    <div className="col-12 col-xl-5 offset-xl-1">
                                        {item.primary.text_column_one ? <p><b>{item.primary.text_column_one}</b></p> : ''}

                                    </div>
                                    <div className="col-12 col-xl-5">
                                        {item.primary.text_column_two ? <p><b>{item.primary.text_column_two}</b></p> : ''}

                                    </div>
                                </div>
                            </div>

                        </div>

                        :null}

                    {item.slice_type=="full_width_image_module"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} full_width_image_module`} style={{ backgroundImage: `url(${item.primary.image1.url})`, backgroundSize: 'contain', backgroundPosition: 'center right', backgroundRepeat: 'no-repeat' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}

                                        {item.primary.button_text ? <a href={item.primary.button_link.url} className="btn--big btn--primary--solid btn--inverted--solid">{item.primary.button_text}</a> : ''}
                                    </div>
                                </div>
                            </div>

                        </div>

                        :null}


                    {item.slice_type=="carousel_module_news"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} news_module`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xl-9 offset-xl-1">
                                        {item.primary.pretitle ? <p><b>{item.primary.pretitle}</b></p> : ''}
                                        {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                        {item.primary.subtitle ? <h3>{item.primary.subtitle}</h3> : ''}
                                    </div>
                                </div>
                                <SectionNewsSliderNew/>
                                <div className="row">
                                    <div className="col-12 col-xl-5 offset-xl-1">
                                        {item.primary.button_text ? <a href={item.primary.button_link.url} className="btn--big btn--primary--solid btn--inverted--solid">{item.primary.button_text}</a> : ''}

                                    </div>

                                </div>
                            </div>

                        </div>

                        :null}


                    {item.slice_type=="contact_module"?

                        <div className={`${item.primary.isLight1 ? 'isLight' : 'isDark'} contact_module sectionKontakt`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                                        <div className="KontaktTexts">
                                            {RichText.asText(item.primary.title1) ? <h2>{RichText.asText(item.primary.title1)}</h2> : ''}
                                            {item.primary.description1 ? <h3>{item.primary.description1}</h3> : ''}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="infoPersonHolder">

                                <img src={ item.primary.image1.url} alt=""/>


                                <div className="userData">
                                    <div className="teamsName">{item.primary.user_name}</div>
                                    <div className="teamsPosition">{item.primary.user_position}</div>

                                    <div className="d-none d-md-block">
                                        <a href={"tel:"+ item.primary.user_phone} className="teamsPhone">{item.primary.user_phone}</a>
                                        <div className="d-flex deskSocialCont">
                                            <a href={"mailto:"+ item.primary.user_email} className="btn--inverted--solid btn--regular">{item.primary.user_email}</a>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-none">
                                        <div className="d-flex">
                                            <a href={"tel:"+ item.primary.user_phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                            <a href={"mailto:"+ item.primary.user_email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        :null}

                </section>
            )}



        </div>
    );
}
export default LandingPageContainerVideos;
