import React from 'react';
import '../style/ImageTextHalfDN.scss';
import { RichText } from 'prismic-reactjs';

function LeftHeadingRightTextDN(props) {
  const data = props.data;
console.log(data);
  const returnContent = () => {
      return (
        <>
          < section className = {
            `sus_center1  pt-6${data.primary.bg_black ? ' bg_blackClass' : 'bg_whiteClass'}`
          }
          style = {
            {
              color: data.primary.bg_black ? '#fff' : '#000',
              backgroundColor: data.primary.bg_black ? '#1A1A1A' : '#fff'
            }
          } >
            
      <div className="container ">
        <div className="row margin-bottomBigger">
          <div className="col-12 col-lg-4 offset-lg-1">
            <div className = " m-0 text-light">
              {RichText.render(data.primary.title)
                ? RichText.render(data.primary.title)
                : ''}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            < div className = " m-0  text-light" >
              {RichText.render(data.primary.description)
                ? RichText.render(data.primary.description)
                : ''}
            </div>
          </div>
        </div>
      </div>
    </section>




        </>
      );
    }


  return (
    < section className = ""
    style = {
      {
        color: data.primary.bg_black ? '#fff' : '#000',
        backgroundColor: data.primary.bg_black ? '#1A1A1A' : '#fff'
      }
    } >
      <div className="">
        <div className="row">{returnContent()}</div>
      </div>
    </section>
  );
}

export default LeftHeadingRightTextDN;
