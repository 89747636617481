import React, {useState, useEffect} from "react";
import '../../components/style/newsContainer.scss'
import NewsItem from "../NewsItem";
import {slice, concat,} from 'lodash';
import arrowImage from "../../components/images/ic_arrow_down_small.svg"
import iconPhone from "../images/ic_phone_white.svg";
import iconMail from "../images/ic_mail_white.svg";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import shape5img from "../../components/images/shapes/new/Shape19-min.jpeg";
import shape6img from "../../components/images/shapes/new/Shape22-min.jpeg";
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Client } from "../../utilities/prismicHelpers";
import NewsItems from "../news/newsItem";
import {useTranslation} from "react-i18next";
import {Button} from "../blocks/button";


function NewsPageContainer() {
    const Loading =()=>
    <div  style={{textAlign: 'center'}}>
        Loading...
    </div>  

    gsap.registerPlugin(ScrollTrigger);
    const [prismicData, setPrismicData] = useState({newsPosts: null,newsPostsCopy:null});
    const [notFound, toggleNotFound] = useState(false);
    const [page, setPage] = useState(1);
    const [showMore,setShowMore] = useState(true);
    const [loading,setLoading] = useState(false);
    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);

  const {t, i18n} = useTranslation();
    let languageKey;
    
    if (i18n.language=='en') {
        languageKey = 'en-us';
    } 
    else {
        languageKey = 'de-de';
    }

     const fetchPrismicData = async (languageKey) => {
      try {
        setLoading(true);
        const newsPosts = await Client.query(
          Prismic.Predicates.at('document.type', 'news_article_detail'),
          { lang : languageKey ,  pageSize : 20, page : page , orderings : '[my.news_article_detail.date desc]' },
        );
        if (newsPosts) {
            if(newsPosts.results.length<20){
                setShowMore(false);
            }
            if(page>1){
            newsPosts.results = [...prismicData.newsPostsCopy, ...newsPosts.results]
            }
         // var temp =newsPosts.results.filter(data=>data.lang ===languageKey)
          setPrismicData({ newsPosts:  newsPosts.results,newsPostsCopy:newsPosts.results});
          setLoading(false);

          //check if page finished
          
        } else {
                        showMore(false);

          console.warn('No Data');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    useEffect(() => {



        fetchPrismicData(languageKey);

    }, 
    []);

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'en-us' }
            )
            if (response) {
                setDocData(response.results[0]);
            }

        }
        fetchData()
    }, [])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'news_press_contact'),{ lang: 'de-de' }
            )
            if (response) {
                setDocDataDe(response.results[0]);
            }

        }
        fetchData()
    }, [])
  
    // To filter Data by Lang
    useEffect( () => {

        if (i18n.language=='en') {
            if(prismicData.newsPostsCopy){
                fetchPrismicData('en-us');
            }
        }
        else{
            if(prismicData.newsPostsCopy){
                fetchPrismicData('de-de');
            } 
        }

    } , [i18n.language]);

    const [contacts, setContacts] = React.useState([{
        "id": "1",
        "category": "PIA Group: News",
        "title": "BVDW Internetagentur-Ranking 2021",
        "image": "img_news_01.jpg",
        "link": "https://pia.me/de/news/meta-namedescription-contentpia-group-bleibt-auf-platz-zwei-der-top-digitalagenturen"
    }, {
        "id": "2",
        "category": "PIA Group: News",
        "title": "Markenführung wechselt zu den Digitalagenturen",
        "image": "img_news_04.jpg",
        "link": "https://www.new-business.de/_rubric/index.php?rubric=Podcast"
    }, {
        "id": "3",
        "category": "PIA Group: News",
        "title": "PIA NORDPOL+ gewinnt EWE DSL im Pitch",
        "image": "img_news_02.jpg",
        "link": "https://pia.me/de/news/nordpol-gewinnt-ewe-dsl-im-pitch"
    }, {
        "id": "4",
        "category": "PIA Group: Presse",
        "title": "Wie nehmen Marken die Angst vor TikTok",
        "image": "img_news_05.jpg",
        "link": "https://www.wuv.de/agenturen/wir_wollen_marken_die_angst_vor_tiktok_nehmen"
    }, {
        "id": "5",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider",
        "image": "img_news_03.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "6",
        "category": "PIA Group: Presse",
        "title": "People of PIA mit Patrick Schneider",
        "image": "img_news_04.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "7",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider",
        "image": "img_news_01.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "8",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider",
        "image": "img_news_03.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "9",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider",
        "image": "img_news_02.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "10",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick 10",
        "image": "img_news_04.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "11",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider 11",
        "image": "img_news_04.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "12",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider 12",
        "image": "img_news_03.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "13",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider 13",
        "image": "img_news_01.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }, {
        "id": "14",
        "category": "PIA Group: News",
        "title": "People of PIA mit Patrick Schneider 14",
        "image": "img_news_02.jpg",
        "link": "https://pia.me/de/news/people-of-pia--patrick-schneider"
    }]);
    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }

    const [countries, setCountries] = React.useState(["All", "News", "Events", "Presse"]);
    const [countriesEn, setCountriesEn] = React.useState(["All", "News", "Events", "Press"]);
    const [categorySelected, setSelected] = React.useState("All");
    const limitItems = 6;
    const length = contacts.length;
    const [index,setIndex] = useState(limitItems);

    const loadMore = () =>{
        var p=page+1;
        setPage(p);
        fetchPrismicData(languageKey)
    }

    const handleChange = e => {
        setSelected(e.target.value)
        if(e.target.value=='All'){
          newsPostsAll=prismicData.newsPostsCopy;
        }
        else{
        newsPostsAll=prismicData.newsPostsCopy.filter(data=>data.data.article_type.slug === e.target.value.toLowerCase())
        }        
        setPrismicData({ newsPosts: newsPostsAll,newsPostsCopy:prismicData.newsPostsCopy });
    }
    var newsPostsAll=[];


    useEffect(()=> {
        window.emos3.send({
            content: 'News List Page'
        });
    }, []);

    let filteredContacts = contacts;
    if (categorySelected !== "All") {
        filteredContacts = contacts.filter(contact => contact.category == categorySelected);
    }
    if(prismicData.newsPosts){
        newsPostsAll=prismicData.newsPosts;
        console.log(newsPostsAll);
    }

    return (
        <div className="newsContainer">
            <div className="sectionTopNews">
                <div className="animationNewsRight">
                    <img src={shape5img} alt="" style={{width: '100%', height: '100%'}}/>
                </div>
                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row">
                        <div className="col-6 offset-lg-1 col-lg-7">
                            <h1 className="PageTitle">News</h1>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="header">
                                <label htmlFor="">{t('Category')}</label>
                                <select
                                    onChange={e => handleChange(e)} style={{backgroundImage: `url(${arrowImage})`}}>
                                    {
                                        isGerman ? (

                                            <React.Fragment>
                                                {countries.map(category =>
                                                    <option key={category} value={category}>PIA Group: {category}</option>
                                                )}
                                            </React.Fragment>
                                        ) : <React.Fragment>
                                            {countriesEn.map(category =>
                                                <option key={category} value={category}>PIA Group: {category}</option>
                                            )}
                                        </React.Fragment>
                                    }

                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container newsArticlesContainer">
                    <div className="row">
                        {newsPostsAll.map((news, id) =>
                            <NewsItems news={news} key={news.id}  />
                        )}
                    </div>
                  
                    <div className="row">
                       {showMore ?
                        loading? (<Loading/>) :
                        <button onClick={loadMore} className="btn--regular btn--primary--solid showMoreBtn">{t('MoreArticlesButton')}</button>
                        :null}
                    </div>
                </div>
            </div>



            <div className="sectionKontakt">
                <div className="animationNewsLeft">
                    <img src={shape6img} alt="" style={{width: '1440px', height: '1440px'}}/>
                </div>
                <div className="container" style={{zIndex: '3', position: 'relative'}}>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                            <div className="KontaktTexts">
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <div className="title">{docDe.data.title}</div>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <div className="title">{doc.data.title}</div>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <div className="description">{docDe.data.description}</div>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <div className="description">{doc.data.description}</div>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="infoPersonHolder">
                    {
                        isGerman ? (

                            <React.Fragment>
                                {
                                    docDe ? (
                                        <img src={ docDe.data.photo.url} alt=""/>

                                    ) : <div></div>
                                }
                            </React.Fragment>
                        ) : <React.Fragment>
                            {
                                doc ? (
                                    <img src={ doc.data.photo.url} alt=""/>
                                ) : <div></div>
                            }
                        </React.Fragment>
                    }

                    <div className="userData">

                        {
                            isGerman ? (

                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <div className="teamsName">{docDe.data.user_name}</div>

                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <div className="teamsName">{doc.data.user_name}</div>
                                    ) : <div></div>
                                }
                            </React.Fragment>
                        }
                        {
                            isGerman ? (
                                <React.Fragment>
                                    {
                                        docDe ? (
                                            <div className="teamsPosition">{docDe.data.user_position}</div>

                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            ) : <React.Fragment>
                                {
                                    doc ? (
                                        <div className="teamsPosition">{doc.data.user_position}</div>
                                    ) : <div></div>
                                }
                            </React.Fragment>
                        }
                        <div className="d-none d-md-block">

                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <a href={"tel:"+ docDe.data.phone} className="teamsPhone">{docDe.data.phone}</a>

                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <a href={"tel:"+ doc.data.phone} className="teamsPhone">{doc.data.phone}</a>
                                        ) : <div></div>
                                    }
                                </React.Fragment>
                            }

                            {
                                isGerman ? (

                                    <React.Fragment>
                                        {
                                            docDe ? (
                                                <div className="d-flex deskSocialCont">
                                                    <a href={"mailto:"+ docDe.data.email} className="btn--inverted--solid btn--regular">{docDe.data.email}</a>
                                                </div>

                                            ) : <div className="d-flex deskSocialCont"></div>
                                        }
                                    </React.Fragment>
                                ) : <React.Fragment>
                                    {
                                        doc ? (
                                            <div className="d-flex deskSocialCont">
                                                <a href={"mailto:"+ doc.data.email} className="btn--inverted--solid btn--regular">{doc.data.email}</a>
                                            </div>
                                        ) : <div className="d-flex deskSocialCont"></div>
                                    }
                                </React.Fragment>
                            }


                        </div>
                        <div className="d-block d-md-none">
                            <div className="d-flex">
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <a href={"tel:"+ docDe.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <a href={"tel:"+ doc.data.phone} className="iconMobileUser"><img src={iconPhone} alt="email"/></a>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    isGerman ? (

                                        <React.Fragment>
                                            {
                                                docDe ? (
                                                    <a href={"mailto:"+ docDe.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>

                                                ) : <div></div>
                                            }
                                        </React.Fragment>
                                    ) : <React.Fragment>
                                        {
                                            doc ? (
                                                <a href={"mailto:"+ doc.data.email} className="iconMobileUser"><img src={iconMail} alt="email"/></a>
                                            ) : <div></div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div className="shapesNewsPagetrigger"></div>


        </div>


    );
}

export default NewsPageContainer;
