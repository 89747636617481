import './style/SectionValues.scss';
import {useInView} from "react-intersection-observer";
import React from "react";
import {motion, AnimatePresence} from "framer-motion";
import {useTranslation} from "react-i18next";
import {Client} from "../utilities/prismicHelpers";
import Prismic from "@prismicio/client";


function SectionValueStrategy() {

    const [doc, setDocData] = React.useState(null);
    const [docDe, setDocDataDe] = React.useState(null);
    const { i18n } = useTranslation();
    let languageKey;

    if (i18n.language=='en') {
        languageKey = 'en-us';
    }
    else {
        languageKey = 'de-de';
    }
    let isGerman;

    if (i18n.language=='en') {
        isGerman = false;
    }
    else {
        isGerman = true;
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'values_sections'),{ lang: 'en-us' }
            )
            if (response) {
                setDocData(response.results[0]);
            }

        }
        fetchData()
    }, [])
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'values_sections'),{ lang: 'de-de' }
            )
            if (response) {
                setDocDataDe(response.results[0]);
            }

        }
        fetchData()
    }, [])



    return (
        <section className="sectionValues sectionValueStrategy justify-content-center">
            <div className="item-fixed">

                        <div className="textContainer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 offset-lg-1">
                                        {
                                            isGerman ? (

                                                <React.Fragment>
                                                    {
                                                        docDe ? (
                                                            <h2>
                                                                {docDe.data.value_1_title}
                                                            </h2>
                                                        ) : <h2></h2>
                                                    }
                                                </React.Fragment>
                                            ) : <React.Fragment>
                                                {
                                                    doc ? (
                                                        <h2>
                                                            {doc.data.value_1_title}
                                                        </h2>
                                                    ) : <h2></h2>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-7 offset-lg-1">
                                        {
                                            isGerman ? (

                                                <React.Fragment>
                                                    {
                                                        docDe ? (
                                                            <div className="valueDescription">
                                                                {docDe.data.value_1_description}
                                                            </div>
                                                        ) :  <div className="valueDescription"></div>
                                                    }
                                                </React.Fragment>
                                            ) : <React.Fragment>
                                                {
                                                    doc ? (
                                                        <div className="valueDescription">
                                                            {doc.data.value_1_description}
                                                        </div>
                                                    ) :  <div className="valueDescription"></div>
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            isGerman ? (

                                                <React.Fragment>
                                                    {
                                                        docDe ? (
                                                            <div className="valueButton mt-5">
                                                                { docDe.data.haslink ? <a target="_blank" className="btn--big btn--primary--solid" href={docDe.data.link_to.url}>{docDe.data.link_text_1}</a> :
                                                                    <div></div>}
                                                            </div>
                                                        ) :  <div className="valueButton"></div>
                                                    }
                                                </React.Fragment>
                                            ) : <React.Fragment>
                                                {
                                                    doc ? (
                                                        <div className="valueButton mt-5">
                                                            { doc.data.haslink ? <a target="_blank" className="btn--big btn--primary--solid" href={doc.data.link_to.url}>{doc.data.link_text_1}</a> :
                                                                <div></div>}
                                                        </div>
                                                    ) :  <div className="valueButton"></div>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="col-12 col-lg-3 offset-lg-1">

                                        <h4>Services</h4>
                                        {
                                            isGerman ? (

                                                <React.Fragment>
                                                    {
                                                        docDe ? (
                                                            <ul className="listServices">
                                                                {docDe.data.services.map(list =>
                                                                    <li>{list.text}</li>
                                                                )}
                                                            </ul>
                                                        ) :  <ul className="listServices"></ul>
                                                    }
                                                </React.Fragment>
                                            ) : <React.Fragment>
                                                {
                                                    docDe ? (
                                                        <ul className="listServices">
                                                            {doc.data.services.map(list =>
                                                                <li>{list.text}</li>
                                                            )}
                                                        </ul>
                                                    ) :  <ul className="listServices"></ul>
                                                }
                                            </React.Fragment>
                                        }




                                    </div>
                                </div>
                            </div>
                        </div>

            </div>
        </section>

    );
}

export default SectionValueStrategy;