import React, { useEffect } from "react";
import './components/style/NotFound.scss';
import HeaderMobile from "./components/HeaderMobile";
import HeaderDesktop from "./components/HeaderDesktop";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import imageUrl3 from "./components/images/shapes/new/shape-13.jpg";
import './i18n/config';
import './components/style/responsive/style.scss';





const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};
const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};
const HeaderComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />;
};



function NotFound() {
    const { t, i18n } = useTranslation();

    let languageKey;
    if (i18n.language=='en') {
        languageKey = '/en/home';

    } else {

        languageKey = '/de/home'
    }

    return (

        <div>
            <ViewportProvider>
                <HeaderComponent />
            </ViewportProvider>
            <div id="postHeader"></div>
            <section className="notFound d-flex justify-content-center" style={{ backgroundImage: `url(${imageUrl3})`}}>
                <div className="generalTextContainer item-relative">
                    <div className="subtitle">404</div>
                    <h2 className="title">{t('404Title')}</h2>
                    <div className="container">
                        <div className="row bottom404Text">
                            <div className="col-12 col-md-8 offset-md-2 text-center">
                                <p>{t('404Description')}</p>
                            </div>
                            <div className="col-12 text-center mt-5">
                                <Link to={languageKey} className="link-1 btn--regular btn--primary--solid">{t('404ButtonText')}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer/>

        </div>

    );
}

export default NotFound;
