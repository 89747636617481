import React, { useEffect,useState } from "react";
import {CustomView, isMobile, isTablet} from "react-device-detect";
import '../../../App.scss';
import '../../../Fonts.scss';
import '../../../i18n/config';
import '../../../components/style/responsive/style.scss';
import "../../../components/style/CaseModules.scss";

const viewportContext = React.createContext({});

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function ModuleType9(props) {
    const { width } = useViewport();
    const breakpoint = 768;
    const data = props.data;

    let imageUrl = typeof data.primary.image_module_9.url != 'undefined' ? data.primary.image_module_9.url : null;

    let dims;

    if(imageUrl != null) {
        dims = {
            width: data.primary.image_module_9.dimensions.width,
            height: 0
        };
    }

    console.log('module type 9', data);

    return (
        <div className={`caseStudyPageContainer module-9`}>
            <div className={`container`}>
                <div className={`row`}>
                    <div className={`col-12`}>
                        {
                            data.primary.title_module_9 != null ?
                                (
                                    <h3>{data.primary.title_module_9}</h3>
                                ) : ('')
                        }

                        {
                            data.primary.description_module_9.length > 0 ?
                                (
                                    <p>{data.primary.description_module_9[0].text}</p>
                                ) : ('')
                        }

                        {
                            data.primary.title_prelist != null ?
                                (
                                    <h3>{data.primary.title_prelist}</h3>
                                ) : ('')
                        }

                        {
                            data.primary.text_prelist != null ?
                                (
                                    <p>{data.primary.text_prelist}</p>
                                ) : ('')
                        }

                        {
                            data.items.length > 0 ?
                                (
                                    <ul>
                                        {
                                            data.items.map((item, index) => {
                                                return (
                                                    <li key={index} className={`poppins-regular`}>{item.list_item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : ('')
                        }
                    </div>
                </div>
            </div>
            {
                data.primary.video_id != null ?
                    (
                        <div className={`mb-160 video-desktop`}>
                            <iframe width="100%" height="100%" src={`https://youtube.com/embed/` + data.primary.video_id}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </div>
                    ) : ('')
            }

            {
                imageUrl != null ?
                    (
                        <div className="topVideoContainer d-block">
                            <div style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url(' + imageUrl +')', width: '100%', height: dims.height + 'px' }}>
                                {/*<h2 style={{ position: 'absolute', top: '65%', fontSize: '28px', left: '250px' }}>{doc.data.body[0].primary.title1}</h2>*/}
                                {/*<h4 style={{ position: 'absolute', fontSize: '24px', top: '55%', left: '250px' }}>{doc.data.subtitle}</h4>*/}
                                {/*<ul className={`heading-items`} style={{ position: 'absolute', top: '72%', left: '250px' }}>*/}
                                {/*    {doc.data.body[0].items.map((item, index) => <li style={{ fontSize: '18px' }} key={index}>{item.list_item}</li>)}*/}
                                {/*</ul>*/}
                            </div>
                        </div>
                    ) : ('')
            }

        </div>
    );
}

export default ModuleType9;